import React from 'react';
import { Button } from '../button';
import useModal from './hooks/useModal';
import { ModalSuccessFooterProps } from './types';

const ModalSuccessFooter = ({ variant = 'primary', className, children, ...props }: ModalSuccessFooterProps): JSX.Element => {
	const { onHide, continueLabel, onContinue } = useModal();
	const classes = [className, children && children && 'mb-2'].filter(Boolean).join(' ');
	const handleClick = () => {
		if (onContinue) {
			onContinue();
		}
		if (onHide) {
			onHide();
		}
	};

	return (
		<>
			<Button className={classes} size="lg" block={true} onClick={handleClick} variant={variant} {...props}>
				{continueLabel}
			</Button>
			{children}
		</>
	);
};

ModalSuccessFooter.displayName = 'ModalSuccessFooter';

export default ModalSuccessFooter;
