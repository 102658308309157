import React from 'react';

import { ModalModifyProps } from './types';

import { SaveButton } from '../button';

import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import Modal from './Modal';

const defaultSaveLabel = 'Save';
const defaultCancelLabel = 'Cancel';
export const defaultModalModifyButtonTestId = 'modal-modify-button';

const ModalModify = ({
	loading,
	closeLabel = defaultCancelLabel,
	saveLabel = defaultSaveLabel,
	onSave,
	onHide,
	children,
	...props
}: ModalModifyProps): JSX.Element => {
	return (
		<Modal onHide={onHide} closeLabel={closeLabel} {...props}>
			{children}
			<Modal.Footer>
				<SaveButton data-testid={defaultModalModifyButtonTestId} loading={loading} onClick={onSave}>
					{saveLabel}
				</SaveButton>
			</Modal.Footer>
		</Modal>
	);
};

ModalModify.displayName = 'ModalModify';
ModalModify.Header = ModalHeader;
ModalModify.Body = ModalBody;

ModalModify.actionLabel = defaultSaveLabel;
ModalModify.closeLabel = defaultCancelLabel;

export default ModalModify;
