import React from 'react';
import { CardElementProps } from './types';

const CardTitle = ({ className = 'h4', children, ...props }: CardElementProps): JSX.Element => {
	const classes = [className, 'card-title'].filter(Boolean).join(' ');
	return (
		<h2 className={classes} {...props}>
			{children}
		</h2>
	);
};

CardTitle.displayName = 'CardTitle';

export default CardTitle;
