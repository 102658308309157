import React from 'react';

import { useAccordionContext, useAccordionItemContext } from './hooks';
import { AccordionBodyProps } from './types';

import { createAccordionId } from './utility';

const AccordionBody = ({ className, ...props }: AccordionBodyProps): JSX.Element => {
	const { id, isOpen } = useAccordionContext();
	const eventKey = useAccordionItemContext().eventKey;
	const headerId = createAccordionId({ id, eventKey, suffix: 'header' });
	const collapseId = createAccordionId({ id, eventKey, suffix: 'collapse' });
	const classes = [className, 'accordion-collapse collapse', isOpen(eventKey) && 'show'].filter(Boolean).join(' ');

	return (
		<div className={classes}>
			<div className="accordion-body" id={collapseId} aria-labelledby={headerId} {...props} />
		</div>
	);
};

AccordionBody.displayName = 'AccordionBody';

export default AccordionBody;
