import { CSSProperties } from 'react';
import { IconProps } from '../Icon';
import { customCSSProperties, supportedThemes, utilityClasses } from './constants';

export interface DuoToneStyle {
	opacity?: number; // any float from 0 to 1
	color?: string; // any valid CSS color value
}

export interface UseDuoToneOptions {
	classList: Array<string>;
	style: CSSProperties;
}
export function useDuoTone({ theme, swapOpacity, primaryStyle, secondaryStyle }: Omit<IconProps, 'icon'>): UseDuoToneOptions {
	const classList: Array<string> = [];
	let style = {};
	if (theme === supportedThemes.duoTone) {
		if (swapOpacity) {
			classList.push(utilityClasses.swapOpacity);
		}

		style = {
			[customCSSProperties.primaryOpacity]: primaryStyle?.opacity,
			[customCSSProperties.primaryColor]: primaryStyle?.color,
			[customCSSProperties.secondaryOpacity]: secondaryStyle?.opacity,
			[customCSSProperties.secondaryColor]: secondaryStyle?.color
		};
	}
	return {
		classList,
		style
	};
}
