import { EMPTY_ARRAY } from './utility/constants';
import { fileUploadStateChangeTypes } from './fileUploadActions';
import { FileUploadReducerInternal } from './types';

const isInvalid = (required: boolean, files: File[]): boolean => {
	return required && files.length === 0;
};

export const fileUploadReducer: FileUploadReducerInternal = (state, action) => {
	let files;
	switch (action.type) {
		case fileUploadStateChangeTypes.AddFiles:
			files = state.fileList.concat((action.files || []) as File[]);
			return {
				...state,
				fileList: files,
				isInvalid: isInvalid(state.required, files)
			};
		case fileUploadStateChangeTypes.ReplaceFiles:
			return {
				...state,
				fileList: (action.files as File[]) || [],
				isInvalid: isInvalid(state.required, (action.files || []) as File[])
			};
		case fileUploadStateChangeTypes.RemoveFiles:
			files = state.fileList.filter((file: File) => !action.files?.includes(file.name));
			return {
				...state,
				fileList: files,
				showErrorAlert: false,
				errorList: EMPTY_ARRAY,
				isInvalid: isInvalid(state.required, files)
			};
		case fileUploadStateChangeTypes.AddErrors: {
			const errors = state.errorList.concat(action.errors || []);
			return {
				...state,
				showErrorAlert: errors.length > 0,
				errorList: errors
			};
		}
		case fileUploadStateChangeTypes.RemoveErrors:
			return {
				...state,
				showErrorAlert: false,
				errorList: EMPTY_ARRAY
			};
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
