import React from 'react';

import { ListGroupItemCheckableProps } from './types';
import ListGroupItemCheckable from './ListGroupItemCheckable';

const ListGroupItemRadio = (props: ListGroupItemCheckableProps): JSX.Element => <ListGroupItemCheckable type="radio" {...props} />;

ListGroupItemRadio.displayName = 'ListGroupItemRadio';

export default ListGroupItemRadio;
