// shortening the icon mapping imports creates circular dependencies
import { iconMap } from '../font-icon/fontIconMapping';
import { spriteIconMap } from '../sprite-icon/spriteIconMapping';
import { IconMappingTaggable, supportedThemes, Theme } from '../shared';
import { TagResolverData } from './types';
import { SpriteIconName } from '../sprite-icon/types';
import { IconName } from '../font-icon/types';

function findMatchingTag<T extends IconMappingTaggable>(mapping: Record<string, T>, searchTag?: string) {
	if (searchTag) {
		const matchFound = Object.entries(mapping).find(([, iconDefinition]) =>
			(iconDefinition?.tags || []).some(iconDefinitionTag => searchTag.match(new RegExp(`^${iconDefinitionTag}$`, 'i')))
		);
		if (matchFound) {
			const [iconName] = matchFound;
			return iconName;
		}
	}

	return undefined;
}

/**
 * searches for a particular tag within one of the available icon mappings
 *
 * @param tag
 * @param theme
 */
export function resolveTag(tag?: string, theme?: Theme): TagResolverData {
	const spriteIconName = findMatchingTag(spriteIconMap, tag);
	const fontIconName = findMatchingTag(iconMap, tag);

	if (theme === supportedThemes.fullColor && spriteIconName) {
		return spriteIconName as SpriteIconName;
	} else if (fontIconName) {
		return fontIconName as IconName;
	} else if (spriteIconName) {
		return spriteIconName as SpriteIconName;
	}

	return undefined;
}

/**
 * loops through an array of tags returning the first icon matching a given tag
 *
 * @param tags
 * @param theme
 */
export function resolveTags(tags: Array<string> = [], theme?: Theme): TagResolverData {
	return tags.filter(Boolean).reduce((accumulator, tag) => {
		if (accumulator.length === 0) {
			const iconName = resolveTag(tag, theme);
			if (iconName) {
				accumulator.push(iconName);
			}
		}

		return accumulator;
	}, [] as Array<IconName | SpriteIconName>)[0];
}
