import * as React from 'react';
import { iconMap } from './fontIconMapping';
import { iconPrefix } from './utility';

const FallbackIcon = React.forwardRef((_, ref: React.ForwardedRef<HTMLElement>): JSX.Element => {
	const { iconName, theme, color } = iconMap.fallback;
	const classes = [`${iconPrefix}-${theme}`, `${iconPrefix}-${iconName}`].filter(Boolean).join(' ');

	return <i ref={ref} data-testid="fallback-icon" aria-hidden="true" className={classes} style={{ color }} />;
});
FallbackIcon.displayName = 'FallbackIcon';

export default FallbackIcon;
