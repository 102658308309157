import React from 'react';

const t10s = (translations?: Record<string, string>) => {
	const _t10s = translations || {};
	const bracketMatcher = new RegExp('\\[\\[.*?]]', 'g');
	const curlyMatcher = new RegExp('{{.*?}}', 'g');

	const getPhrase = (key: keyof typeof _t10s) => {
		return _t10s[key] || key || '';
	};

	const replace = (phrase: string, matcher: RegExp, replacements: Record<string, string>) => {
		return phrase.replace(matcher, function (match) {
			const key = match.replace('{{', '').replace('}}', '').replace('[[', '').replace(']]', '');
			return replacements[key] || match;
		});
	};

	const getPhraseAndReplace = (key: keyof typeof _t10s, replacements?: Record<string, string>) => {
		let phrase = getPhrase(key);

		if (replacements) {
			phrase = replace(phrase, bracketMatcher, replacements);
			phrase = replace(phrase, curlyMatcher, replacements);
		}

		return phrase;
	};

	type TranslateHTMLInternalOptions = {
		as?: React.ElementType;
	};

	const translateHTMLInternal = (phrase: string, options: TranslateHTMLInternalOptions = {}) => {
		const { as: Element = 'span', ...props } = options;

		return <Element dangerouslySetInnerHTML={{ __html: phrase }} {...props} />;
	};

	const translateStringInternal = (phrase: string) => {
		return phrase;
	};

	return {
		translate: (key: keyof typeof _t10s, replacements?: Record<string, string>) => {
			const phrase = getPhraseAndReplace(key, replacements);
			const isHTML = /<\/?[a-z][^>]*>|&(?:[a-z]+|#\d+);/i.test(phrase);

			if (isHTML) {
				return translateHTMLInternal(phrase);
			} else {
				return translateStringInternal(phrase);
			}
		},
		translateHTML: (key: keyof typeof _t10s, replacements?: Record<string, string>, options?: TranslateHTMLInternalOptions) => {
			const phrase = getPhraseAndReplace(key, replacements);
			return translateHTMLInternal(phrase, options);
		},
		translateString: (key: string, replacements?: Record<string, string>) => {
			const phrase = getPhraseAndReplace(key, replacements);
			return translateStringInternal(phrase);
		}
	};
};

export default t10s;
