import React from 'react';
import useModal from './hooks/useModal';
import { ModalFooterProps } from './types';

import { Modal } from 'react-bootstrap';

import Button from '../button/Button';

const ModalFooter = ({ children, ...props }: ModalFooterProps): JSX.Element => {
	const { onHide, closeLabel } = useModal();

	return (
		<Modal.Footer {...props}>
			{children}
			<Button variant="neutral" onClick={onHide}>
				{closeLabel}
			</Button>
		</Modal.Footer>
	);
};
ModalFooter.displayName = 'ModalFooter';

export default ModalFooter;
