import React from 'react';
import { PromoWidgetProps } from './types';
import Widget from './Widget';

const PromoWidget = ({ media, children, ...props }: PromoWidgetProps): JSX.Element => {
	return (
		<Widget {...props}>
			<table role="presentation">
				<tbody>
					<tr>
						<td className="v-align-top promo-start" style={{ width: '5.5rem', padding: '1.5rem 0 1.5rem 1.5rem' }}>
							{media}
						</td>
						<td>{children}</td>
					</tr>
				</tbody>
			</table>
		</Widget>
	);
};
PromoWidget.displayName = 'PromoWidget';

export default PromoWidget;
