import React from 'react';
import { Pagination, PaginationProps } from 'react-bootstrap';
import { IconBack } from '@optic-delight/icons';

interface PreviousPageItemProps extends PaginationProps {
	pageIndex: number;
}

export function PreviousPageItem({ pageIndex, ...props }: PreviousPageItemProps): React.ReactNode {
	return pageIndex > 0 ? (
		<Pagination.Prev {...props}>
			<IconBack /> Previous
		</Pagination.Prev>
	) : null;
}
