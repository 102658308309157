import { CSSProperties } from 'react';
import { customCSSProperties } from '../utility';

export function useListStyle(style: CSSProperties = {}): CSSProperties {
	const { margin, width, ...cssProperties } = style;
	return {
		[customCSSProperties.margin]: margin,
		[customCSSProperties.width]: width,
		...cssProperties
	};
}
