import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { IconExpand } from '@optic-delight/icons';
import { Button } from '../button';
import IconPickerOverlay from './IconPickerOverlay';
import useIconPicker, { useIconPickerPropTypes } from './hooks/useIconPicker';
import { useCombinedRefs } from '../../helpers';

const IconPicker = React.forwardRef(({ 'aria-label': ariaLabel, children, ...props }, ref) => {
	const innerRef = useRef();
	const combinedRef = useCombinedRefs(ref, innerRef);

	const { css, offset, reducer, ...rest } = props;
	const { toggleMenu, getOverlayProps } = useIconPicker({ css, offset, reducer });

	return (
		<>
			<Button ref={combinedRef} onClick={toggleMenu} aria-label={ariaLabel}>
				{children}
			</Button>
			<IconPickerOverlay
				{...getOverlayProps({
					targetRef: combinedRef,
					...rest
				})}
			/>
		</>
	);
});
IconPicker.displayName = 'IconPicker';

IconPicker.defaultProps = {
	'aria-label': 'Toggle Menu',
	children: <IconExpand />
};

IconPicker.propTypes = {
	...useIconPickerPropTypes,
	'aria-label': PropTypes.string,
	children: PropTypes.any
};

export default IconPicker;
