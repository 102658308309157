import { createIcon } from '../utility/createIcon';

export const IconAdd = createIcon('add');

export const IconAttach = createIcon('attach');

export const IconCopy = createIcon('copy');

export const IconDelete = createIcon('delete');

export const IconDownload = createIcon('download');

export const IconEdit = createIcon('edit');

export const IconExport = createIcon('export');

export const IconImport = createIcon('import');

export const IconPrint = createIcon('print');

export const IconRefresh = createIcon('refresh');

export const IconTransfer = createIcon('transfer');

export const IconUndo = createIcon('undo');

export const IconUpload = createIcon('upload');
