import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../helpers';

export const toggleSwitchTestId = 'toggle-switch';
export const toggleSizes = ['sm', 'lg'];
export const toggleVariants = ['primary', 'secondary', 'danger', 'warning'];

const Switch = React.forwardRef(({ on, className, 'aria-label': ariaLabel = 'Toggle', 'aria-pressed': ariaPressed, size, variant, ...props }, ref) => {
	const btnClassName = [
		'btn-toggle',
		toggleVariants.includes(variant) ? `btn-toggle-${variant}` : '',
		toggleSizes.includes(size) ? `btn-toggle-${size}` : '',
		on ? 'active' : ''
	]
		.filter(Boolean)
		.join(' ');

	return (
		<label aria-label={ariaLabel} className={className}>
			<input ref={ref} {...props} className="toggle-input" type="checkbox" checked={on} onChange={noop} />
			<span className={btnClassName} aria-pressed={ariaPressed} data-testid={process.env.NODE_ENV === 'test' ? toggleSwitchTestId : undefined} />
		</label>
	);
});
Switch.displayName = 'Switch';

Switch.propTypes = {
	on: PropTypes.bool,
	className: PropTypes.string,
	'aria-label': PropTypes.string,
	'aria-pressed': PropTypes.bool,
	size: PropTypes.oneOf(toggleSizes),
	variant: PropTypes.oneOf(toggleVariants)
};

export default Switch;
