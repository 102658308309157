import React, { forwardRef } from 'react';

import { InputProps } from '../types';
import useFormGroup from '../form-group/hook/useFormGroup';

const FormControl = forwardRef<HTMLElement, InputProps>(
	({ 'aria-invalid': ariaInvalid = false, isInvalid, className, as: Wrapper = 'input', ...props }, ref) => {
		const { controlId } = useFormGroup();
		const classes = ['form-control', isInvalid && 'is-invalid', props.size && `form-control-${props.size}`, className].filter(Boolean).join(' ');

		return <Wrapper id={controlId} aria-invalid={isInvalid || ariaInvalid} className={classes} ref={ref} {...props} />;
	}
);

FormControl.displayName = 'FormControl';

export default FormControl;
