import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import DrillDownBreadCrumbs from './DrillDownBreadCrumbs';
import { Search } from '../forms';

// https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
export const CustomDropDownMenu = React.forwardRef(
	(
		{
			inputRef,
			onInputChange,
			onKeyDown,
			inputValue,
			placeholder,
			style,
			className,
			'aria-label': ariaLabel,
			ariaActivedescendant,
			breadCrumbs,
			onBreadCrumbClick,
			menuId,
			multiple,
			children,
			onClear
		},
		ref
	) => {
		return (
			<div ref={ref} data-testid={process.env.NODE_ENV === 'test' ? `${menuId}-dropdown` : undefined} style={style} className={className} tabIndex="-1">
				<Search
					groupClassName="drilldown-search"
					ref={inputRef}
					aria-label={ariaLabel}
					placeholder={placeholder}
					onKeyDown={onKeyDown}
					onChange={onInputChange}
					value={inputValue}
					aria-autocomplete="list"
					aria-controls={menuId}
					aria-activedescendant={ariaActivedescendant}
					onClear={onClear}
				/>
				<DrillDownBreadCrumbs items={breadCrumbs} onBreadCrumbClick={onBreadCrumbClick} />
				<ul id={menuId} className="drilldown-menu" role="tree" aria-multiselectable={multiple}>
					{children}
				</ul>
			</div>
		);
	}
);
CustomDropDownMenu.displayName = 'CustomDropDownMenu';

CustomDropDownMenu.defaultProps = {
	style: undefined,
	className: undefined,
	placeholder: 'Type to filter...',
	'aria-label': 'Search'
};

CustomDropDownMenu.propTypes = {
	inputRef: PropTypes.any,
	onInputChange: PropTypes.func,
	onClear: PropTypes.func,
	onKeyDown: PropTypes.func,
	inputValue: PropTypes.string,
	placeholder: PropTypes.string,
	style: PropTypes.object,
	className: PropTypes.string,
	'aria-label': PropTypes.string,
	ariaActivedescendant: PropTypes.string,
	/**
	 * group items for bread crumb display
	 */
	breadCrumbs: PropTypes.array,
	onBreadCrumbClick: PropTypes.func,
	menuId: PropTypes.string,
	multiple: PropTypes.bool,
	children: PropTypes.node
};

const DrillDownMenu = React.forwardRef((props, ref) => {
	// force renderOnMount for accessibility
	return <Dropdown.Menu ref={ref} as={CustomDropDownMenu} {...props} />;
});

DrillDownMenu.defaultProps = {
	renderOnMount: true
};

export default DrillDownMenu;
