import React from 'react';

import { ModalConfirmProps } from './types';

import { Button } from '../button';

import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import Modal from './Modal';

const defaultConfirmLabel = 'Delete';
const defaultCancelLabel = 'Cancel';

const ModalConfirm = ({
	onConfirm,
	confirmLabel = defaultConfirmLabel,
	closeLabel = defaultCancelLabel,
	onHide,
	children,
	...props
}: ModalConfirmProps): JSX.Element => {
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		onConfirm?.(e);
		onHide();
	};
	return (
		<Modal fullscreen={false} closeLabel={closeLabel} onHide={onHide} {...props}>
			{children}
			<Modal.Footer>
				<Button variant="primary" onClick={handleClick}>
					{confirmLabel}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

ModalConfirm.displayName = 'ModalConfirm';
ModalConfirm.Header = ModalHeader;
ModalConfirm.Body = ModalBody;

ModalConfirm.actionLabel = defaultConfirmLabel;
ModalConfirm.closeLabel = defaultConfirmLabel;

export default ModalConfirm;
