import * as React from 'react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { utilityClasses } from '../utility';
import { StackStyleProps, useStackStyle } from './useStackStyle';
import { createClassWithSuffix } from '../../shared';

export interface StackIconsProps extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'style'> {
	size?: SizeProp;
	inverse?: boolean;
	style?: StackStyleProps;
}

export function StackIcons({ className, style = {}, ...props }: StackIconsProps): JSX.Element {
	const customStyle = useStackStyle(style);
	const classes = [
		className,
		utilityClasses.stackIcons,
		props.inverse && utilityClasses.inverse,
		props.size && createClassWithSuffix(utilityClasses.size, props.size)
	]
		.filter(Boolean)
		.join(' ');

	return <span className={classes} style={customStyle} {...props} />;
}
