import * as React from 'react';
import { ColumnInstance } from 'react-table';
import { rowSpacer } from '../filters/utility';

interface RenderProps {
	columns: Array<ColumnInstance>;
}
type RenderPropsFn = (props: RenderProps) => React.ReactNode;

interface ComponentArrayProps {
	columns: Array<ColumnInstance>;
	children: React.ReactElement | RenderPropsFn | Array<React.ReactElement | RenderPropsFn>;
	readOnly?: boolean;
}

export default function ComponentArray({ columns, children, readOnly }: ComponentArrayProps): JSX.Element {
	const childrenArray = Array.isArray(children) ? children : [children];
	const newChildren = childrenArray.map((child, index) => {
		// @ts-expect-error when child is a function no key is provided, and ternary handles it appropriately
		const key = index + (child?.key || '');
		return (
			<span key={key} className={`${rowSpacer} me-1`}>
				{React.isValidElement(child)
					? React.cloneElement(child, {
							readOnly,
							onChange: (e: React.ChangeEvent) => (child as React.ReactElement).props.onChange?.(e, columns),
							...((child as React.ReactElement).props.onClick && {
								onClick: (e: React.MouseEvent) => (child as React.ReactElement).props.onClick?.(e, columns)
							})
					  })
					: child({ columns })}
			</span>
		);
	});
	return <div className="d-flex flex-wrap">{newChildren}</div>;
}
