import React from 'react';

import { ContainerProps } from './types';

export const fluidVariants = [true, 'sm', 'md', 'lg', 'xl', 'xxl'];

const Container = ({ className, fluid, ...props }: ContainerProps): JSX.Element => {
	const fluidClasses = [
		'container',
		typeof fluid === 'string' && fluidVariants.some(variant => variant === fluid) && `-${fluid}`,
		typeof fluid === 'boolean' && '-fluid'
	]
		.filter(Boolean)
		.join('');
	const classes = [className, fluidClasses].filter(Boolean).join(' ');
	return <div className={classes} {...props} />;
};

export default Container;
