import { createAxiosInstance } from '../http-client';
import { LIVE_KINNECT_INSTANCE_DEFAULTS } from './LiveKinnectClient';
import { getAccessTokens } from './utility/getAccessTokens';
import tokenManager from './utility/tokenManager';
import { GetUpdatedTokenProps, LiveKinnectAuthorizedClientProps, configProp } from './types';
import { MISSING_ACCESS_TOKEN_MESSAGE, MISSING_REFRESH_TOKEN_MESSAGE } from './utility/constants';

export const liveKinnectAuthorizedClient: LiveKinnectAuthorizedClientProps = createAxiosInstance(LIVE_KINNECT_INSTANCE_DEFAULTS);
liveKinnectAuthorizedClient.setCSRF = csrf => {
	tokenManager.setLocalStorageKey('live-kinnect');
	if (liveKinnectAuthorizedClient._csrf !== csrf) {
		// if csrf changes, user should be on a new session, so clear in memory tokens
		// this could be something like a new user or an existing user with a new session
		tokenManager.clearTokens();
	}
	liveKinnectAuthorizedClient._csrf = csrf;
};

const getUpdatedToken: GetUpdatedTokenProps = async (refreshToken = undefined) => {
	// accessKey value is unimportant to the backend as the active session is whats ultimately validated against
	const response = await getAccessTokens('JSESSIONID', liveKinnectAuthorizedClient._csrf as string, refreshToken || undefined);
	if (!response.data?.token) {
		throw new Error(MISSING_ACCESS_TOKEN_MESSAGE);
	}
	tokenManager.setToken(response.data.token);
	tokenManager.setRefreshToken(response.data?.refreshToken);
};

async function getAccessToken() {
	if (!tokenManager.getToken()) {
		if (!tokenManager.isFetchingToken()) {
			tokenManager.setTokenFetcher(getUpdatedToken());
		}
		await tokenManager.fetchToken();
	}
}

async function refreshAccessToken() {
	if (!tokenManager.getRefreshToken()) {
		throw new Error(MISSING_REFRESH_TOKEN_MESSAGE);
	}
	return getUpdatedToken(tokenManager.getRefreshToken());
}

// set auth token before each request
liveKinnectAuthorizedClient.interceptors.request.use(
	async (config: configProp) => {
		await getAccessToken();

		if (config.headers) {
			config.headers['Authorization'] = `Bearer ${tokenManager.getToken()}`;
		}

		return config;
	},
	error => Promise.reject(error)
);

// refresh access tokens, and retry request
liveKinnectAuthorizedClient.interceptors.response.use(
	response => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;
		if (originalRequest) {
			if (
				!originalRequest._retry &&
				error.response.status === 401 &&
				error.response.data?.errors?.some(
					(err: { type: string; message: string }) => err.type === 'AUTHENTICATION_ERROR' && err.message.match(/jwt token.*expired/i)
				)
			) {
				originalRequest._retry = true;
				await refreshAccessToken();
				return liveKinnectAuthorizedClient(originalRequest);
			} else if (originalRequest._retry && (error.response.status === 401 || error.response.status === 403)) {
				// request was retried and still resulted in an unauthorized/forbidden error, clear out access tokens
				tokenManager.clearTokens();
			}
		}
		return Promise.reject(error);
	}
);
