import React from 'react';

import { default as BootstrapDropdownItem } from 'react-bootstrap/DropdownItem';
import ActionButtonDropdownDivider from './ActionButtonDropdownDivider';

import {
	IconEdit,
	IconAdd,
	IconInformation,
	IconCopy,
	IconDelete,
	IconDownload,
	IconExport,
	IconHistory,
	IconPrint,
	IconCalendarGrid
} from '@optic-delight/icons';
import { ActionButtonDropDownItemProps } from './types';

export const actionButtonDropdownItemVariants = ['add', 'edit', 'delete', 'details', 'copy', 'download', 'export', 'history', 'print', 'schedule'];

const ActionButtonDropdownItem = ({ children, variant, icon, iconTestId, ...props }: ActionButtonDropDownItemProps): JSX.Element => {
	const actionIcon = ActionButtonDropdownItem.getIcon(icon as React.ElementType, variant, iconTestId);

	const isDelete = ActionButtonDropdownItem.getIsDelete(variant);
	if (isDelete) {
		props.className = `${typeof props.className === 'string' ? props.className : ''} text-danger`;
	}

	return (
		<>
			{isDelete && <ActionButtonDropdownDivider />}
			<BootstrapDropdownItem {...props}>
				{!!actionIcon && actionIcon}
				{ActionButtonDropdownItem.getChildren(children, variant)}
			</BootstrapDropdownItem>
		</>
	);
};

ActionButtonDropdownItem.getIcon = (icon?: React.ElementType | string, variant?: string, testId?: string) => {
	const variants: { [key: string]: React.ElementType } = {
		add: IconAdd,
		edit: IconEdit,
		details: IconInformation,
		delete: IconDelete,
		copy: IconCopy,
		download: IconDownload,
		export: IconExport,
		history: IconHistory,
		print: IconPrint,
		schedule: IconCalendarGrid
	};
	if (variant && !variants[variant] && !icon) {
		return;
	}
	const ActionIcon = (variant && variants[variant]) || icon;
	return ActionIcon && <ActionIcon fixedWidth data-testid={testId} />;
};
ActionButtonDropdownItem.getChildren = (children: React.ReactNode, variant?: string) => {
	if (typeof children === 'undefined') {
		switch (variant) {
			case 'add':
				return 'Add';
			case 'edit':
				return 'Edit';
			case 'delete':
				return 'Delete';
			case 'details':
				return 'Details';
			case 'copy':
				return 'Copy';
			case 'download':
				return 'Download';
			case 'export':
				return 'Export';
			case 'history':
				return 'History';
			case 'print':
				return 'Print';
			case 'schedule':
				return 'Schedule';
			default:
				break;
		}
	}

	return children;
};
ActionButtonDropdownItem.getIsDelete = (variant?: string) => {
	return variant === 'delete';
};

ActionButtonDropdownItem.displayName = 'ActionButtonDropdownItem';
export default ActionButtonDropdownItem;
