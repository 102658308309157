import { useEffect } from 'react';
import axeRegister from '../axe';
import { UseA11yAuditorProps } from '../types';

/**
 * Custom hook to initialize accessibility testing from within any component
 *
 * @param options - pass thru configuration options for @axe-core/react
 */
const useA11yAuditor = (options: UseA11yAuditorProps) => {
	// intentionally running this every re-render
	useEffect(() => {
		axeRegister(options?.context, { ...options?.config, disableDeduplicate: true });
	});
};

export default useA11yAuditor;
