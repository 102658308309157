import React from 'react';
import { Tabs as BootstrapTabs, Fade as BootstrapFade, TabsProps } from 'react-bootstrap';
import Tab from './Tab';

const Tabs = (props: TabsProps) => {
	return <BootstrapTabs {...props} transition={BootstrapFade} />;
};

Tabs.Tab = Tab;

export default Tabs;
