import { Theme } from './iconMapping';

export const iconPrefix = 'fa';
export const supportedThemes: Record<string, Theme> = {
	regular: 'regular',
	solid: 'solid',
	duoTone: 'duotone'
};
export const defaultTheme: Theme = supportedThemes.regular;

export const attentionColors = {
	error: '#e11313',
	warning: '#f0c419',
	success: '#33834c',
	info: '#0dcaf0'
};

export const utilityClasses = {
	layers: `${iconPrefix}-layers`,
	layersCounter: `${iconPrefix}-layers-counter`,
	layersText: `${iconPrefix}-layers-text`,
	listIcons: `${iconPrefix}-ul`,
	listItemIcon: `${iconPrefix}-li`,
	stackIcons: `${iconPrefix}-stack`,
	bounce: `${iconPrefix}-bounce`,
	flip: `${iconPrefix}-flip`,
	flipDirection: `${iconPrefix}-flip-%s`,
	shake: `${iconPrefix}-shake`,
	pulse: `${iconPrefix}-spin-pulse`,
	spin: `${iconPrefix}-spin`,
	reverse: `${iconPrefix}-spin-reverse`,
	beatFade: `${iconPrefix}-beat-fade`,
	beat: `${iconPrefix}-beat`,
	fade: `${iconPrefix}-fade`,
	inverse: `${iconPrefix}-inverse`,
	fixedWidth: `${iconPrefix}-fw`,
	border: `${iconPrefix}-border`,
	size: `${iconPrefix}-%s`,
	rotation: `${iconPrefix}-rotate-%s`,
	stack: `${iconPrefix}-stack-%s`,
	swapOpacity: `${iconPrefix}-swap-opacity`,
	grow: 'grow-%s',
	shrink: 'shrink-%s',
	right: 'right-%s',
	left: 'left-%s',
	up: 'up-%s',
	down: 'down-%s',
	rotate: 'rotate-%s',
	flipHorizontal: 'flip-h',
	flipVertical: 'flip-v',
	position: `${iconPrefix}-layers-%s`
};

export const dataAttributes = {
	mask: `data-${iconPrefix}-mask`,
	maskId: `data-${iconPrefix}-mask-id`,
	transform: `data-${iconPrefix}-transform`
};

export const customCSSProperties = {
	backgroundColor: `--${iconPrefix}-counter-background-color`,
	borderRadius: `--${iconPrefix}-counter-border-radius`,
	lineHeight: `--${iconPrefix}-counter-line-height`,
	maxWidth: `--${iconPrefix}-counter-max-width`,
	minWidth: `--${iconPrefix}-counter-min-width`,
	padding: `--${iconPrefix}-counter-padding`,
	scale: `--${iconPrefix}-counter-scale`,
	top: `--${iconPrefix}-top`,
	bottom: `--${iconPrefix}-bottom`,
	right: `--${iconPrefix}-right`,
	left: `--${iconPrefix}-left`,
	margin: `--${iconPrefix}-li-margin`,
	width: `--${iconPrefix}-li-width`,
	zIndex: `--${iconPrefix}-stack-z-index`,
	inverse: `--${iconPrefix}-inverse`,
	primaryOpacity: `--${iconPrefix}-primary-opacity`,
	primaryColor: `--${iconPrefix}-primary-color`,
	secondaryOpacity: `--${iconPrefix}-secondary-opacity`,
	secondaryColor: `--${iconPrefix}-secondary-color`
};
