import React from 'react';
import { Button } from '../button';
import { Icon } from '@optic-delight/icons';
import { ActionButtonsProps } from './types';

export const ConfirmLabel = 'Confirm';
export const CancelLabel = 'Cancel';

const ActionButtons = (props: ActionButtonsProps) => {
	const { confirmLabel = ConfirmLabel, onConfirm, cancelLabel = CancelLabel, onCancel, style = {} } = props;

	const wrapperClasses: React.CSSProperties = {
		display: 'flex',
		position: 'absolute',
		right: 0,
		top: '100%',
		marginTop: '5px',
		zIndex: 99,
		...style
	};

	return (
		<div style={wrapperClasses} className="clearfix bg-body">
			<div className="float-end">
				<Button
					aria-label={confirmLabel}
					onClick={onConfirm}
					variant="primary"
					size="sm"
					className="shadow-sm me-1"
					data-testid="inline-action-button-confirm">
					<Icon icon="check" />
				</Button>
			</div>

			<div className="float-start">
				<Button aria-label={cancelLabel} onClick={onCancel} size="sm" className="shadow-sm" data-testid="inline-action-button-cancel">
					<Icon icon="remove" />
				</Button>
			</div>
		</div>
	);
};
ActionButtons.displayName = 'ActionButtons';

export default ActionButtons;
