import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useAuthentication } from '../authorization/useAuthentication';

export default function Header(): JSX.Element {
	const { currentUser } = useAuthentication();
	return (
		<Navbar as="header" bg="light" expand="lg">
			<Container fluid="xxl">
				<Navbar.Brand as={Link} to="/" className="p-2">
					Hello Cloud
				</Navbar.Brand>
				{currentUser ? (
					<>
						<Navbar.Toggle aria-controls="header-navigation" />
						<Navbar.Collapse id="header-navigation">
							<Nav className="ms-auto">
								<Nav.Link as={Link} to="/">
									Home
								</Nav.Link>
								<Nav.Link as={Link} to="modify">
									Add User
								</Nav.Link>
								<Nav.Link as={Link} to="logoff" className="btn btn-secondary">
									Sign Off
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</>
				) : null}
			</Container>
		</Navbar>
	);
}
