import React, { useState } from 'react';
import Modal from '../modal/Modal';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';
import { ButtonProps, DeleteButtonProps } from './types';
import { DeleteButtonConfirmationModal } from '../modal';
import CustomizedButtonWrapper from './CustomizedButtonWrapper';

export const DeleteButtonDefaultText = 'Delete';
export const ModalCancelButtonDefaultText = 'Cancel';
export const ModalDeleteButtonDefaultText = 'Delete';

export const deleteButtonDefaultProps = {
	triggerVariant: 'default' as const,
	variant: 'danger' as const,
	icon: null,
	modalTitle: 'Delete confirmation',
	modalBody: 'Are you sure you want to delete?'
};

const TriggerButton = ({ onClick, children = DeleteButtonDefaultText, ...props }: ButtonProps): JSX.Element => (
	<CustomizedButtonWrapper variant={deleteButtonDefaultProps.variant} onClick={onClick} {...props}>
		{children}
	</CustomizedButtonWrapper>
);

TriggerButton.displayName = 'Delete';

const ConfirmationModal = ({ show, onHide, children, title, body, cancelLabel = ModalCancelButtonDefaultText }: DeleteButtonConfirmationModal) => (
	<Modal onHide={onHide} show={show} closeLabel={cancelLabel}>
		<ModalHeader>{title}</ModalHeader>
		<ModalBody>{body}</ModalBody>
		<ModalFooter>{children}</ModalFooter>
	</Modal>
);

const DeleteButton = React.forwardRef<HTMLButtonElement, DeleteButtonProps>(
	(
		{
			'aria-label': ariaLabel,
			hiddenLabel,
			children = DeleteButtonDefaultText,
			modalTitle = deleteButtonDefaultProps.modalTitle,
			modalBody = deleteButtonDefaultProps.modalBody,
			modalDeleteButton = ModalDeleteButtonDefaultText,
			modalCancelButton = ModalCancelButtonDefaultText,
			onCancel,
			...props
		},
		ref
	) => {
		const [show, setShow] = useState(false);

		const handleHide = () => {
			setShow(false);
			onCancel && onCancel();
		};
		const handleShow = () => setShow(true);

		return (
			<>
				<TriggerButton aria-label={ariaLabel} onClick={handleShow} hiddenLabel={hiddenLabel} loading={props.loading}>
					{children}
				</TriggerButton>

				<ConfirmationModal show={show} title={modalTitle} body={modalBody} onHide={handleHide} cancelLabel={modalCancelButton}>
					<CustomizedButtonWrapper
						aria-label={ariaLabel}
						hiddenLabel={hiddenLabel}
						{...props}
						variant={deleteButtonDefaultProps.variant}
						icon={deleteButtonDefaultProps.icon}
						ref={ref}>
						{modalDeleteButton}
					</CustomizedButtonWrapper>
				</ConfirmationModal>
			</>
		);
	}
);

DeleteButton.displayName = 'DeleteButton';

export default DeleteButton;
