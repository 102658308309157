import React, { PropsWithChildren } from 'react';
import { ColumnInstance } from 'react-table';
import { IconSortable } from './IconSortable';

interface SortButtonProps extends PropsWithChildren<unknown> {
	column: ColumnInstance;
	disabled?: boolean;
}

export function SortButton({ column, children, disabled }: SortButtonProps): JSX.Element {
	return (
		<button
			{...column.getSortByToggleProps({
				title: `Toggle ${column.Header || column.id} Sort`
			})}
			type="button"
			className="btn btn-table-sort pe-4"
			disabled={disabled}>
			{children}
			<span className="position-relative">
				<span className="position-absolute">
					<IconSortable column={column} />
				</span>
			</span>
		</button>
	);
}
