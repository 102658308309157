import * as React from 'react';
import { useListStyle } from './useListStyle';
import Icon, { IconProps } from '../Icon';
import { utilityClasses } from '../utility';

export interface ListItemIconProps extends IconProps {
	listItemStyle?: React.CSSProperties;
}

export const baseTestId = `li-icon-container`;

export function ListItemIcon({ listItemStyle, ...props }: ListItemIconProps): JSX.Element {
	const itemStyle = useListStyle(listItemStyle);

	return (
		<span data-testid={`${baseTestId}${props['data-testid']}`} className={utilityClasses.listItemIcon} style={itemStyle}>
			<Icon {...props} />
		</span>
	);
}
