import React from 'react';
import { FormProvider as ReactHookFormProvider, useForm, FieldValues } from 'react-hook-form';
import { FormContextBridge, FormProps, WithDefaultUseFormProps } from './types';

export const FormContext = React.createContext<FormContextBridge | null>(null);

const FRefForm = <TFormValues extends FieldValues, TFormContext = unknown>(
	{ onSubmit, useFormMethods, noValidate, disabled = false, ...props }: FormProps<TFormValues, TFormContext>,
	ref: React.Ref<HTMLFormElement>
) => {
	return (
		<ReactHookFormProvider {...useFormMethods}>
			<FormContext.Provider value={{ isDisabled: disabled }}>
				<form
					ref={ref}
					onSubmit={useFormMethods.handleSubmit(onSubmit)}
					noValidate={String(noValidate).toLowerCase() === 'novalidate' || !!noValidate}
					{...props}>
					{props.children}
				</form>
			</FormContext.Provider>
		</ReactHookFormProvider>
	);
};

const Form = React.forwardRef(FRefForm) as <TFormValues extends FieldValues, TFormContext = unknown>(
	p: FormProps<TFormValues, TFormContext> & { ref?: React.Ref<HTMLFormElement> }
) => React.ReactElement;

export const withDefaultUseForm = <TFormValues extends FieldValues, TFormContext = unknown>(
	Component: <TFormValues extends FieldValues, TFormContext = unknown>(
		p: FormProps<TFormValues, TFormContext> & { ref?: React.Ref<HTMLFormElement> }
	) => React.ReactElement
) => {
	const WrappedFormComponent = ({ forwardedRef, criteriaMode = 'all', ...props }: WithDefaultUseFormProps<TFormValues>) => {
		const useFormMethods = useForm<TFormValues, TFormContext>({
			criteriaMode: criteriaMode
		});

		return <Component ref={forwardedRef} useFormMethods={useFormMethods} {...props} />;
	};

	return React.forwardRef((props: Omit<WithDefaultUseFormProps<TFormValues>, 'forwardedRef'>, ref: React.ForwardedRef<HTMLFormElement>) => {
		return <WrappedFormComponent forwardedRef={ref} {...props} />;
	});
};

export const ComposedForm = withDefaultUseForm(Form);

export default Form;
