import { iconPickerActionTypes } from './iconPickerActions';

export function iconPickerReducer(state, { type, icon, ...action }) {
	switch (type) {
		case iconPickerActionTypes.show:
			return {
				...state,
				show: true
			};
		case iconPickerActionTypes.hide: {
			return {
				...state,
				...action,
				show: false
			};
		}
		case iconPickerActionTypes.select:
			return {
				...state,
				...action,
				show: false,
				selectedIcon: icon
			};
		case iconPickerActionTypes.filter:
			return {
				...state,
				...action,
				currentPage: 1
			};
		case iconPickerActionTypes.navigate: {
			const nextPage = state.currentPage + action.offset;
			return {
				...state,
				currentPage: nextPage < 1 ? 1 : nextPage > state.totalPages ? state.totalPages : nextPage
			};
		}
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
}
