import { createIcon } from '../utility/createIcon';

export const IconBanking = createIcon('banking');

export const IconCreditCard = createIcon('credit-card');

export const IconDollar = createIcon('dollar');

export const IconSavings = createIcon('savings');

export const IconWallet = createIcon('wallet');
