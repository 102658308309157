import { useCallback } from 'react';
import { inlineEditStateChangeTypes } from '../inlineEditActions';
import { InlineEditValue, UseStateCallbacksProps } from '../types';

export const useStateCallbacks = ({ dispatch, latest, formContext }: UseStateCallbacksProps) => {
	const showEdit = useCallback(() => {
		dispatch({ type: inlineEditStateChangeTypes.EditMode });
	}, [dispatch]);

	const hideEdit = useCallback(() => {
		dispatch({ type: inlineEditStateChangeTypes.ReadMode });
	}, [dispatch]);

	const handleConfirm = useCallback(() => {
		formContext.setValue(latest.current.state.currentValue);
		dispatch({
			type: inlineEditStateChangeTypes.ConfirmEdit,
			selectedValue: latest.current.state.currentValue,
			required: latest.current.props.required
		});
	}, [dispatch, formContext, latest]);

	const handleCancel = useCallback(() => {
		formContext.setValue(latest.current.state.selectedValue);
		dispatch({ type: inlineEditStateChangeTypes.CancelEdit });
	}, [dispatch, formContext, latest]);

	const setValue = useCallback(
		(newSelectedValue: InlineEditValue) => {
			formContext.setValue(newSelectedValue);
			dispatch({
				type: inlineEditStateChangeTypes.SetValue,
				selectedValue: newSelectedValue,
				required: latest.current.props.required
			});
		},
		[dispatch, formContext, latest]
	);

	const setCurrentValue = useCallback(
		(newCurrentValue: InlineEditValue) => {
			dispatch({
				type: inlineEditStateChangeTypes.SetCurrentValue,
				currentValue: newCurrentValue
			});
		},
		[dispatch]
	);

	return { setCurrentValue, showEdit, handleConfirm, handleCancel, hideEdit, setValue };
};
