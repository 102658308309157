import React from 'react';
import Alert from './Alert';
import { AlertVariantProps } from './types';

/**
 *`<SuccessAlert>` is an `<Alert>` with defaulted variant, header, description properties.
 */
const SuccessAlert = React.forwardRef<HTMLDivElement, AlertVariantProps>(({ heading = 'Success', ...props }, ref) => {
	return <Alert heading={heading} ref={ref} variant="success" iconVariant {...props} />;
});
SuccessAlert.displayName = 'SuccessAlert';

export default SuccessAlert;
