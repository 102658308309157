import { CSSProperties } from 'react';
import { customCSSProperties } from '../utility';

export interface StackStyleProps extends CSSProperties {
	inverse?: string; // any valid CSS color value
}

export function useStackStyle(style: StackStyleProps = {}): CSSProperties {
	const { zIndex, inverse, ...cssProperties } = style;
	return {
		[customCSSProperties.zIndex]: zIndex,
		[customCSSProperties.inverse]: inverse,
		...cssProperties
	};
}
