import React from 'react';
import WidgetTitle from './WidgetTitle';
import { WidgetElementProps } from './types';
import { widgetBaseClass } from './utility/constants';

export default function WidgetHeader({ className, children, ...props }: WidgetElementProps): JSX.Element {
	const classes = [`${widgetBaseClass}-header`, className].filter(Boolean).join(' ');
	return (
		<div className={classes} {...props}>
			<WidgetTitle>{children}</WidgetTitle>
		</div>
	);
}
