import React from 'react';

import { ListGroupItemCheckableProps } from './types';

import Tooltip from '../tooltip/Tooltip';
import { Checkable } from '../forms';
import { Helpblock } from '../utility';
import ListGroupItem from './ListGroupItem';

const ListGroupItemCheckable = ({ tooltip, id, label, required, helptext, ...props }: ListGroupItemCheckableProps) => (
	<ListGroupItem as="label">
		<Checkable id={id} className="me-2" inputonly {...props} />
		{label}
		{required && <span className="text-danger"> *</span>}
		<Tooltip id={id ? id + '_tooltip' : undefined}>{tooltip}</Tooltip>
		<Helpblock id={helptext ? id + '_helptext' : null}>{helptext}</Helpblock>
	</ListGroupItem>
);

ListGroupItemCheckable.displayName = 'ListGroupItemCheckable';

export default ListGroupItemCheckable;
