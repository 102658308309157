import React from 'react';
import { TranslatableTextProviderProps, UseTranslatableTextData } from './types';

export const TranslatableTextContext = React.createContext<UseTranslatableTextData | undefined>(undefined);
TranslatableTextContext.displayName = 'TranslatableTextContext';

export const TranslatableTextProvider = ({ children, value }: React.PropsWithChildren<TranslatableTextProviderProps>): JSX.Element => {
	return <TranslatableTextContext.Provider value={value}>{children}</TranslatableTextContext.Provider>;
};

TranslatableTextProvider.displayName = 'TranslatableTextProvider';
