import { SpriteIconName } from './types';
import { IconMappingTaggable } from '../shared';

export interface SpriteIconMapDefinition extends IconMappingTaggable {
	iconName: string;
}
export const spriteIconMap: Record<SpriteIconName, SpriteIconMapDefinition> = {
	'401k': { iconName: '401k', tags: ['401k', '4k', '403b'] },
	'benefits-edit': { iconName: 'benefits-edit' },
	'brand-drugs': { iconName: 'brand-drugs' },
	'briefcase-warning': { iconName: 'briefcase-warning' },
	'calendar-money': { iconName: 'calendar-money' },
	'chart-bar': { iconName: 'chart-bar' },
	'chart-donut': { iconName: 'chart-donut' },
	'chart-line': { iconName: 'chart-line' },
	'chart-pie': { iconName: 'chart-pie' },
	'chat-question': { iconName: 'bubble-question' },
	'check-mark': { iconName: 'check' },
	'clipboard-medical': { iconName: 'clipboard-medical' },
	'clipboard-pen': { iconName: 'clipboard-pen' },
	'credit-cards': { iconName: 'credit-cards' },
	'critical-illness': {
		iconName: 'critical-illness',
		tags: [
			'volchci',
			'volchci2',
			'volci',
			'volci2',
			'voleeci',
			'voleeci2',
			'volspci',
			'volspci2',
			'chci',
			'chci2',
			'ci',
			'ci2',
			'eeci',
			'eeci2',
			'spci',
			'spci2',
			'cicf',
			'criticalillness'
		]
	},
	'danger-icon': { iconName: 'danger-icon', tags: ['error'] },
	'device-protection': { iconName: 'device-protection' },
	'doctor-female': { iconName: 'dr-female' },
	'doctor-male': { iconName: 'dr-male' },
	'emoji-happy': { iconName: 'emoji-happy' },
	'emoji-neutral': { iconName: 'emoji-neutral' },
	'emoji-sad': { iconName: 'emoji-sad' },
	'file-archive': { iconName: 'zip' },
	'file-excel': { iconName: 'xls' },
	'file-image': { iconName: 'image-file' },
	'file-pdf': { iconName: 'pdf' },
	'file-powerpoint': { iconName: 'ppt' },
	'file-rtf': { iconName: 'rtf' },
	'file-sign': { iconName: 'document-sign' },
	'file-text': { iconName: 'text' },
	'file-upload': { iconName: 'upload' },
	'file-url': { iconName: 'url' },
	'file-verification': { iconName: 'verification' },
	'file-video': { iconName: 'video-file' },
	'file-word': { iconName: 'doc' },
	'folder-closed': { iconName: 'folder-closed' },
	'folder-in-progress': { iconName: 'folder-in-progress' },
	'folder-no-change': { iconName: 'folder-no-change' },
	'folder-pending': { iconName: 'folder-pending' },
	'folder-verification': { iconName: 'folder-verification' },
	'gains-coverage': { iconName: 'gains-coverage' },
	'generic-drugs': { iconName: 'generic-drugs' },
	'generic-file': { iconName: 'generic-file' },
	'goal-savings': { iconName: 'goal-savings', tags: ['gsa', 'sga'] },
	'health-average': { iconName: 'health-average' },
	'health-good': { iconName: 'health-good' },
	'health-poor': { iconName: 'health-poor' },
	'home-improvement': { iconName: 'home-improvement' },
	'id-card': { iconName: 'id' },
	'id-theft': { iconName: 'id-theft' },
	'info-icon': { iconName: 'info-icon' },
	'insurance-card': { iconName: 'insurance-card' },
	'large-purchase': { iconName: 'large-purchase' },
	'life-child': { iconName: 'life-child', tags: ['deplife', 'deplife2', 'volchlife', 'volchlife2', 'dep', 'vcl', 'vcl2'] },
	'life-spouse': { iconName: 'life-spouse', tags: ['volsplife', 'volsplife2', 'vsl', 'vsl2'] },
	'light-bulb': { iconName: 'light-bulb' },
	'loses-coverage': { iconName: 'loses-coverage' },
	'map-pin-health': { iconName: 'map-pin-health', tags: ['bg', 'fg'] },
	'non-smoker': { iconName: 'non-smoker', tags: ['tc'] },
	'question-mark': { iconName: 'question-mark' },
	'reference-center': { iconName: 'reference-center' },
	'savings-ban': { iconName: 'piggy-bank-no' },
	'savings-broken': { iconName: 'piggy-bank-broken' },
	'savings-question-mark': { iconName: 'piggy-bank-question-mark' },
	'search-money': { iconName: 'search-money' },
	'service-rep': { iconName: 'service-rep' },
	'shield-medical': { iconName: 'shield-medical' },
	'shield-money': { iconName: 'shield-money' },
	'success-icon': { iconName: 'success-icon' },
	'thumbs-up': { iconName: 'thumbs-up' },
	'wallet-lock': { iconName: 'wallet-lock' },
	'warning-icon': { iconName: 'warning-icon' },
	'weather-sprinkle': { iconName: 'weather-sprinkle' },
	'weather-thunder': { iconName: 'weather-thunder' },
	'wedding-rings': { iconName: 'wedding-rings' },
	'woman-desk': { iconName: 'woman-desk', tags: ['eap'] },
	'x-mark': { iconName: 'x' },
	'x-ray': { iconName: 'x-ray' },
	accident: {
		iconName: 'accident',
		tags: [
			'volacc',
			'volaccident',
			'voladd',
			'volchadd',
			'volchadd2',
			'volspadd',
			'volspadd2',
			'acc',
			'vdd',
			'vsd',
			'vcd2',
			'vpa',
			'vsd',
			'vsd2',
			'acf',
			'ad&d',
			'accident'
		]
	},
	ambulance: { iconName: 'ambulance' },
	award: { iconName: 'award' },
	baby: { iconName: 'baby' },
	ban: { iconName: 'no-sign' },
	banking: { iconName: 'bank' },
	beneficiary: { iconName: 'beneficiary' },
	benefits: { iconName: 'benefits' },
	bill: { iconName: 'bill' },
	birthday: { iconName: 'birthday' },
	briefcase: { iconName: 'briefcase' },
	bullhorn: { iconName: 'bullhorn' },
	bus: { iconName: 'bus', tags: ['transit', 'mt', 'mtr', 'trn', 'trp'] },
	calculator: { iconName: 'calculator' },
	calendar: { iconName: 'calendar' },
	cancer: { iconName: 'cancer', tags: ['cr[0-9]+'] },
	car: { iconName: 'car' },
	cash: { iconName: 'cash' },
	cat: { iconName: 'cat' },
	chat: { iconName: 'chat' },
	chatbot: { iconName: 'chatbot' },
	checklist: { iconName: 'checklist' },
	cobra: { iconName: 'cobra' },
	coins: { iconName: 'coins', tags: ['credit', 'cr'] },
	compare: { iconName: 'compare' },
	configuration: { iconName: 'user-cog' },
	contact: { iconName: 'user-contact' },
	dcfsa: { iconName: 'dcfsa', tags: ['flex2'] },
	death: { iconName: 'death' },
	dental: { iconName: 'dental', tags: ['dental', 'den'] },
	dependent: { iconName: 'dependent' },
	dice: { iconName: 'dice' },
	disability: { iconName: 'short-term-disability', tags: ['ltd', 'ltd[0-9]+', 'std', 'std[0-9]+', 'dbl'] },
	doctor: { iconName: 'doctor' },
	dog: { iconName: 'dog' },
	email: { iconName: 'email' },
	eyewear: { iconName: 'eyewear' },
	female: { iconName: 'female' },
	file: { iconName: 'document' },
	files: { iconName: 'documents' },
	fitness: { iconName: 'fitness', tags: ['fit'] },
	folder: { iconName: 'folder-open' },
	fsa: { iconName: 'fsa', tags: ['flex', 'flx', 'ffsa', 'fsa', 'lfsa'] },
	gavel: { iconName: 'gavel', tags: ['legal', 'legal[0-9]+', 'lgl[0-9]+'] },
	gift: { iconName: 'gift' },
	government: { iconName: 'government' },
	health: { iconName: 'health' },
	heart: { iconName: 'heart', tags: ['wp'] },
	hospital: { iconName: 'hospital', tags: ['volhosp', 'hosp', 'hosp65', 'hicf', 'hospital'] },
	house: { iconName: 'house' },
	hra: { iconName: 'hra', tags: ['fhra', 'hra', 'lhra'] },
	hsa: { iconName: 'hsa', tags: ['hsa'] },
	image: { iconName: 'image' },
	life: { iconName: 'life', tags: ['life', 'life[0-9]+', 'vollife', 'vollife2', 'lif', 'li[0-9]+', 'vlf', 'vlf2'] },
	lock: { iconName: 'lock' },
	male: { iconName: 'male' },
	medical: { iconName: 'medical', tags: ['medical', 'medical[0-9]+', 'hmo', 'hmo65', 'hlt', 'hdhp', 'mm', 'ppo', 'ppo65', 'pos'] },
	medicare: { iconName: 'medicare', tags: ['medicare', 'hmoma', 'hmomapd', 'ppoma', 'ppomapd', 'me_atph'] },
	newspaper: { iconName: 'newspaper' },
	nurse: { iconName: 'nurse', tags: ['nl'] },
	parking: { iconName: 'parking', tags: ['parking', 'prk', 'prk', 'prp'] },
	pencil: { iconName: 'pencil' },
	plan: { iconName: 'plan' },
	prescription: { iconName: 'prescription', tags: ['mod', 'pdg', 'pdg', 'rx', 'pd'] },
	profile: { iconName: 'user' },
	renter: { iconName: 'renter' },
	report: { iconName: 'report' },
	risk: { iconName: 'risk' },
	savings: { iconName: 'piggy-bank' },
	school: { iconName: 'school', tags: ['ta', 'tui'] },
	search: { iconName: 'search' },
	shot: { iconName: 'shot' },
	smoker: { iconName: 'smoker' },
	specialist: { iconName: 'specialist' },
	star: { iconName: 'star' },
	stocks: { iconName: 'stocks', tags: ['stock', 'sto'] },
	surgeon: { iconName: 'surgeon' },
	travel: { iconName: 'travel' },
	trophy: { iconName: 'trophy' },
	umbrella: { iconName: 'umbrella' },
	video: { iconName: 'video' },
	vision: { iconName: 'vision', tags: ['vision', 'vis'] },
	wallet: { iconName: 'wallet' }
};
