import React from 'react';
import { ModalProviderProps } from './types';

export const ModalContext = React.createContext<ModalProviderProps | undefined>(undefined);
ModalContext.displayName = 'ModalContext';

const defaultCloseLabel = 'Close';
const defaultAtionLabel = 'Continue';

export default function ModalProvider({
	continueLabel = defaultAtionLabel,
	closeLabel = defaultCloseLabel,
	onHide,
	icon,
	onContinue,
	...props
}: React.PropsWithChildren<ModalProviderProps>): JSX.Element {
	return <ModalContext.Provider value={{ onHide, closeLabel, continueLabel, icon, onContinue }} {...props} />;
}

ModalProvider.displayName = 'ModalProvider';
