import { DEFAULT_QUEUE_INTERVAL } from './constants';

export class Queue<T = unknown> {
	private queue: T[] = [];
	private unloading = false;
	private readonly intervalHandler: NodeJS.Timeout;

	constructor(private sendCallback: (auditRecords: T[]) => void, private filterCallback = () => true, private interval = DEFAULT_QUEUE_INTERVAL) {
		this.intervalHandler = setInterval(this.send.bind(this), this.interval);
		window.addEventListener('beforeunload', this.unloadListener);
	}

	private unloadListener() {
		this.unloading = true;
		this.send();
	}

	public list() {
		return this.queue;
	}

	public isUnloading() {
		return this.unloading;
	}

	public add(data: T) {
		this.queue.push(data);
	}

	public send() {
		if (!this.queue.length) {
			return Promise.resolve();
		}

		const queueToSend = this.queue.filter(this.filterCallback);
		if (!queueToSend.length) {
			return Promise.resolve();
		}
		this.queue = this.queue.filter(data => !queueToSend.includes(data));

		this.sendCallback(queueToSend);
	}

	public cleanup() {
		clearInterval(this.intervalHandler);
		window.removeEventListener('beforeunload', this.unloadListener);
	}
}
