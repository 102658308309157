import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { isColumnEmpty } from '../utility';

const HorizontalTable = props => {
	const { headerGroups, footerGroups, rows, prepareRow } = props;

	const columnValue = useCallback(
		column => {
			return rows.map(row => {
				prepareRow(row);
				return row.cells
					.filter(cell => cell.column.id === column.id)
					.map(cell => (
						<td key={'column-value:' + column.id} className="text-end">
							{cell.column.cellFormat ? cell.column.cellFormat(cell.value) : cell.render('Cell')}
						</td>
					));
			});
		},
		[rows, prepareRow]
	);

	return (
		<>
			<tbody data-testid="horizontal">
				{headerGroups.map(headerGroup =>
					headerGroup.headers
						.filter(item => !isColumnEmpty(item, 'Header'))
						.map(column => (
							<tr key={column.id}>
								<th scope="col">{column.render('Header')}</th>
								{columnValue(column)}
							</tr>
						))
				)}
			</tbody>
			{footerGroups.map((footerGroup, itemIdx) => (
				<tfoot key={itemIdx}>
					{footerGroup.headers
						.filter(item => !isColumnEmpty(item, 'Footer'))
						.map(header => (
							<tr key={header.id}>
								<th>{header.render('Footer')}</th>
								<td data-testid="footer-sum" className="text-end">
									{header.cellFormat ? header.cellFormat(header) : header.render('Cell')}
								</td>
							</tr>
						))}
				</tfoot>
			))}
		</>
	);
};

HorizontalTable.propTypes = {
	headerGroups: PropTypes.array.isRequired,
	footerGroups: PropTypes.array.isRequired,
	rows: PropTypes.array.isRequired,
	prepareRow: PropTypes.func
};

HorizontalTable.displayName = 'HorizontalTable';

export default HorizontalTable;
