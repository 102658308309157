import React, { ChangeEvent, ForwardedRef, LegacyRef, ReactElement } from 'react';
import { useMultipleSelection, useSelect } from 'downshift';
import { IGroupedListOption, MultiSelectProps } from './types';
import FormGroupValidation from '../shared/FormGroupValidation';
import useFormGroupValidation from '../shared/hooks/useFormGroupValidation';
import { inputDefaultProps } from '../../../props/inputProps';
import { noop } from '../../../helpers';
import MultiSelectSelectedItems from './MultiSelectSelectedItems';
import OptionsList from './OptionsList';
import { useSearchableItemsList } from './hooks';
import Search from '../search/Search';
import { getSelectMenuHeight, isInHiddenGroup, selectInputReducerUtils, updateSelectReducerChanges } from './utility';
import { IconLoading } from '@optic-delight/icons';
import { useControlledFocusOnError } from '../shared/hooks/useControlledFocusOnError';
import { useFlippedDropdownMenu } from '../../../helpers/hooks/useFlippedDropdownMenu';

export const MultiSelectDefaultPlaceholder = 'Select...';
export const MultiSelectLoadingPlaceholder = 'Loading...';
export const MultiSelectSearchDefaultPlaceholder = 'Search...';
export const MultiSelectNotFoundPlaceholder = 'Nothing found';

/**
 * see [Input](/docs/framework-forms-input--default) component for complete documentation on the underlying implementation
 */
const MultiSelect = React.forwardRef(<T extends number[] | string[]>(props: MultiSelectProps<T>, ref: ForwardedRef<HTMLInputElement>): ReactElement => {
	const {
		hideSelected = false,
		placeholder = MultiSelectDefaultPlaceholder,
		loadingPlaceholder = MultiSelectLoadingPlaceholder,
		searchPlaceholder = MultiSelectSearchDefaultPlaceholder,
		notFoundPlaceholder = MultiSelectNotFoundPlaceholder,
		compareType,
		onChange,
		requiredFieldMessage,
		suppressValidationMessage,
		'data-testid': dataTestId,
		value,
		defaultValue,
		items = React.useMemo(() => [], []),
		size,
		disabled,
		readOnly,
		loading = false,
		searchable = false,
		onBlur,
		textTruncation = true,
		...selectProps
	} = props;

	const { filteredItems, searchValue, setSearchValue, searchRef, getGroupItems, initialSelectedValue, hiddenGroups, collapseGroup, getItemByValue } =
		useSearchableItemsList({
			items,
			value,
			defaultValue,
			compareType,
			multiple: true
		});
	const filteredItemsToDisplay = (filteredItems as IGroupedListOption[]).filter(item => !isInHiddenGroup(item, hiddenGroups));
	const isSearchVisible = typeof searchable === 'number' ? filteredItems.length >= searchable : searchable;
	const isDisabled = disabled || loading;

	function getSelectedItems(): IGroupedListOption[] {
		return downshiftMultiSelectProps.selectedItems.map(getItemByValue).filter(Boolean) as IGroupedListOption[]; // type cast as filter thinks it can return undefined, but we filtered it
	}

	function onSearchInputChange(event: ChangeEvent<HTMLInputElement>) {
		setSearchValue(event.target.value);
	}

	function toggleGroup(group: IGroupedListOption) {
		const toggledItems = getGroupItems(group, filteredItems);
		const hashedSelected = Object.fromEntries(downshiftMultiSelectProps.selectedItems.map(item => [item, true]));
		const allClear = toggledItems.every(item => !hashedSelected[item.value ?? '']);

		if (allClear) {
			toggledItems.forEach(item => {
				downshiftMultiSelectProps.addSelectedItem(item.value ?? '');
			});
		} else {
			toggledItems.forEach(item => {
				if (!hashedSelected[item.value ?? '']) return;
				downshiftMultiSelectProps.removeSelectedItem(item.value ?? '');
			});
		}
	}

	const { setSelectedItems, ...downshiftMultiSelectProps } = useMultipleSelection<string | number>({
		[typeof value !== 'undefined' ? 'selectedItems' : 'initialSelectedItems']: initialSelectedValue as (string | number)[]
	});

	const downshiftProps = useSelect<IGroupedListOption>({
		id: selectProps.id,
		items: filteredItemsToDisplay || [],
		selectedItem: null,
		stateReducer: (state, actionAndChanges) => {
			const { changes, type } = actionAndChanges;

			switch (type) {
				case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
					// without this code downshift don't let us put spaces in our search input,
					// but we use it only if no one item is currently highlighted
					if (state.highlightedIndex === -1) {
						if (searchRef.current !== document.activeElement) {
							searchRef.current?.focus();
						}
						setSearchValue(searchValue + ' ');
					}

					return updateSelectReducerChanges(changes, true, state.highlightedIndex);

				case useSelect.stateChangeTypes.MenuKeyDownEnter:
				case useSelect.stateChangeTypes.ItemClick:
					return updateSelectReducerChanges(changes, true, state.highlightedIndex);

				case useSelect.stateChangeTypes.MenuKeyDownEscape:
					return selectInputReducerUtils.menuKeydownEscape(changes, searchValue, setSearchValue);

				case useSelect.stateChangeTypes.MenuBlur:
					return selectInputReducerUtils.menuBlur(changes, searchRef, setSearchValue);

				case useSelect.stateChangeTypes.ToggleButtonKeyDownCharacter:
					return selectInputReducerUtils.toggleButtonKeydownCharacter(changes, setSearchValue);

				case useSelect.stateChangeTypes.MenuKeyDownCharacter:
					return selectInputReducerUtils.menuKeydownCharacter(changes, searchRef);
			}

			return changes;
		},
		onSelectedItemChange: ({ selectedItem }) => {
			if (selectedItem?.value === undefined || selectedItem.isGroup) return;

			let selectedValues: IGroupedListOption[];
			const hashedValues = Object.fromEntries(downshiftMultiSelectProps.selectedItems.map(item => [item, true]));

			const newFilteredItems = getSelectedItems();

			if (hashedValues[selectedItem.value]) {
				downshiftMultiSelectProps.removeSelectedItem(selectedItem.value);
				selectedValues = newFilteredItems.filter(item => item.value !== selectedItem.value);
			} else {
				downshiftMultiSelectProps.addSelectedItem(selectedItem.value);
				selectedValues = [...newFilteredItems, selectedItem];
			}

			validation.setIsInvalid(validation.isRequired && selectedValues.length === 0);

			const element = inputRef?.current as unknown as HTMLInputElement;

			const setHiddenInputValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
			setHiddenInputValue?.call(element, selectedValues.map(item => item.value).join(','));

			const event = new Event('input', { bubbles: true });
			element.dispatchEvent(event);
		}
	});
	const { ref: buttonRef } = useControlledFocusOnError<HTMLButtonElement>({ name: selectProps.name });
	const downshiftInputProps = downshiftProps.getToggleButtonProps(
		downshiftMultiSelectProps.getDropdownProps({ ref: buttonRef, preventKeyAction: downshiftProps.isOpen })
	);
	const downshiftLabelProps = downshiftProps.getLabelProps();

	const { getGroupProps, getLabelProps, getHelpblockProps, getInputProps, validation, inputRef } = useFormGroupValidation({
		id: downshiftInputProps.id,
		ref,
		disabled: isDisabled,
		readOnly,
		...selectProps
	});

	// group props
	const { controlId, ...groupProps } = getGroupProps();

	// label props
	const baseLabelProps = getLabelProps({ htmlFor: downshiftInputProps.id });
	const labelProps = { ...downshiftLabelProps, ...baseLabelProps };

	// menu props
	const isNothingFoundShown = isSearchVisible && filteredItemsToDisplay.length === 0;
	const downshiftMenuProps = downshiftProps.getMenuProps({
		'aria-labelledby': labelProps.id,
		tabIndex: 0,
		style: {
			height: getSelectMenuHeight(isNothingFoundShown ? 1 : filteredItemsToDisplay.length)
		}
	});

	// input props
	let inputValue = placeholder;
	if (loading) {
		inputValue = loadingPlaceholder;
	}
	const selectedItemsLength = downshiftMultiSelectProps.selectedItems?.length;
	const hasSelectedItems = selectedItemsLength > 0;
	const hasOneSelectedItem = selectedItemsLength === 1;

	if (hasSelectedItems) {
		inputValue = hasOneSelectedItem ? getItemByValue(downshiftMultiSelectProps.selectedItems[0] || -1)?.label || '' : `{ ${selectedItemsLength} selected }`;
	}

	const baseInputProps = getInputProps({
		id: downshiftInputProps.id,
		value: inputValue,
		'aria-labelledby': labelProps.id,
		'aria-owns': downshiftMenuProps.id,
		className: ['form-select', validation.isInvalid && 'is-invalid', loading && 'loading'].filter(Boolean).join(' ')
	});
	// onBlur from getInputProps returns a string when multiselect closes via toggle, to prevent this we override onBlur
	const {
		onBlur: onInputBlur = onBlur,
		'aria-required': ariaRequired = undefined,
		disabled: toggleBtnDisabled = undefined,
		...inputProps
	} = { ...downshiftInputProps, ...baseInputProps };

	// helpblock props
	const helpblockProps = getHelpblockProps({
		id: `${downshiftInputProps.id}_helptext`
	});

	// effects
	const { setValue = noop } = validation.formContext;
	React.useEffect(() => {
		if (!baseInputProps.disabled) {
			setValue(baseInputProps.name, downshiftMultiSelectProps.selectedItems);
		}
	}, [downshiftMultiSelectProps.selectedItems, baseInputProps.name, baseInputProps.disabled, setValue]);

	const { dropdownMenuRef, isDropdownMenuFlipped } = useFlippedDropdownMenu(downshiftProps.isOpen);

	return (
		<FormGroupValidation
			groupProps={groupProps}
			labelProps={labelProps}
			helpblockProps={helpblockProps}
			validation={validation}
			inputRef={inputRef}
			inline={selectProps.inline}
			requiredFieldMessage={requiredFieldMessage}
			suppressValidationMessage={suppressValidationMessage}>
			<div className={['form-select-wrapper', validation.isInvalid && 'is-invalid'].filter(Boolean).join(' ')}>
				{!hideSelected && downshiftMultiSelectProps.selectedItems ? (
					<MultiSelectSelectedItems
						selectedItems={getSelectedItems()}
						onClick={(_e: MouseEvent, item: IGroupedListOption) => downshiftProps.selectItem(item)}
					/>
				) : null}

				<span className={loading ? 'input-group input-group-embedded' : undefined}>
					<input {...inputProps} data-testid={dataTestId} type="button" disabled={toggleBtnDisabled || readOnly} />
					{loading && (
						<span className="input-group-text" data-testid="loading-spinner">
							<IconLoading />
						</span>
					)}
				</span>

				{/* Type conversion used here to solve the following type error:  */}
				{/* Type 'MutableRefObject<undefined>' is not assignable to type 'LegacyRef<HTMLInputElement> | undefined'. */}
				<input
					id={selectProps.id}
					data-testid="select-hidden-input"
					aria-required={ariaRequired}
					ref={inputRef as unknown as LegacyRef<HTMLInputElement>}
					value={downshiftMultiSelectProps.selectedItems?.map(t => t).join(',')}
					onInput={event => onChange?.(event as ChangeEvent<HTMLInputElement>)}
					type="hidden"
					name={baseInputProps.name}
					disabled={baseInputProps.disabled}
				/>

				<div
					ref={dropdownMenuRef}
					className={['dropdown-menu w-100 p-0', downshiftProps.isOpen && 'show', isDropdownMenuFlipped && 'dropdown-menu-end']
						.filter(Boolean)
						.join(' ')}>
					{isSearchVisible ? (
						<div className="dropdown-search-field w-100">
							<Search
								groupClassName=""
								ref={searchRef as React.MutableRefObject<HTMLInputElement>}
								value={searchValue}
								placeholder={searchPlaceholder}
								onChange={onSearchInputChange}
								aria-autocomplete="list"
								aria-controls={inputProps.id}
								autoComplete="off"
								onClear={() => {
									setSearchValue('');
								}}
							/>
						</div>
					) : null}

					<ul className="dropdown-menu-items w-100 p-0" {...downshiftMenuProps} data-testid="select-dropdown-menu">
						{downshiftProps.isOpen ? (
							<OptionsList
								getItemProps={downshiftProps.getItemProps}
								getGroupItems={getGroupItems}
								allItems={filteredItems}
								visibleItems={filteredItemsToDisplay}
								hiddenGroups={hiddenGroups}
								highlightedIndex={downshiftProps.highlightedIndex}
								notFoundPlaceholder={notFoundPlaceholder}
								selectedItems={downshiftMultiSelectProps.selectedItems}
								multiple={true}
								searchString={searchValue}
								onGroupCollapse={collapseGroup}
								onGroupToggle={toggleGroup}
								textTruncation={textTruncation}
							/>
						) : null}
					</ul>
				</div>
			</div>
		</FormGroupValidation>
	);
});
MultiSelect.defaultProps = {
	...inputDefaultProps
};
MultiSelect.displayName = 'MultiSelect';

export default MultiSelect as <T>(props: MultiSelectProps<T> & { ref?: ForwardedRef<HTMLFormElement> }) => JSX.Element;
