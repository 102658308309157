import { UserInterfaceResolver } from '../types';
import { unsafeTitleAdapter } from '../adapter/unsafe-title';

export const simpleResolver: UserInterfaceResolver = ({ contract, level: _level, parent: _parent }) => {
	switch (contract.type) {
		case 'text':
			return ['p', unsafeTitleAdapter(contract)];
		case 'inline':
			return ['span', unsafeTitleAdapter(contract)];
		case 'block':
			return ['div', unsafeTitleAdapter(contract)];
		case 'link':
			return [
				'a',
				{
					children: contract.title,
					href: contract.link
				}
			];
		case 'media':
			return [
				'img',
				{
					src: contract.source,
					alt: contract.title || 'unknown image',
					height: contract.view?.height,
					width: contract.view?.width
				}
			];
		default:
			return [];
	}
};
