import React from 'react';
import { WidgetProps } from './types';
import WidgetBody from './WidgetBody';
import WidgetHeader from './WidgetHeader';
import WidgetFooter from './WidgetFooter';
import WidgetTitle from './WidgetTitle';
import { widgetBaseClass } from './utility/constants';

const Widget = ({ href, as: Component = href ? 'a' : 'div', className, ...props }: WidgetProps): JSX.Element => {
	const classes = [widgetBaseClass, className].filter(Boolean).join(' ');
	return <Component className={classes} href={href} {...props} />;
};
Widget.displayName = 'Widget';

Widget.Body = WidgetBody;
Widget.Footer = WidgetFooter;
Widget.Header = WidgetHeader;
Widget.Title = WidgetTitle;

export default Widget;
