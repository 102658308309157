import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from './useAuthentication';

interface AuthorizedRouteProps {
	children: JSX.Element;
	redirectTo?: string;
}
export default function AuthorizedRoute({ children, redirectTo = '/login' }: AuthorizedRouteProps): JSX.Element {
	const { currentUser } = useAuthentication();
	return currentUser ? children : <Navigate replace to={redirectTo} />;
}
