import React from 'react';
import { ColProps } from './types';
import { prefixCol, useGrid } from './hooks/useGrid';

const Col = ({ lg, md, sm, xl, xs, xxl, className, ...props }: ColProps) => {
	const { colClassName } = useGrid({ gridSizes: { lg, md, sm, xl, xs, xxl }, prefix: prefixCol });
	const classes = [className, colClassName, prefixCol].filter(Boolean).join(' ');
	return <div className={classes} {...props} />;
};

export default Col;
