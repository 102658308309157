export default {
	$schema: 'http://json-schema.org/draft-07/schema',
	title: 'user interface contract',
	description: 'a contract for JSON configurable user interfaces',
	type: 'object',
	properties: {
		type: {
			description: 'internal element type corresponding to a user interface component',
			type: 'string'
		},
		title: {
			description: 'primary component text',
			type: 'string'
		},
		description: {
			description: 'secondary component text',
			type: 'string'
		},
		icon: {
			description: 'tag for which a particular icon will map to',
			type: 'string'
		},
		link: {
			description: 'url of the page this link goes to',
			type: 'string'
		},
		target: {
			description: 'specifies where to open a link',
			type: 'string',
			enum: ['_self', '_blank', '_parent', '_top']
		},
		source: {
			description: 'image src url, or icon tag. TODO: is this really any different than `icon` property?',
			type: 'string'
		},
		children: {
			description: 'list of user interface contract(s)',
			type: 'array',
			items: { $ref: '#' }
		},
		metadata: {
			$ref: '#/$defs/metadata'
		},
		view: {
			$ref: '#/$defs/view'
		}
	},
	additionalProperties: false,
	required: ['type'],
	$defs: {
		stringOrInteger: {
			oneOf: [
				{
					type: 'string'
				},
				{
					type: 'integer'
				}
			]
		},
		textValues: {
			type: 'string',
			enum: ['start', 'end', 'center', 'wrap', 'nowrap', 'break', 'lowercase', 'uppercase', 'capitalize']
		},
		fontValues: {
			type: 'string',
			enum: ['bold', 'bolder', 'semibold', 'medium', 'normal', 'light', 'lighter', 'italics']
		},
		alignValues: {
			type: 'string',
			enum: ['start', 'end', 'center', 'baseline', 'stretch']
		},
		justifyValues: {
			type: 'string',
			enum: ['start', 'end', 'center', 'between', 'around', 'evenly']
		},
		breakpoint: {
			type: 'object',
			properties: {
				span: {
					$ref: '#/$defs/stringOrInteger'
				},
				text: {
					$ref: '#/$defs/textValues'
				},
				align: {
					$ref: '#/$defs/alignValues'
				},
				justify: {
					$ref: '#/$defs/justifyValues'
				}
			},
			additionalProperties: false
		},
		metadata: {
			description: 'arbitrary descriptive data',
			type: 'object',
			properties: {
				notification: {
					description: 'a badge counter notification',
					type: 'integer'
				},
				variant: {
					description: 'value used to trigger an alternate view of a particular component',
					type: 'string'
				},
				analytics: {
					description: 'analytic tracking data',
					type: 'object'
				}
			}
		},
		view: {
			description: 'provides visual tweaks to a components text, layout, responsiveness',
			type: 'object',
			properties: {
				xs: {
					description: 'portrait phone screen size',
					$ref: '#/$defs/breakpoint'
				},
				sm: {
					description: 'landscape phone screen size',
					$ref: '#/$defs/breakpoint'
				},
				md: {
					description: 'tablet screen size',
					$ref: '#/$defs/breakpoint'
				},
				lg: {
					description: 'desktop screen size',
					$ref: '#/$defs/breakpoint'
				},
				xl: {
					description: 'large desktop screen size',
					$ref: '#/$defs/breakpoint'
				},
				xxl: {
					description: 'larger desktop screen size',
					$ref: '#/$defs/breakpoint'
				},
				text: {
					description: 'control text alignment, wrapping, transformations',
					$ref: '#/$defs/textValues'
				},
				font: {
					description: 'control font weight, italics',
					$ref: '#/$defs/fontValues'
				},
				align: {
					description: 'control layout alignment',
					$ref: '#/$defs/alignValues'
				},
				justify: {
					description: 'control layout justification',
					$ref: '#/$defs/justifyValues'
				},
				height: {
					description: 'control height',
					$ref: '#/$defs/stringOrInteger'
				},
				width: {
					description: 'control width',
					$ref: '#/$defs/stringOrInteger'
				}
			},
			additionalProperties: false
		}
	}
};
