import React from 'react';
import { PopoverHeaderProps } from './types';

const PopoverHeader = ({ className, as: Component = 'h3', ...props }: PopoverHeaderProps) => {
	const classes = [className, 'popover-header'].filter(Boolean).join(' ');
	return <Component className={classes} {...props} />;
};

PopoverHeader.displayName = 'PopoverHeader';

export default PopoverHeader;
