import { UserInterfaceContract } from '../types';

export const gridViewAdapter = (view?: Record<string, unknown>) => {
	if (!view) {
		return {};
	}

	const adapterProps: Record<string, unknown> = {};
	const classes: string[] = [];
	const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
	const alignment = ['text', 'align', 'justify'];
	const fontWeight = ['bold', 'bolder', 'semibold', 'medium', 'normal', 'light', 'lighter'];
	const italics = ['italic'];
	Object.keys(view).forEach(key => {
		const originalValue = view[key];
		if (breakpoints.includes(key)) {
			if (originalValue !== null && typeof originalValue === 'object') {
				const record = originalValue as Record<string, unknown>;
				Object.keys(record).forEach(innerKey => {
					const originalInnerValue = record[innerKey];
					if (innerKey === 'span') {
						adapterProps[key] = {};
						const breakpointBasedProps = adapterProps[key] as Record<string, unknown>;
						if (!Number.isNaN(Number.parseInt(String(originalInnerValue)))) {
							breakpointBasedProps[innerKey] = Number.parseInt(String(originalInnerValue));
						} else {
							breakpointBasedProps[innerKey] = originalInnerValue;
						}
					} else if (alignment.includes(innerKey)) {
						classes.push(`${innerKey}-${key}-${originalInnerValue}`);
					}
				});
			} else if (!Number.isNaN(Number.parseInt(String(originalValue)))) {
				adapterProps[key] = Number.parseInt(String(originalValue));
			} else {
				adapterProps[key] = view[key];
			}
		} else if (key === 'font' && fontWeight.includes(originalValue as string)) {
			classes.push(`fw-${originalValue}`);
		} else if (key === 'font' && italics.includes(originalValue as string)) {
			classes.push(`fst-${originalValue}`);
		} else if (alignment.includes(key)) {
			classes.push(`${key}-${originalValue}`);
		}
	});

	const filteredClasses = classes.filter(Boolean);
	if (filteredClasses.length > 0) {
		adapterProps['className'] = classes.filter(Boolean).join(' ');
	}

	return adapterProps;
};

export const gridAdapter = (contract: UserInterfaceContract) => {
	return {
		...gridViewAdapter(contract.view as Record<string, unknown>)
	};
};
