import React from 'react';
import { Dropdown } from 'react-bootstrap';
import DrillDownToggle from './DrillDownToggle';
import DrillDownMenu from './DrillDownMenu';
import DrillDownSelectedItems from './DrillDownSelectedItems';
import { defaultUseDrillDownProps } from './utility';

const DrillDown = props => {
	return <Dropdown {...props} />;
};
DrillDown.displayName = 'DrillDown';

DrillDown.defaultProps = {
	className: defaultUseDrillDownProps.className,
	show: defaultUseDrillDownProps.show
};

DrillDown.SelectedItems = DrillDownSelectedItems;
DrillDown.Toggle = DrillDownToggle;
DrillDown.Menu = DrillDownMenu;

export default DrillDown;
