import React from 'react';
import { CheckableInputProps } from '../types';

const CheckableInput = React.forwardRef<HTMLInputElement, CheckableInputProps>(({ type, button, isInvalid, className, ...props }, ref) => {
	const classes = [className, button && isInvalid && 'is-invalid', 'form-check-input'].filter(Boolean).join(' ');

	return <input type={type} ref={ref} aria-required={props.required} aria-invalid={isInvalid} className={classes} {...props} />;
});
CheckableInput.displayName = 'CheckableInput';

export default CheckableInput;
