import React from 'react';

type InformationalRowProps = Omit<React.PropsWithChildren<React.TdHTMLAttributes<HTMLTableCellElement>>, 'role'> & {
	isPresentationTable?: boolean;
};

export const testId = 'informational-row';

const InformationalRow = ({ children, colSpan, className = 'text-center', isPresentationTable = false, ...props }: InformationalRowProps): JSX.Element => (
	<tr role={isPresentationTable ? undefined : 'row'}>
		<td data-testid={testId} colSpan={colSpan} role={isPresentationTable ? undefined : 'cell'} className={className} {...props}>
			{children}
		</td>
	</tr>
);

InformationalRow.displayName = 'InformationalRow';

export default InformationalRow;
