import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from './useAuthentication';

export default function Logoff(): JSX.Element {
	const { setCurrentUser } = useAuthentication();
	React.useEffect(() => {
		setCurrentUser(undefined);
	}, [setCurrentUser]);

	return <Navigate to="/login" />;
}
