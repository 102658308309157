// @ts-expect-error TypeScript thinks `emptyRenderer` doesn't exist for some unknown reason
import { emptyRenderer, ColumnInstance } from 'react-table';

export function isColumnEmpty(column: ColumnInstance, key: 'Header' | 'Footer'): boolean {
	return (
		!Object.prototype.hasOwnProperty.call(column, key) ||
		(typeof column[key] === 'string' && String(column[key]).length === 0) ||
		column[key] === emptyRenderer
	);
}
