import { SpecialDateValues, Weekday } from './constants';
import { PossibleDate } from '../types';

export function getCurrentDate() {
	/*
	 * This exists for the sole purpose of testing
	 * To override the "current" date in tests, do something like below
	 * ex) jest.spyOn(global.Date, 'now').mockImplementation(() => new Date('2020-01-15T11:01:58.135Z').valueOf());
	 */
	return new Date(Date.now());
}

export function getPreviousBusinessDay() {
	const date = getCurrentDate();
	date.setDate(date.getDate() - 1);
	if (date.getDay() === Weekday.Sunday) {
		date.setDate(date.getDate() - 2);
	} else if (date.getDay() === Weekday.Saturday) {
		date.setDate(date.getDate() - 1);
	}
	return date;
}

export function getNextBusinessDay() {
	const date = getCurrentDate();
	date.setDate(date.getDate() + 1);
	if (date.getDay() === Weekday.Sunday) {
		date.setDate(date.getDate() + 1);
	} else if (date.getDay() === Weekday.Saturday) {
		date.setDate(date.getDate() + 2);
	}
	return date;
}

export function getFourDigitYear(year: string | number) {
	let yearString = String(year);
	if (yearString.length < 4) {
		const currentYearString = getCurrentDate().getFullYear().toString();
		let century = Number(currentYearString.substring(0, 2)) - 1;
		if (Number(yearString) < Number(currentYearString.substring(2, 4)) - -10) {
			century = Number(currentYearString.substring(0, 2));
		}
		yearString = century + yearString;
	}
	return yearString;
}

export function zeroPadDelimitedDate(value: string, delimiter = '/') {
	if (value.includes(delimiter)) {
		return value
			.split(delimiter)
			.map(part => (part.length === 1 ? `0${part}` : part))
			.join(delimiter);
	}

	return value;
}

export function validateDaysInMonth(month: number, day: number, year: number) {
	switch (month) {
		// February
		case 2:
			return (day > 0 && day < 29) || (day === 29 && year % 4 === 0);
		// January, March, May, July, August, October, December
		case 1:
		case 3:
		case 5:
		case 7:
		case 8:
		case 10:
		case 12:
			return day > 0 && day <= 31;
		// April, June, September, November
		case 4:
		case 6:
		case 9:
		case 11:
			return day > 0 && day <= 30;
		default:
			return false;
	}
}

export function asDate(value: PossibleDate): Date | undefined {
	if (value instanceof Date) {
		return value;
	}

	let stringValue = String(value);
	stringValue = zeroPadDelimitedDate(stringValue, '/');
	stringValue = zeroPadDelimitedDate(stringValue, '-');
	stringValue = stringValue.replace(/\//g, '').replace(/-/g, '').toLowerCase();

	switch (stringValue) {
		case SpecialDateValues.Today:
			return getCurrentDate();
		case SpecialDateValues.PreviousBusinessDay:
			return getPreviousBusinessDay();
		case SpecialDateValues.NextBusinessDay:
			return getNextBusinessDay();
		default:
	}

	if (/^\d+$/.test(stringValue)) {
		if ((typeof value === 'number' || !Number.isNaN(stringValue)) && (stringValue.length === 5 || stringValue.length === 7)) {
			// zero pad stringValue.  Assume one-digit month was provided
			stringValue = `0${stringValue}`;
		}

		let date: Date | undefined;
		if (stringValue.length > 8) {
			date = new Date(Number(stringValue));
		} else {
			const currentYear = getCurrentDate().getFullYear();
			let month, day, year;
			if (
				stringValue.length === 8 &&
				Number(stringValue.substring(0, 4)) > currentYear - 100 &&
				Number(stringValue.substring(0, 4)) < currentYear - -10
			) {
				// yyyyMMdd format
				month = Number(stringValue.substring(4, 6));
				day = Number(stringValue.substring(6, 8));
				year = Number(stringValue.substring(0, 4));
			} else {
				// `MMddyyyy or MMddyy format
				month = Number(stringValue.substring(0, 2));
				day = Number(stringValue.substring(2, 4));
				year = Number(getFourDigitYear(stringValue.substring(4, stringValue.length)));
			}

			if (validateDaysInMonth(month, day, year)) {
				date = new Date(year, month - 1, day, 0, 0, 0, 0);
			}
		}
		if (date && date.getTime() > 0) {
			return date;
		}
	}

	return undefined;
}

export function isDate(value: PossibleDate, strict = false): boolean {
	const conditional = !strict || (strict && !Object.values(SpecialDateValues).includes(value as string));
	return conditional && asDate(value) instanceof Date;
}

export const getLaterDate = (date1: PossibleDate, date2: PossibleDate): Date | undefined => {
	let laterDate;
	const date1AsDate = asDate(date1);
	const date2AsDate = asDate(date2);

	if (!date1AsDate && date2AsDate) {
		laterDate = date2AsDate;
	} else if (date1AsDate && !date2AsDate) {
		laterDate = date1AsDate;
	} else if (date1AsDate && date2AsDate) {
		if (date1AsDate.getTime() > date2AsDate.getTime()) {
			laterDate = date1AsDate;
		} else {
			laterDate = date2AsDate;
		}
	}

	return laterDate;
};

export const determineAge = (dob: PossibleDate, asOf: PossibleDate): number => {
	let age = -1;
	if (isDate(dob, true)) {
		const dobDate = asDate(dob) as Date;

		let asOfDate = getCurrentDate();
		if (isDate(asOf)) {
			asOfDate = asDate(asOf) as Date;
		}

		age = asOfDate.getFullYear() - dobDate.getFullYear();
		if (asOfDate.getMonth() < dobDate.getMonth() || (asOfDate.getMonth() === dobDate.getMonth() && asOfDate.getDate() < dobDate.getDate())) {
			age -= 1;
		}
	}

	return age;
};
