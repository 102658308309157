import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useCustomer from '../hooks/useCustomer';
import { AuthorizedRouteProps } from '../types';

const AuthorizedRoute: FC<AuthorizedRouteProps> = ({
	roles,
	targetCompany = undefined,
	bscAdmin = false,
	redirectPath = '/',
	replace = true,
	strict = false,
	children
}): JSX.Element => {
	const location = useLocation();
	const { customer, hasRole, hasCompanyRole } = useCustomer();
	let authorized = bscAdmin ? customer.bscAdmin : true;
	if (authorized) {
		authorized = targetCompany ? hasCompanyRole(targetCompany, roles, strict) : hasRole(roles, strict);
	}

	if (!authorized) {
		// role not authorised so redirect to specified path
		return <Navigate to={redirectPath} state={{ from: location }} replace={replace} />;
	}

	return children as JSX.Element;
};

export default AuthorizedRoute;
