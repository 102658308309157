import React from 'react';
import useNavigation from '../hooks/useNavigation';
import { NavigationDropdownMenuProps } from '../types';

export const NavigationDropdownMenu = React.forwardRef<HTMLUListElement, NavigationDropdownMenuProps>(
	({ show, className, style, 'aria-labelledby': ariaLabelledBy, children, ...props }, ref) => {
		const { show: navbarShow } = useNavigation();
		if (navbarShow && show && style) {
			// override popper config so menu displays inline on smaller screens
			style.position && delete style.position;
			style.inset && delete style.inset;
			// top/right/bottom/left are the equivalent of using inset directly
			style.top && delete style.top;
			style.right && delete style.right;
			style.bottom && delete style.bottom;
			style.left && delete style.left;
			style.transform && delete style.transform;
		}

		return (
			<ul ref={ref} className={className} aria-labelledby={ariaLabelledBy} style={style}>
				{children}
			</ul>
		);
	}
);
NavigationDropdownMenu.displayName = 'NavigationDropdownMenu';
