export const buttonVariants = [
	'primary',
	'neutral',
	'danger',
	'success',
	'link',
	'default',
	'outline-primary',
	'outline-secondary',
	'outline-danger',
	'outline-success',
	'outline-link',
	'outline-default'
] as const;
