import React, { useEffect } from 'react';
import { ActionButtonDropdown, Button } from '@optic-delight/ui-framework';
import { Datatable, Expander } from '@optic-delight/ui-datatable';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import axios from 'axios';
import { User } from './types';

function Actions({ row }: { row: Row<User> }) {
	const navigate = useNavigate();

	const editUser = (): void => {
		navigate(`/modify/${row.original.num}`, {
			state: row.original
		});
	};
	return (
		<ActionButtonDropdown id={`actions-${row.original.num}`} title={`${row.original.name} Actions`}>
			<ActionButtonDropdown.Item onClick={editUser} title={`Edit ${row.original.name}`}>
				Edit
			</ActionButtonDropdown.Item>
			<ActionButtonDropdown.Divider />
			<ActionButtonDropdown.Item className="text-danger" title={`Delete ${row.original.name}`}>
				Delete
			</ActionButtonDropdown.Item>
		</ActionButtonDropdown>
	);
}

function FormattedAddress({ row }: { row: Row<User> }) {
	return (
		<>
			{row.original.address1}
			{row.original.address2 ? (
				<>
					<br />
					{row.original.address2}
				</>
			) : null}
			<br />
			{`${row.original.city}, ${row.original.state} ${row.original.zip || ''}`}
		</>
	);
}

export default function Users(): JSX.Element {
	const navigate = useNavigate();
	const [users, setUsers] = React.useState<User[]>([]);

	useEffect(() => {
		axios.get('/users').then(response => setUsers(response.data));
	}, []);

	const renderRowSubComponent = React.useCallback(
		({ row }: { row: Row<User> }) => (
			<div className="ms-5">
				<div>
					<strong>Student Status: </strong>
					{row.original.status}
				</div>
				<div>
					<strong>A Date: </strong>
					{row.original.date}
				</div>
			</div>
		),
		[]
	);

	return (
		<Datatable
			caption="Current Users"
			columns={React.useMemo(
				() => [
					{
						Header: () => null, // do not display header
						id: 'expander', // id is required
						Cell: Expander,
						className: 'col-check'
					},
					{
						Header: 'Name',
						accessor: 'name'
					},
					{
						Header: 'Email',
						accessor: 'email'
					},
					{
						Header: 'Address',
						Cell: FormattedAddress
					},
					{
						Header: 'Actions',
						Cell: Actions,
						className: 'table-col-actions'
					}
				],
				[]
			)}
			data={users}
			responsive={false}
			renderRowSubComponent={renderRowSubComponent}
			actionButtons={
				<Button variant="primary" onClick={() => navigate('/modify')}>
					Add User
				</Button>
			}
		/>
	);
}
