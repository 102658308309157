import React from 'react';
import { AuthenticationContext, AuthenticationContextInterface } from './AuthenticationProvider';

export const useAuthentication = (): AuthenticationContextInterface => {
	const context = React.useContext(AuthenticationContext);
	if (!context) {
		throw new Error('useAuthentication must be used within the AuthenticationContext');
	}

	return context;
};
