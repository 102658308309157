import { createAxiosInstance, CLIENT_TIMEOUT, CSRF_HEADER_NAME } from '../http-client';

export const LIVE_KINNECT_INSTANCE_DEFAULTS = {
	baseURL: `${window.location.origin}/benefits/rest`,
	timeout: CLIENT_TIMEOUT,
	xsrfHeaderName: CSRF_HEADER_NAME,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
};

const liveKinnectClient = createAxiosInstance(LIVE_KINNECT_INSTANCE_DEFAULTS);

export default liveKinnectClient;
