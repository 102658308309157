import { useContext } from 'react';
import { AccordionContext, AccordionItemContext } from '../AccordionContext';
import HookContextError from '../../../helpers/HookContextError';

export const useAccordionItemContext = () => {
	const context = useContext(AccordionItemContext);
	if (context === undefined) {
		throw new HookContextError(useAccordionItemContext.name, AccordionItemContext.displayName);
	}

	return context;
};

export const useAccordionContext = () => {
	const context = useContext(AccordionContext);
	if (context === undefined) {
		throw new HookContextError(useAccordionContext.name, AccordionContext.displayName);
	}

	return context;
};
