import React from 'react';
import { Icon, IconClose } from '@optic-delight/icons';
import { ProgressDotsProps, ProgressDotsVariant } from './types';

export const progressDotVariants = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];
export const progressDotFill = ['auto', 'none', 'full', 'half-past'];

export const getProgressDotVariantClasses = (variant: ProgressDotsVariant) => {
	return ['progress-dots', progressDotVariants.includes(variant) && `progress-dots-${variant}`].filter(Boolean).join(' ');
};

export const getProgressDotsFill = (fill: string, nowVariant?: ProgressDotsVariant) => {
	if (fill === 'auto' && (nowVariant === 'danger' || nowVariant === 'warning')) {
		return 'full';
	} else if (fill === 'auto') {
		return 'half-past';
	}
	return fill;
};

export const iconVariant: { [key: string]: React.ElementType } = {
	success: props => <Icon icon="check" {...props} />,
	warning: props => <Icon icon="exclamation" {...props} />,
	danger: IconClose
};

const ProgressDots = ({
	nowVariant,
	nowVariantIcon = nowVariant === 'success' || nowVariant === 'danger' || nowVariant === 'warning' ? true : false,
	now,
	steps,
	variant = 'primary',
	'aria-label': ariaLabel,
	fill = 'auto',
	className,
	...props
}: ProgressDotsProps): JSX.Element => {
	const stepsArray = Array.isArray(steps) ? steps : new Array(steps).fill(undefined);
	const progressDotFill = getProgressDotsFill(fill, nowVariant);
	const progressDotClasses = [
		className,
		getProgressDotVariantClasses(variant),
		progressDotFill && progressDotFill !== 'full' && `progress-fill-${progressDotFill}`
	]
		.filter(Boolean)
		.join(' ');
	const dots = stepsArray.map((step, index) => {
		const isComplete = index + 1 < now;
		const isCurrent = index + 1 === now;
		const isNext = index + 1 - now === 1;
		const NowIcon = nowVariantIcon && nowVariant && iconVariant[nowVariant];
		const stepClasses = ['progress-dot-step', isNext && 'next', isCurrent && 'current', isComplete && 'complete'].filter(Boolean).join(' ');
		const progressDotNowClasses = [
			'progress-dot-icon',
			isCurrent && nowVariant && progressDotVariants.includes(nowVariant) && `progress-dot-now-${nowVariant}`
		]
			.filter(Boolean)
			.join(' ');
		return (
			<div key={index} className={stepClasses}>
				<div className={progressDotNowClasses} data-testid={isCurrent ? 'current-step-test' : undefined}>
					{isCurrent && NowIcon ? (
						<NowIcon color="#fff" theme="solid" data-testid="current-step-custom-icon-test" />
					) : (
						isComplete && <Icon icon="check" theme="solid" />
					)}
				</div>
				{step && <div className="progress-dot-label">{step}</div>}
			</div>
		);
	});

	return (
		<div
			{...props}
			className={progressDotClasses}
			role="progressbar"
			aria-valuenow={now}
			aria-valuemin={0}
			aria-valuemax={dots.length}
			aria-label={`${ariaLabel} from 0 to ${dots.length}`}>
			{dots}
		</div>
	);
};
ProgressDots.displayName = 'ProgressDots';

export default ProgressDots;
