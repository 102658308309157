import { createIcon } from '../utility/createIcon';

export const IconChart = createIcon('chart');

export const IconChartArea = createIcon('chart-area');

export const IconChartBar = createIcon('chart-bar');

export const IconChartLine = createIcon('chart-line');

export const IconChartPie = createIcon('chart-pie');
