import React from 'react';
import Alert from './Alert';
import { AlertVariantProps } from './types';

/**
 *`<ErrorAlert>` is an `<Alert>` with defaulted variant, header, description properties.
 */
const ErrorAlert = React.forwardRef<HTMLDivElement, AlertVariantProps>(({ heading = 'Error', ...props }, ref) => {
	return <Alert heading={heading} ref={ref} variant="danger" iconVariant {...props} />;
});
ErrorAlert.displayName = 'ErrorAlert';

export default ErrorAlert;
