import React from 'react';

export function useFlippedDropdownMenu(isDropdownShown: boolean) {
	const dropdownMenuRef = React.useRef<HTMLDivElement>(null);
	const [isDropdownMenuFlipped, setIsDropdownMenuFlipped] = React.useState(false);

	React.useEffect(() => {
		if (dropdownMenuRef.current && isDropdownShown) {
			const dropdownMenuRect = dropdownMenuRef.current.getBoundingClientRect();
			const dropdownMenuWidth = dropdownMenuRect.width;
			const dropdownMenuLeftSpace = dropdownMenuRect.left;
			const bodyRect = document.body.getBoundingClientRect();
			const bodyWidth = bodyRect.width;
			setIsDropdownMenuFlipped(dropdownMenuLeftSpace + dropdownMenuWidth >= bodyWidth);
		}
	}, [dropdownMenuRef, isDropdownShown]);

	return { dropdownMenuRef, isDropdownMenuFlipped };
}
