import { ResolverProcessor, UserInterfaceContractProcessorData, UserInterfaceTypeResolverOptions } from '../types';

export const childrenOrTitleAdapter = ({ contract, level }: UserInterfaceTypeResolverOptions, { process }: ResolverProcessor) => {
	let children: UserInterfaceContractProcessorData = contract.title;
	if (!children) {
		children = process({ contract: contract.children, level: level + 1, parent: contract });
	}

	return children;
};
