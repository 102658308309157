import React from 'react';
import { SpinnerTitleProps } from './types';

/**
 * primary loading text for `Spinner`
 */
export function SpinnerTitle({ children = 'Loading...', ...props }: SpinnerTitleProps) {
	return (
		<div className="d-flex justify-content-center spinner-text" {...props}>
			{children}
		</div>
	);
}
