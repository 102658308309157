import React, { useState, useEffect, useRef } from 'react';
import CheckableInput from './CheckableInput';
import ButtonCheckable from './ButtonCheckable';
import useCheckable from './hooks/useCheckable';
import { useCombinedRefs, buildRegisterProps, fieldHasErrors, isInputChecked, callAll, getFieldErrors, Validator } from '../../../helpers';
import { Helpblock, Label } from '../../utility';
import { CheckableProps } from '../types';
import { FormControlFeedback } from '../form-control';
import { Tooltip } from '../../tooltip';
import { useFormContext } from '../form-element';
import FormGroupValidation from '../shared/FormGroupValidation';

/**
 * This is the underlying implementation for `<Checkbox>` and `<Radio>`.
 */
export default function Checkable(props: CheckableProps): JSX.Element {
	const { getProps, isGroup, checkGroup } = useCheckable(props);
	const { formState, register } = useFormContext() || {};
	const { className, inputonly, id, dirty, validators, inline, label, onChange, onBlur, tooltip, indeterminate, disabled, ...checkableProps } = getProps();
	const isDisabled = formState?.isDisabled || disabled || props.disabled; // <form disabled> | <CheckGroup disabled> | <input disabled>
	const formCheckClasses = [className, inline && 'form-check-inline', 'form-check'].filter(Boolean).join(' ');

	const innerRef = useRef();
	const registerProps = buildRegisterProps(register, {
		name: checkableProps.name,
		required: checkableProps.required,
		disabled: isDisabled,
		validators
	});
	const combinedRef = useCombinedRefs(registerProps.ref, innerRef);

	const [isInvalid, setIsInvalid] = useState(!!(dirty && checkableProps.required));
	const formCheckLabelClasses = ['form-check-label', isInvalid && 'is-invalid'].filter(Boolean).join(' ');
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let invalid = checkableProps.required && !isInputChecked(event.target);
		if (!invalid && validators.length > 0) {
			invalid = validators.some((validator: { validate: (value: string) => boolean }) => !validator.validate(event.target.value));
		}
		setIsInvalid(invalid);
		callAll(registerProps.onChange, onChange, checkGroup.onChange)(event);
	};

	const getErrorMessage = () => {
		if (checkableProps.required && label) {
			return `${label} ${FormGroupValidation.defaultProps.requiredFieldMessage}`;
		}

		const errorsForInput = getFieldErrors(checkableProps.name, formState?.errors);
		if (!errorsForInput) {
			return null;
		}

		return validators?.map((validator: Validator) => {
			// return error message for the appropriate validator under error
			if (errorsForInput.type === validator.name || Object.prototype.hasOwnProperty.call(errorsForInput.types, validator.name || '')) {
				return validator.message;
			}

			return null;
		});
	};

	const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		callAll(registerProps.onBlur, onBlur)(event);
	};

	const firstUpdate = useRef(!!dirty);
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		setIsInvalid(fieldHasErrors(checkableProps.name, formState?.errors));
	}, [formState, checkableProps.name]);

	const helptextId = checkableProps.helptext ? id + '_helptext' : undefined;

	React.useEffect(() => {
		if (!combinedRef.current) return;
		(combinedRef.current as HTMLInputElement).indeterminate = indeterminate;
	}, [combinedRef, indeterminate]);

	return checkableProps.button ? (
		<ButtonCheckable
			ref={combinedRef}
			label={label}
			id={id}
			onChange={handleChange}
			onBlur={handleBlur}
			tooltip={tooltip}
			isInvalid={isInvalid}
			aria-describedby={helptextId}
			disabled={isDisabled}
			{...checkableProps}
		/>
	) : inputonly ? (
		<CheckableInput
			id={id}
			ref={combinedRef}
			onChange={handleChange}
			onBlur={handleBlur}
			isInvalid={isInvalid}
			aria-describedby={helptextId}
			className={className}
			disabled={isDisabled}
			{...checkableProps}
		/>
	) : (
		<div className={formCheckClasses}>
			<CheckableInput
				id={id}
				ref={combinedRef}
				onChange={handleChange}
				onBlur={handleBlur}
				isInvalid={isInvalid}
				aria-describedby={helptextId}
				disabled={isDisabled}
				{...checkableProps}
			/>
			<Label required={!isGroup() && checkableProps.required} htmlFor={id} className={formCheckLabelClasses}>
				{label}
			</Label>
			<Tooltip id={id + '_tooltip'}>{tooltip}</Tooltip>
			<Helpblock id={helptextId}>{checkableProps.helptext}</Helpblock>
			{!isGroup() && (checkableProps.required || fieldHasErrors(checkableProps.name, formState?.errors)) ? (
				<FormControlFeedback type="invalid">{getErrorMessage()}</FormControlFeedback>
			) : null}
		</div>
	);
}

Checkable.defaultProps = {
	required: false,
	validators: [],
	dirty: undefined,
	button: false
};
