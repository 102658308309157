import { BASE_URL, HTTP_INSTANCE_DEFAULTS } from './utility/constants';
import { requestFactory } from './utility/requestFactory';
import { createAxiosInstance } from './utility/createAxiosInstance';
import { HttpClientInstance } from './types';

const client = createAxiosInstance(HTTP_INSTANCE_DEFAULTS);

const HttpClient: HttpClientInstance = {
	instance: client,
	post: (pageName, data, config) => {
		const { payload, requestConfig } = requestFactory(pageName, data, config);

		return client.post(BASE_URL, payload, requestConfig);
	}
};

export default HttpClient;
