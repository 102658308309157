import React, { ForwardedRef } from 'react';
import { CustomizedButtonProps } from './types';
import CustomizedButtonWrapper from './CustomizedButtonWrapper';

export const NextButtonDefaultText = 'Next';

const NextButton = React.forwardRef(({ children = NextButtonDefaultText, ...props }: CustomizedButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
	return (
		<CustomizedButtonWrapper variant="primary" icon="next" iconDirection="append" ref={ref} {...props}>
			{children}
		</CustomizedButtonWrapper>
	);
});
NextButton.displayName = 'NextButton';

export default NextButton;
