import React from 'react';
import { Outlet } from 'react-router-dom';

export default function Body(): JSX.Element {
	return (
		<main className="container py-3 flex-grow-1">
			<Outlet />
		</main>
	);
}
