import { createAxiosInstance, CSRF_HEADER_NAME } from '../../http-client';
import { LIVE_KINNECT_INSTANCE_DEFAULTS } from '../LiveKinnectClient';
import { applyAwsSigV4Interceptor } from './awsSigV4Interceptor';

export const getAccessTokens = (accessKey: string, secretKey: string, refreshToken: string | undefined) => {
	const liveKinnectClient = createAxiosInstance(LIVE_KINNECT_INSTANCE_DEFAULTS);
	applyAwsSigV4Interceptor(liveKinnectClient, accessKey, secretKey);

	let url = '/session/token';
	if (refreshToken) {
		url += '?grant_type=refresh&refresh_token=' + refreshToken;
	}

	const headers: Record<string, string> = {};
	headers[CSRF_HEADER_NAME] = secretKey;

	return liveKinnectClient({
		method: 'post',
		url: url,
		headers: headers,
		data: null
	});
};
