import { IconName } from '@fortawesome/fontawesome-common-types';
import { Theme } from './iconMapping';
import { dataAttributes, defaultTheme, iconPrefix } from './constants';
import { IconProps } from '../Icon';

export interface MaskInterface {
	icon: IconName;
	theme?: Theme;
}
export type Mask = IconName | MaskInterface;

export function useMask({ mask, theme = defaultTheme }: Omit<IconProps, 'icon'>): Record<string, unknown> {
	if (mask) {
		let maskTheme, maskIcon;
		if (typeof mask === 'string') {
			maskTheme = theme;
			maskIcon = mask;
		} else {
			const maskInterface: MaskInterface = mask;
			maskTheme = maskInterface.theme || theme;
			maskIcon = maskInterface.icon;
		}

		return {
			[dataAttributes.mask]: `${iconPrefix}-${maskTheme} ${iconPrefix}-${maskIcon}`,
			[dataAttributes.maskId]: maskIcon
		};
	}

	return {};
}
