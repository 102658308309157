import React from 'react';
import { HeaderProps } from './types';

const Header = React.forwardRef<HTMLHeadingElement, HeaderProps>(({ className, type, as: Component = 'h1', ...props }, ref) => {
	if (type === 'page') {
		const classes = [className, 'page-header'].filter(Boolean).join(' ');
		return (
			<div className="page-title">
				<Component ref={ref} className={classes} {...props} />
			</div>
		);
	}
	return <Component ref={ref} className={className} {...props} />;
});
Header.displayName = 'Header';

export default Header;
