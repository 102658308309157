import React from 'react';
import Alert from './Alert';
import { AlertVariantProps } from './types';

/**
 *`<WarningAlert>` is an `<Alert>` with defaulted variant, header, description properties.
 */
const WarningAlert = React.forwardRef<HTMLDivElement, AlertVariantProps>(({ heading = 'Warning', ...props }, ref) => {
	return <Alert heading={heading} ref={ref} variant="warning" iconVariant {...props} />;
});
WarningAlert.displayName = 'WarningAlert';

export default WarningAlert;
