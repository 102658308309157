import React, { ForwardedRef } from 'react';
import { Button } from '../button';
import { ReadViewProps } from './types';

export const ReadViewDefaultChildren = '[ select value ]';

const ReadView = React.forwardRef((readViewProps: ReadViewProps, ref: ForwardedRef<HTMLButtonElement>) => {
	const { className = 'px-0 text-decoration-none', children = ReadViewDefaultChildren, isInvalid, ...props } = readViewProps;

	const buttonClasses = [className, !className?.includes('px-0') && 'px-0', isInvalid && 'text-danger is-invalid'].filter(Boolean).join(' ');

	return (
		<Button ref={ref} variant="link" className={buttonClasses} {...props}>
			<span style={{ borderBottom: isInvalid ? 'dashed 1px #c72b16' : 'dashed 1px #1568aa' }}>{children}</span>
		</Button>
	);
});
ReadView.displayName = 'ReadView';

export default ReadView;
