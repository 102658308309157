import React from 'react';
import { ListGroupItemTitleProps } from './types';

const ListGroupItemTitle = ({ className = 'h4', children, ...props }: ListGroupItemTitleProps) => {
	const classes = [className, 'list-group-item-title'].filter(Boolean).join(' ');
	return (
		<h2 className={classes} {...props}>
			{children}
		</h2>
	);
};

ListGroupItemTitle.displayName = 'ListGroupItemTitle';

export default ListGroupItemTitle;
