import { useRef, useReducer } from 'react';
import { callAll } from '../../../helpers';
import { toggleReducer } from '../toggleReducer';
import { toggleActionTypes } from '../toggleActions';

export function useToggle({ initialOn = false, reducer = toggleReducer } = {}) {
	const { current: initialState } = useRef({ on: initialOn });
	const [state, dispatch] = useReducer(reducer, initialState, undefined);
	const { on } = state;

	const toggle = () => dispatch({ type: toggleActionTypes.toggle });
	const reset = () => dispatch({ type: toggleActionTypes.reset, initialState });

	function getToggleProps({ onClick, ...props } = {}) {
		return {
			'aria-pressed': on,
			onClick: callAll(onClick, toggle),
			...props
		};
	}

	function getResetterProps({ onClick, ...props } = {}) {
		return {
			onClick: callAll(onClick, reset),
			...props
		};
	}

	return {
		on,
		reset,
		toggle,
		getToggleProps,
		getResetterProps
	};
}
