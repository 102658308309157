import React from 'react';

import CardHeader from './CardHeader';
import CardBody from './CardBody';
import CardImage from './CardImage';
import CardTitle from './CardTitle';
import CardFooter from './CardFooter';
import { CardProps } from './types';

export const cardVariants = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light'];
export const cardTextVariants = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'white', 'muted'];

const Card = ({ href, as: CardWrapper = href ? 'a' : 'div', className, body, border, text, variant, id, children, ...props }: CardProps): JSX.Element => {
	const classes = [className, border && `border-${border}`, text && `text-${text}`, variant && `bg-${variant}`, 'card'].filter(Boolean).join(' ');

	return (
		<CardWrapper href={href} id={id} className={classes} {...props}>
			{body ? <CardBody>{children}</CardBody> : children}
		</CardWrapper>
	);
};

Card.Title = CardTitle;
Card.Header = CardHeader;
Card.Body = CardBody;
Card.Image = CardImage;
Card.Title = CardTitle;
Card.Footer = CardFooter;

Card.displayName = 'Card';

export default Card;
