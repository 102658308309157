import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { Button, buttonVariants } from '../button';

export const DrillDownInitialToggleLabel = 'Select...';

const DrillDownToggle = ({ loading, label = DrillDownInitialToggleLabel, ...props }) => {
	return loading ? (
		<Button iconDirection="append" disabled loading={loading}>
			{label}
		</Button>
	) : (
		<Dropdown.Toggle as={Button} aria-haspopup={true} {...props}>
			{label}
		</Dropdown.Toggle>
	);
};
DrillDownToggle.displayName = 'DrillDownToggle';

DrillDownToggle.propTypes = {
	/**
	 * Toggle label text.  Set from `useDrillDown` `toggleLabel` | `initialToggleLabel`
	 */
	label: PropTypes.string,

	/**
	 * button variants
	 */
	variant: PropTypes.oneOf(buttonVariants),

	/**
	 * display as block button
	 */
	block: PropTypes.bool,

	/**
	 * switches 'DrillDown' component to loading state
	 */
	loading: PropTypes.bool
};

export default DrillDownToggle;
