import React, { ForwardedRef, MutableRefObject } from 'react';
import Button from '../../button/Button';
import useDragAndDrop from './hooks/useDragAndDrop';
import { sharedDefaultProps } from './utility/sharedPropTypes';
import { DragAndDropProps } from './types';
import { IconFile, IconNavigation, StackIcons } from '@optic-delight/icons';
import { FIFTEEN_MEGABYTE } from './utility/constants';
import { prettifySizeValue, useCombinedRefs } from '../../../helpers';
import { useControlledFocusOnError } from '../shared/hooks/useControlledFocusOnError';

export const DragAndDropDefaultDisplayText = 'Drop your file(s) here';
export const DragAndDropButtonDefaultText = 'Browse Files';

const DragAndDrop = React.forwardRef((inProps: DragAndDropProps, ref: ForwardedRef<HTMLInputElement>) => {
	const {
		displayText = DragAndDropDefaultDisplayText,
		orText = 'or',
		browseText = DragAndDropButtonDefaultText,
		accept = sharedDefaultProps.accept,
		'aria-label': ariaLabel,
		isInvalid,
		fileUpload,
		multiple = sharedDefaultProps.multiple,
		disabled,
		hideIcon,
		maxSize = FIFTEEN_MEGABYTE,
		fileTypesText = false,
		fileSizeText = true,
		name,
		onChange,
		onDrop,
		onDragLeave,
		onDragEnter,
		onDragOver,
		...props
	} = inProps;

	const { handleDrop, handleDragOver, handleDragEnter, handleDragLeave, handleChange, getStyle } = useDragAndDrop({
		multiple,
		disabled,
		fileUpload,
		onChange,
		onDrop,
		onDragLeave,
		onDragEnter,
		onDragOver
	});

	const fileInput = React.useRef<HTMLInputElement>();
	const combinedRef = useCombinedRefs(ref, fileInput) as unknown as MutableRefObject<HTMLInputElement>;
	const { ref: controlRef } = useControlledFocusOnError<HTMLButtonElement>({ name });

	const dragAndDropClasses = ['rounded', isInvalid && 'border-danger'].filter(Boolean).join(' ');

	const formats = Array.isArray(accept) ? accept.join(', ') : accept;
	let displayedFileTypesText = `Valid types - ${formats}`;
	if (typeof fileTypesText === 'function' && accept) {
		displayedFileTypesText = (fileTypesText as (val: string) => string)(formats);
	}

	const sizeStringified = prettifySizeValue(maxSize);
	let displayedFileSizeText = `Max file size: ${sizeStringified}`;
	if (typeof fileTypesText === 'function') {
		displayedFileSizeText = (fileSizeText as (val: string) => string)(sizeStringified);
	}

	const buttonWrapperClasses = ['d-flex align-items-center justify-content-center', (fileTypesText || fileSizeText) && 'mb-3'].filter(Boolean).join(' ');

	return (
		<div
			onDrop={handleDrop}
			onDragOver={handleDragOver}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
			style={getStyle()}
			className={dragAndDropClasses}
			data-testid={'dragArea'}>
			{!hideIcon ? (
				<StackIcons size="2x" className="mb-3">
					<IconFile className="text-primary" stack="2x" theme="duotone" />
					<IconNavigation className="mt-2 text-primary" stack="1x" />
				</StackIcons>
			) : null}

			<div className="lead">{displayText}</div>

			<div className={buttonWrapperClasses}>
				{displayText ? <div className="me-2">{orText}</div> : null}
				<Button ref={controlRef} onClick={() => combinedRef?.current?.click()} disabled={disabled}>
					{browseText}
				</Button>
			</div>

			<input
				ref={combinedRef}
				name={name}
				type="file"
				onChange={handleChange}
				style={{ display: 'none' }}
				accept={accept ? accept.toString() : undefined}
				aria-label={ariaLabel}
				aria-required={fileUpload.data.required ?? false}
				aria-invalid={isInvalid}
				multiple={multiple}
				disabled={disabled}
				{...props}
			/>

			{fileTypesText ? <div>{displayedFileTypesText}</div> : null}

			{fileSizeText ? <div>{displayedFileSizeText}</div> : null}
		</div>
	);
});
DragAndDrop.displayName = 'DragAndDrop';

export default DragAndDrop;
