import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'react-bootstrap';

const DrillDownBreadCrumbs = ({ items = [], onBreadCrumbClick }) => {
	return items.length > 1 ? (
		<Breadcrumb>
			<Breadcrumb.Item onClick={onBreadCrumbClick} linkProps={{ tabIndex: -1, className: 'nav' }}>
				Root
			</Breadcrumb.Item>
			{items.map((item, index) => {
				if (index === 0) {
					// zero index is the root level items, do NOT display below
					return null;
				}

				const isActive = items.length === index + 1;

				return (
					<Breadcrumb.Item
						key={`crumb_${index}`}
						active={isActive}
						title={!isActive ? item.content || item : undefined}
						onClick={e => onBreadCrumbClick(e, item)}
						linkProps={{ tabIndex: -1, className: 'nav' }}>
						{isActive ? item.content || item : '..'}
					</Breadcrumb.Item>
				);
			})}
		</Breadcrumb>
	) : null;
};
DrillDownBreadCrumbs.displayName = 'DrillDownBreadCrumbs';

DrillDownBreadCrumbs.propTypes = {
	/**
	 * Breadcrumb items
	 */
	items: PropTypes.array,

	/**
	 * passed thru via `useDrillDown` to dispatch state changes
	 */
	onBreadCrumbClick: PropTypes.func.isRequired
};

export default DrillDownBreadCrumbs;
