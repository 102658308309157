import { FileUploadDataReducerActionInternal } from './types';

export const fileUploadStateChangeTypes = {
	AddFiles: 'ADD_FILES',
	RemoveFiles: 'REMOVE_FILES',
	ReplaceFiles: 'REPLACE_FILES',
	AddErrors: 'ADD_ERRORS',
	RemoveErrors: 'REMOVE_ERRORS'
};

export function addFiles(files: File[] = []): FileUploadDataReducerActionInternal {
	return {
		type: fileUploadStateChangeTypes.AddFiles,
		files: files
	};
}

export function removeFiles(files: string[] = []): FileUploadDataReducerActionInternal<string> {
	return {
		type: fileUploadStateChangeTypes.RemoveFiles,
		files: files
	};
}

export function replaceFiles(files: File[] = []): FileUploadDataReducerActionInternal {
	return {
		type: fileUploadStateChangeTypes.ReplaceFiles,
		files: files
	};
}

export function addErrors(errors: string[] = []): FileUploadDataReducerActionInternal {
	return {
		type: fileUploadStateChangeTypes.AddErrors,
		errors: errors
	};
}

export function removeErrors(): FileUploadDataReducerActionInternal {
	return {
		type: fileUploadStateChangeTypes.RemoveErrors
	};
}
