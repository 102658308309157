import { MutableRefObject, useRef } from 'react';

export function useLatestRef<T = unknown>(val: T): MutableRefObject<T> {
	const ref = useRef(val);
	// technically this is not "concurrent mode safe" because we're manipulating
	// the value during render (so it's not idempotent). However, the places this
	// hook is used is to support memoizing callbacks which will be called
	// *during* render, so we need the latest values *during* render.
	// If not for this, then we'd probably want to use useLayoutEffect instead.
	ref.current = val;
	return ref;
}
