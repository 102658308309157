import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { MessageType, postMessage } from '../../utility';

export function createAxiosInstance(config: AxiosRequestConfig): AxiosInstance {
	const axiosInstance = axios.create(config);
	axiosInstance.interceptors.request.use(
		requestConfig => {
			postMessage(MessageType.KEEP_ALIVE);
			return requestConfig;
		},
		error => Promise.reject(error)
	);

	return axiosInstance;
}
