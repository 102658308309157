import React from 'react';
import { IconQuestion } from '@optic-delight/icons';
import { Button } from '../button';

export const TooltipIndicator = React.forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement>>((props, ref) => {
	return (
		<Button ref={ref} variant="link" className="ms-2 p-0" aria-label="?" {...props}>
			<IconQuestion />
		</Button>
	);
});
TooltipIndicator.displayName = 'TooltipIndicator';
