import Ajv, { ErrorObject } from 'ajv';
import { UserInterfaceContract } from '../types';
import schema from './user-interface-contract-schema';

const ajv = new Ajv();
const validate = ajv.compile<UserInterfaceContract>(schema);

export interface ValidateUserInterfaceContractData {
	valid: boolean;
	errors: null | ErrorObject[];
}

export function validateUserInterfaceContract(data: object): ValidateUserInterfaceContractData {
	return {
		valid: validate(data),
		errors: validate.errors ? [...validate.errors] : null
	};
}
