import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from '../../progress';
import { PROGRESS_ERROR_STATE } from './utility/constants';
import { Icon } from '@optic-delight/icons';
import { UploadStatusProps } from './types';

const UploadStatus = ({ progress, errorMessage }: UploadStatusProps) => {
	return progress === PROGRESS_ERROR_STATE ? (
		<div>
			<span className="text-danger">
				<Icon icon="ban" className="text-danger me-1" />
				{errorMessage}
			</span>
		</div>
	) : (
		<ProgressBar animated={progress !== 100} now={progress} label={`${progress}%`} />
	);
};
UploadStatus.displayName = 'UploadStatus';

UploadStatus.propTypes = {
	progress: PropTypes.number,
	errorMessage: PropTypes.string
};

export default UploadStatus;
