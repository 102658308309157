import React, { forwardRef } from 'react';
import { FormGroupProps } from './types';
import FormGroupProvider from './FormGroupProvider';

const FormGroup = forwardRef<HTMLElement, FormGroupProps>(({ as: Wrapper = 'div', controlId, className, ...props }, ref) => {
	return (
		<FormGroupProvider controlId={controlId}>
			<Wrapper className={className} ref={ref} {...props} />
		</FormGroupProvider>
	);
});

FormGroup.displayName = 'FormGroup';
export default FormGroup;
