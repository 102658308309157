import React from 'react';

export interface AuthenticationContextInterface {
	currentUser?: string;
	setCurrentUser: (user?: string) => void;
}

interface AuthenticationProviderProps {
	currentUser?: string;
	children: React.ReactNode;
}

export const AuthenticationContext = React.createContext({} as AuthenticationContextInterface);
AuthenticationContext.displayName = 'AuthorizationContext';

export const AuthenticationProvider = ({ currentUser: aUser, children }: AuthenticationProviderProps): JSX.Element => {
	const [currentUser, setCurrentUser] = React.useState<string | undefined>(aUser);

	return (
		<AuthenticationContext.Provider
			value={{
				currentUser,
				setCurrentUser
			}}>
			{children}
		</AuthenticationContext.Provider>
	);
};
