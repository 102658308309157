import React from 'react';

import Card from './Card';
import CalloutCardHeader from './CalloutCardHeader';
import CalloutCardGroup from './CalloutCardGroup';
import { CalloutCardProps } from './types';

const CalloutCard = ({ as, className, icon, href, children, ...props }: CalloutCardProps): JSX.Element => (
	<Card body as={as} href={href} className="card-callout h-100 shadow-sm" {...props}>
		<div className="d-flex align-items-top">
			{!!icon && icon}
			<div>{children}</div>
		</div>
	</Card>
);

CalloutCard.displayName = 'CalloutCard';
CalloutCard.Header = CalloutCardHeader;
CalloutCard.Group = CalloutCardGroup;

export default CalloutCard;
