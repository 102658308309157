import React from 'react';
import useNavigation from './hooks/useNavigation';
import { NavigationItemProps } from './types';
import { useActiveItem } from './hooks/useActiveItem';

export function NavigationItem({ as: Component = 'a', eventKey, children, onClick, href, ...props }: NavigationItemProps): JSX.Element {
	const { onSelect, activeKey, setActiveContent } = useNavigation();
	const { isActive, getLinkProps, eventKey: itemKey } = useActiveItem({ eventKey, href, activeKey, setActiveContent });

	const navLinkClasses = ['nav-link', isActive() && 'active'].filter(Boolean).join(' ');

	const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		onSelect(itemKey, event);
		onClick?.(event);
	};

	const handleMouseDown = (event: React.MouseEvent<HTMLAnchorElement>) => {
		onSelect(itemKey, event);
	};

	const linkProps = getLinkProps({
		...(href ? {} : { role: 'button', tabIndex: 0 }),
		...props
	});

	return (
		<li className="nav-item">
			<Component className={navLinkClasses} onMouseDown={handleMouseDown} onClick={handleClick} {...linkProps}>
				{children}
			</Component>
		</li>
	);
}
