import React, { useRef } from 'react';
import Input from '../Input';
import Button from '../../button/Button';
import { IconClose, IconSearch } from '@optic-delight/icons';
import { SearchInputProps } from './types';
import { callAll, useCombinedRefs } from '../../../helpers';

/**
 * see [react-bootstrap - Form Controls](https://react-bootstrap.github.io/components/forms/#forms-controls) for complete documentation on the underlying implementation
 */
const Search = React.forwardRef<HTMLInputElement, SearchInputProps>(({ 'aria-label': ariaLabel, onClear, placeholder = 'Search...', ...props }, ref) => {
	const searchRef = useCombinedRefs(useRef<HTMLInputElement>(), ref) as unknown as React.MutableRefObject<HTMLInputElement>;
	const internalAriaLabel = ariaLabel || 'Search';

	const clearInput = () => {
		searchRef.current.value = '';
		searchRef.current.focus();
	};

	return (
		<Input
			ref={searchRef}
			type="search"
			placeholder={placeholder}
			aria-label={internalAriaLabel}
			addOnRole="search"
			addOnPrepend={<IconSearch />}
			addOnAppend={
				<Button data-testid="clear-search" onClick={callAll(clearInput, onClear)} aria-label={`Clear ${internalAriaLabel}`} aria-describedby={props.id}>
					<IconClose />
				</Button>
			}
			embedded={true}
			{...props}
		/>
	);
});
Search.displayName = 'Search';

export default Search;
