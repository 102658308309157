// https://github.com/department-stockholm/aws-signature-v4
import CryptoJS from 'crypto-js';
import {
	CanonicalRequestPayload,
	CanonicalRequestURI,
	CanonicalRequestQuery,
	HmacProps,
	ToTimeProp,
	CanonicalRequestHeaders,
	CreateCanonicalRequestProps
} from '../types';

const urlSearchParamsToObject = (entries: URLSearchParams) => {
	const result: Record<string, string> = {};
	for (const [key, value] of entries) {
		// each 'entry' is a [key, value] tupple
		result[key] = value;
	}
	return result;
};

const createCanonicalRequest: CreateCanonicalRequestProps = (method, pathname, query, headers, payload) => {
	return [
		method.toUpperCase(),
		createCanonicalURI(pathname),
		createCanonicalQueryString(query),
		createCanonicalHeaders(headers),
		createSignedHeaders(headers),
		createCanonicalPayload(payload)
	].join('\n');
};

const escapePath = (pathname: string) => {
	return pathname
		.split(/\//g)
		.map(v => encodeURIComponent(v))
		.join('/');
};

const createCanonicalURI = (uri: CanonicalRequestURI) => {
	try {
		const urlObject = new URL(uri);
		return `${escapePath(urlObject.pathname)}`;
	} catch (e) {
		// ignore, uri likely not valid URL format, so get path below
	}

	return escapePath(uri);
};

const createCanonicalPayload = (payload: CanonicalRequestPayload) => {
	if (payload === 'UNSIGNED-PAYLOAD') {
		return payload;
	}
	return hash(payload || '');
};

const createCanonicalQueryString = (params: CanonicalRequestQuery) => {
	if (!params) {
		return '';
	}

	if (typeof params == 'string') {
		params = urlSearchParamsToObject(new URLSearchParams(params));
	}

	return Object.keys(params)
		.sort()
		.map(key => {
			const paramValue = (params as Record<string, unknown[]>)[key];
			const values = Array.isArray(paramValue) ? paramValue : [paramValue];

			return values
				.sort()
				.map(value => {
					return encodeURIComponent(key) + '=' + encodeURIComponent(value as string);
				})
				.join('&');
		})
		.join('&');
};

const createCanonicalHeaders = (headers: CanonicalRequestHeaders) => {
	return Object.keys(headers)
		.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
		.map(name => {
			const headerValue = headers[name];
			const values = Array.isArray(headerValue) ? headerValue : [headerValue];
			return (
				name.toLowerCase().trim() +
				':' +
				values
					.map(value => {
						return value.replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, '');
					})
					.join(',') +
				'\n'
			);
		})
		.join('');
};

const createSignedHeaders = (headers: CanonicalRequestHeaders) => {
	return Object.keys(headers)
		.map(name => {
			return name.toLowerCase().trim();
		})
		.sort()
		.join(';');
};

const createCredentialScope = (time: ToTimeProp, region: string, service: string) => {
	return [toDate(time), region, service, 'aws4_request'].join('/');
};

const createStringToSign = (time: ToTimeProp, region: string, service: string, request: string) => {
	return ['AWS4-HMAC-SHA256', toTime(time), createCredentialScope(time, region, service), hash(request)].join('\n');
};

const createAuthorizationHeader = (key: string, scope: string, signedHeaders: string, signature: string) => {
	return ['AWS4-HMAC-SHA256 Credential=' + key + '/' + scope, 'SignedHeaders=' + signedHeaders, 'Signature=' + signature].join(',');
};

const createSignature = (secret: string, time: ToTimeProp, region: string, service: string, stringToSign: string) => {
	const kDate = hmac('AWS4' + secret, toDate(time)); // key-date
	const kRegion = hmac(kDate, region); // key-region
	const kService = hmac(kRegion, service); // key-service
	const kSigning = hmac(kService, 'aws4_request'); // key-signing

	return hmac(kSigning, stringToSign).toString();
};

const toTime = (time: ToTimeProp) => {
	return new Date(time).toISOString().replace(/[:-]|\.\d{3}/g, '');
};

const toDate = (time: ToTimeProp) => {
	return toTime(time).substring(0, 8);
};

const hmac: HmacProps = (key, string) => {
	return CryptoJS.HmacSHA256(string, key);
};

const hash = (string: string) => {
	return CryptoJS.SHA256(string).toString();
};

export default {
	toTime,
	toDate,
	createCanonicalRequest,
	createCanonicalQueryString,
	createCanonicalHeaders,
	createSignedHeaders,
	createCredentialScope,
	createStringToSign,
	createAuthorizationHeader,
	createSignature
};
