import React from 'react';
import Input from './Input';
import { Validation } from '../../helpers';
import { InputProps } from './types';

/**
 * see [&lt;Input&gt;](/docs/framework-forms-input--default) for complete documentation on the underlying implementation
 */
const Email = React.forwardRef<HTMLInputElement, InputProps>(({ helptext = 'email@example.com', ...props }, ref) => {
	return <Input ref={ref} helptext={helptext} type="email" validators={[Validation.email]} {...props} />;
});

Email.displayName = 'Email';

export default Email;
