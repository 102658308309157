import React from 'react';
import { SortButton } from '../sortable/SortButton';
import { ColumnInstance } from 'react-table';

interface ColumnHeaderProps {
	column: ColumnInstance;
	disabled?: boolean;
	loading?: boolean;
}

type AriaSortOptions = 'ascending' | 'descending' | undefined;

export const ColumnHeader = ({ column, disabled, loading, ...props }: ColumnHeaderProps): JSX.Element => {
	let ariaSort: AriaSortOptions = undefined;
	if (column.isSortedDesc) {
		ariaSort = 'descending';
	} else if (column.isSorted) {
		ariaSort = 'ascending';
	}
	return (
		<th aria-sort={ariaSort} {...props}>
			{column.canSort ? (
				<SortButton column={column} disabled={loading || disabled}>
					{column.render('Header')}
				</SortButton>
			) : (
				column.render('Header')
			)}
			{column.canFilter && column.Filter ? <div className="p-1">{column.render('Filter', { disabled, readOnly: loading })}</div> : null}
		</th>
	);
};
