import * as React from 'react';
import { Transform, useTransform } from '../hooks/useTransform';
import { utilityClasses } from '../utility';
import { LayerStyleProps, useLayerCounterStyle } from './useLayerCounterStyle';
import { createClassWithSuffix } from '../../shared';

export interface LayerCounterProps extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'style'> {
	transform?: Transform;
	position?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
	style?: LayerStyleProps;
}

export function LayerCounter({ className, transform, position, style = {}, ...props }: LayerCounterProps): JSX.Element {
	const transformProps = useTransform(transform);
	const customStyle = useLayerCounterStyle(style);
	const classes = [className, utilityClasses.layersCounter, position && createClassWithSuffix(utilityClasses.position, position)].filter(Boolean).join(' ');

	return <span className={classes} style={customStyle} {...transformProps} {...props} />;
}
