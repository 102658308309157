import { useRef } from 'react';
import { generateId } from '../generateId';
import { UseElementIdsData, UseElementIdsProps } from '../types';

export function useElementIds({ prefix = 'generic', id, menuId, getGroupId, getItemId }: UseElementIdsProps): UseElementIdsData {
	const elementIdsRef = useRef<UseElementIdsData>({
		id: id ? id : `${prefix}-${generateId()}`,
		menuId: menuId || `${prefix}-menu-${id}`,
		getGroupId: getGroupId || ((index?: number) => `${prefix}-${id}-group-${index}`),
		getItemId: getItemId || ((index?: number) => `${prefix}-${id}-item-${index}`)
	});

	return elementIdsRef.current;
}
