import { AlertVariant } from '../types';
import { IconError, IconInformation, IconSuccess, IconWarning } from '@optic-delight/icons';

export const alertIcons: { [key: string]: React.ElementType } = {
	danger: IconError,
	warning: IconWarning,
	success: IconSuccess,
	info: IconInformation
};

export const useAlert = ({ variant, condensed }: { variant: Omit<AlertVariant, 'primary'>; condensed?: boolean }) => {
	const baseProps = {
		fixedWidth: true,
		size: condensed ? 'lg' : '2x',
		theme: 'solid'
	};
	return { icon: alertIcons[variant as string], iconProps: { ...baseProps } };
};
