import React from 'react';
import { ListGroup } from 'react-bootstrap';
import FileUpload from './FileUpload';
import RealTimeFileUploadItem from './RealTimeFileUploadItem';
import { FileListProps, RealTimeFileUploadProps } from './types';

const defaultFileList = ({ data, ...props }: RealTimeFileUploadProps) => {
	return (
		<ListGroup>
			{data?.fileList.map(file => {
				return <RealTimeFileUploadItem key={file.name} file={file} {...props} />;
			})}
		</ListGroup>
	);
};

export default function RealTimeFileUpload(inProps: RealTimeFileUploadProps) {
	const { chunkSize, onUpload, customFileList = defaultFileList, errorMessage, preview = false, ...props } = inProps;
	return <FileUpload customFileList={({ data }: FileListProps) => customFileList({ data, chunkSize, onUpload, errorMessage, preview })} {...props} />;
}
