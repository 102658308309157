import React from 'react';

import { CardElementProps } from './types';

const CardBody = ({ className, ...props }: CardElementProps): JSX.Element => {
	const classes = [className, 'card-body'].filter(Boolean).join(' ');
	return <div className={classes} {...props} />;
};

CardBody.displayName = 'CardBody';

export default CardBody;
