import React, { Children } from 'react';
import PropTypes from 'prop-types';
import NavProgressItem from './NavProgressItem';
import { getProgressDotVariantClasses, progressDotVariants } from '../progress/ProgressDots';

const NavProgress = ({ defaultActiveKey, variant, children, ...props }) => {
	let hasActiveChild = false;
	let childrenWithProps = Children.map(children, child => {
		if (React.isValidElement(child)) {
			if (!hasActiveChild && (child.props.href === defaultActiveKey || child.props.eventKey === defaultActiveKey)) {
				hasActiveChild = true;
				return React.cloneElement(child, { active: true, complete: false });
			} else if (!hasActiveChild) {
				return React.cloneElement(child, { active: false, complete: true });
			}
		}
		return child;
	});

	return (
		<nav className="d-block">
			<div {...props} className={getProgressDotVariantClasses(variant)}>
				{childrenWithProps}
			</div>
		</nav>
	);
};
NavProgress.displayName = 'NavProgress';

NavProgress.defaultProps = {
	variant: 'primary'
};

NavProgress.propTypes = {
	/**
	 * progress dot variants
	 */
	variant: PropTypes.oneOf(progressDotVariants),

	/**
	 * current active `NavProgressItem`
	 */
	defaultActiveKey: PropTypes.string.isRequired,

	/**
	 * collection of `NavProgressItem`'s
	 */
	children: PropTypes.node.isRequired
};

NavProgress.Item = NavProgressItem;

export default NavProgress;
