import { createIcon } from '../utility/createIcon';

export const IconFile = createIcon('file');

export const IconFileArchive = createIcon('file-archive');

export const IconFileCode = createIcon('file-code');

export const IconFileError = createIcon('file-error');

export const IconFileExcel = createIcon('file-excel');

export const IconFileImage = createIcon('file-image');

export const IconFilePdf = createIcon('file-pdf');

export const IconFilePowerpoint = createIcon('file-powerpoint');

export const IconFileText = createIcon('file-text');

export const IconFileWord = createIcon('file-word');
