export const EMPTY_ARRAY = [];
export const FIFTEEN_MEGABYTE = 15728640;
export const ACCEPT_FILE_EXTENSIONS = [
	'.rtf',
	'.txt',
	'.doc',
	'.docx',
	'.pdf',
	'.jpg',
	'.jpe',
	'.tif',
	'.xml',
	'.fi',
	'.mp3',
	'.mpe',
	'.mpeg',
	'.htm',
	'.png',
	'.jpeg',
	'.flv',
	'.sql',
	'.xsl',
	'.gif',
	'.mp2',
	'.mp3',
	'.mp4',
	'.sh',
	'.edi',
	'.pgp',
	'.gpg',
	'.csv',
	'.jrx',
	'.zip',
	'.xls',
	'.msg',
	'.asc',
	'.dat',
	'.log',
	'.wmv',
	'.bmp',
	'.wav',
	'.ppt',
	'.pptx',
	'.mbi',
	'.fea',
	'.mht',
	'.p7s',
	'.834',
	'.fw',
	'.dec',
	'.xps',
	'.7z',
	'.lst',
	'.gz',
	'.mnt',
	'.key',
	'.all',
	'.eps',
	'.wpl',
	'.vsd',
	'.trn',
	'.url',
	'.hip',
	'.ai',
	'.pps',
	'.qem',
	'.xps',
	'.ar',
	'.oft',
	'.one',
	'.m4a',
	'.ods',
	'.dot',
	'.xlt',
	'.mdi'
];
export const BLACKLIST_FILE_EXTENSIONS = [
	'.exe',
	'.pif',
	'.app',
	'.gad',
	'.msi',
	'.msp',
	'.com',
	'.scr',
	'.hta',
	'.cpl',
	'.msc',
	'.jar',
	'.bat',
	'.cmd',
	'.vb',
	'.vbs',
	'.vbe',
	'.js',
	'.jse',
	'.ws',
	'.wsf',
	'.wsc',
	'.wsf',
	'.wsc',
	'.wsh',
	'.ps1',
	'.ps2',
	'.psc',
	'.msh',
	'.scf',
	'.lnk',
	'.inf',
	'.reg',
	'.jsp',
	'.php'
];

export const ERROR_MESSAGE = {
	duplicateFile: 'already attached',
	disallowedFileType: 'is not an allowable file type',
	fileTooLarge: 'exceeds allowable file size',
	tooManyFiles: 'Only one file can be uploaded at a time',
	fileEmpty: 'is empty',
	generic: 'An error occurred'
};

export const PROGRESS_ERROR_STATE = -1;
