// shortening the icon mapping imports creates circular dependencies
import { iconMap } from '../../font-icon/fontIconMapping';
import { spriteIconMap } from '../../sprite-icon/spriteIconMapping';
import { supportedThemes } from '../utility';
import { IconName, MappingIconName } from '../../font-icon/types';
import { SpriteIconName } from '../../sprite-icon/types';
import { Theme } from '../types';

export function useIconMap(iconName: IconName | SpriteIconName, theme: Theme | undefined): Record<string, unknown> {
	const existsInIconMap = Object.prototype.hasOwnProperty.call(iconMap, iconName);
	const fontIcon = {
		icon: iconMap[iconName as MappingIconName]?.iconName,
		theme: theme || iconMap[iconName as MappingIconName]?.theme
	};

	const existsInSpriteIconMap = Object.prototype.hasOwnProperty.call(spriteIconMap, iconName);
	const spriteIcon = {
		icon: spriteIconMap[iconName as SpriteIconName]?.iconName,
		theme: supportedThemes.fullColor
	};

	if (theme === supportedThemes.fullColor) {
		if (existsInSpriteIconMap) return spriteIcon;
		if (existsInIconMap) return fontIcon;
	} else if (!theme || !Object.values(supportedThemes).includes(theme)) {
		if (existsInIconMap) return fontIcon;
		if (existsInSpriteIconMap) return spriteIcon;
	}

	return {
		icon: iconName,
		theme
	};
}
