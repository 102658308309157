import React from 'react';
import { Button } from 'react-bootstrap';
import { IconExpand, IconNext } from '@optic-delight/icons';
import { ExpanderProps } from './types';

const Expander = ({ row, buttonProps = {} }: ExpanderProps): JSX.Element => {
	const { onClick, ...rest } = buttonProps;
	const { title, ...toggleRowExpandedProps } = row.getToggleRowExpandedProps({
		'aria-expanded': !!row.isExpanded,
		'aria-controls': `rowExpander${row.id}`,
		...rest
	});
	return (
		<Button
			variant="link"
			size="sm"
			{...toggleRowExpandedProps}
			onClick={e => {
				row.toggleRowExpanded();
				onClick?.(e);
			}}>
			<span className="visually-hidden">{title}</span>
			{row.isExpanded ? <IconExpand /> : <IconNext />}
		</Button>
	);
};
Expander.displayName = 'Expander';

export default Expander;
