import React from 'react';
import PropTypes from 'prop-types';
import Label from '../../utility/Label';
import Helpblock from '../../utility/Helpblock';
import { AttributeBasedValidation, fieldHasErrors, getFieldErrors, InlineHelper } from '../../../helpers';
import { FormControlFeedback } from '../form-control';
import FormGroup from '../form-group/FormGroup';

const FormGroupValidation = ({
	groupProps,
	labelProps,
	helpblockProps,
	inputRef,
	validation,
	requiredFieldMessage,
	suppressValidationMessage,
	inline,
	children
}) => {
	const errors = validation.formContext?.formState?.errors;

	const getErrorMessage = () => {
		const errorsForInput = getFieldErrors(validation.inputName, errors);

		if (errorsForInput) {
			const validatorsMessage = validation.validators
				?.map(validator => {
					// return error message for the appropriate validator under error

					if (errorsForInput.type === validator.name || errorsForInput.types?.[validator.name]) {
						return validator.message;
					}

					return null;
				})
				.filter(Boolean);

			if (validatorsMessage.length > 0) {
				return validatorsMessage;
			} else if (AttributeBasedValidation[errorsForInput.type]) {
				return errorsForInput.message;
			}
		}

		if (validation.isRequired && inputRef.current?.value === '') {
			return `${validation.displayLabel} ${requiredFieldMessage}`;
		}

		return null;
	};

	return (
		<FormGroup {...groupProps}>
			<Label {...labelProps} />
			<InlineHelper inline={inline}>
				{children}
				<Helpblock {...helpblockProps} />
				{!suppressValidationMessage && (validation.isRequired || fieldHasErrors(validation.inputName, errors)) ? (
					<FormControlFeedback type="invalid">{getErrorMessage()}</FormControlFeedback>
				) : null}
			</InlineHelper>
		</FormGroup>
	);
};
FormGroupValidation.displayName = 'FormGroupValidation';

FormGroupValidation.defaultProps = {
	groupProps: {},
	labelProps: {},
	helpblockProps: {},
	validation: {},
	inputRef: {},
	requiredFieldMessage: 'is a required field',
	suppressValidationMessage: false
};

FormGroupValidation.propTypes = {
	/**
	 * `FormGroup` property implementation from `useFormGroupValidation` hook
	 */
	groupProps: PropTypes.object,

	/**
	 * `Label` property implementation from `useFormGroupValidation` hook
	 */
	labelProps: PropTypes.object,

	/**
	 * `Helpblock`` property implementation from `useFormGroupValidation` hook
	 */
	helpblockProps: PropTypes.object,

	/**
	 * `useFormGroupValidation` hook validation object
	 */
	validation: PropTypes.object,

	/**
	 * the registered input reference
	 */
	inputRef: PropTypes.object,

	/**
	 * Form control input
	 */
	children: PropTypes.any,

	/*
	 * Allows customizing the default required field error message
	 */
	requiredFieldMessage: PropTypes.string,

	/*
	 * An escape hatch to remove pre-defined validation message reporting
	 */
	suppressValidationMessage: PropTypes.bool,

	/**
	 * inlines form label and input
	 */
	inline: PropTypes.bool
};

export default FormGroupValidation;
