import React, { ChangeEvent, KeyboardEvent } from 'react';
import OptionsList from './OptionsList';
import { useMultipleSelection, useSelect } from 'downshift';
import { IGroupedListOption, ListboxItemsListProps } from './types';
import { Label } from '../../utility';
import { isKeyPress, ListboxKeys } from '../../../helpers/keyboardKeys';
import { comparatorTypes } from '../combobox/utility';
import { useSearchableItemsList } from './hooks';
import { Checkbox } from '../checkable';
import OptionItem from './OptionItem';
import Search from '../search/Search';
import { isInHiddenGroup, selectInputReducerUtils, updateSelectReducerChanges } from './utility';
import { IconLoading } from '@optic-delight/icons';

export const ListboxSearchDefaultPlaceholder = 'Search...';
export const ListboxSearchExcludePlaceholder = 'Exclude';
export const ListboxNotFoundPlaceholder = 'Nothing found';

const ListboxItemsList = React.forwardRef<HTMLUListElement, ListboxItemsListProps>((props, ref) => {
	const {
		id,
		items,
		value,
		defaultValue,
		disabled = false,
		loading = false,
		loadingLabel,
		exclusionFilter,
		onChange,
		compareType = comparatorTypes.startsWith,
		label,
		searchPlaceholder = ListboxSearchDefaultPlaceholder,
		excludeLabel = ListboxSearchExcludePlaceholder,
		notFoundPlaceholder = ListboxNotFoundPlaceholder,
		parentLabel,
		parentLabelId,
		invalid,
		requiredFieldMessage,
		suppressValidationMessage,
		'data-testid': testId,
		textTruncation = true
	} = props;

	const [exclude, setExclude] = React.useState(false);

	const {
		filteredItems,
		searchValue,
		setSearchValue,
		searchRef,
		initialSelectedValue,
		hiddenGroups,
		getItemByValue,
		getGroupItems,
		collapseGroup,
		itemsNumber,
		countItems
	} = useSearchableItemsList({
		items,
		value,
		defaultValue,
		compareType,
		exclude,
		multiple: true
	});

	const filteredItemsToDisplay = (filteredItems as IGroupedListOption[]).filter(item => !isInHiddenGroup(item, hiddenGroups));

	function onSearchInputChange(event: ChangeEvent<HTMLInputElement>): void {
		setSearchValue(event.target.value);
	}

	function getSelectedItems(): IGroupedListOption[] {
		return downshiftMultiSelectProps.selectedItems.map(item => getItemByValue(item)).filter(Boolean) as IGroupedListOption[];
		// type cast as filter thinks it can return undefined, but we filtered it
	}

	function onListFocused(): void {
		if (downshiftProps.highlightedIndex < 0) {
			downshiftProps.setHighlightedIndex(0);
		}
	}

	function calculateLabel(): string {
		if (label === undefined) {
			return '';
		}

		let labelWithCount = label;
		if (items) {
			const selectedCount = downshiftMultiSelectProps.selectedItems.length;
			if (selectedCount === 0) {
				labelWithCount += ` (${itemsNumber})`;
			} else {
				const itemsPlural = itemsNumber === 1 ? 'item' : 'items';
				labelWithCount += ` (${selectedCount} of ${itemsNumber} ${itemsPlural} selected)`;
			}
		}
		return labelWithCount;
	}

	function toggleAllItems(setAllChecked: boolean) {
		onChange?.(setAllChecked ? [...filteredItems.filter(item => !item.isGroup)] : []);
	}

	function onSelectCheckboxClicked() {
		const areNoneSelected = downshiftMultiSelectProps.selectedItems.length === 0;
		toggleAllItems(areNoneSelected); // toggle on when nothing selected
	}

	function onControlA(event: KeyboardEvent): void {
		if (isKeyPress(ListboxKeys.CtrlA, event)) {
			// this issue says there is no type definition for `preventDownshiftDefault`
			// https://github.com/downshift-js/downshift/issues/734
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			event.preventDownshiftDefault = true;
			toggleAllItems(true);
		}
	}

	function toggleGroup(toggledGroup: IGroupedListOption) {
		const toggledItems = getGroupItems(toggledGroup, filteredItems);
		const toggledItemsValues = Object.fromEntries(toggledItems.map(item => [item.value, true]));
		const areNoneSelected = toggledItems.every(item => !downshiftMultiSelectProps.selectedItems.includes(item.value ?? ''));

		let selectedValues: IGroupedListOption[];
		if (areNoneSelected) {
			selectedValues = [...getSelectedItems(), ...toggledItems];
		} else {
			selectedValues = [...getSelectedItems().filter(item => item.value !== undefined && !toggledItemsValues[item.value])];
		}

		onChange?.(selectedValues);
	}

	const downshiftMultiSelectProps = useMultipleSelection({
		selectedItems: initialSelectedValue as (string | number)[]
	});

	const downshiftProps = useSelect({
		id,
		items: filteredItemsToDisplay || [],
		selectedItem: null,
		stateReducer: (state, actionAndChanges) => {
			const { changes, type } = actionAndChanges;
			if (type === useSelect.stateChangeTypes.ItemMouseMove || type === useSelect.stateChangeTypes.MenuMouseLeave) {
				return changes;
			}

			switch (type) {
				case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
					// without this code downshift don't let us put spaces in our search input,
					// but we use it only if no one item is currently highlighted
					if (state.highlightedIndex === -1) {
						if (searchRef.current === document.activeElement) {
							// if search input is focused, then just add a space character to the current input
							setSearchValue(searchRef.current.value + ' ');
						} else {
							// otherwise we add space straight to downshift inputValue and focus the search input
							setSearchValue(changes.inputValue + ' ');
							searchRef.current?.focus();
						}
					}

					return updateSelectReducerChanges(changes, true, state.highlightedIndex);

				case useSelect.stateChangeTypes.MenuKeyDownEnter:
				case useSelect.stateChangeTypes.ItemClick:
					return updateSelectReducerChanges(changes, true, state.highlightedIndex);

				case useSelect.stateChangeTypes.MenuKeyDownEscape:
					return selectInputReducerUtils.menuKeydownEscape(changes, searchValue, setSearchValue);

				case useSelect.stateChangeTypes.MenuKeyDownCharacter:
					return selectInputReducerUtils.menuKeydownCharacter(changes, searchRef);
			}

			changes.isOpen = true;

			return changes;
		},
		onSelectedItemChange: ({ selectedItem }) => {
			if (selectedItem?.value === undefined || selectedItem.isGroup) {
				return;
			}

			let newSelectedValues: IGroupedListOption[];
			const currentSelectedValues = getSelectedItems();

			const hashedValues = Object.fromEntries(downshiftMultiSelectProps.selectedItems.map(item => [item, true]));

			if (hashedValues[selectedItem.value]) {
				downshiftMultiSelectProps.removeSelectedItem(selectedItem.value);
				newSelectedValues = currentSelectedValues.filter(item => item.value !== selectedItem.value);
			} else {
				downshiftMultiSelectProps.addSelectedItem(selectedItem.value);
				newSelectedValues = [...currentSelectedValues, selectedItem];
			}

			onChange?.(newSelectedValues);
		}
	});

	downshiftProps.getToggleButtonProps(
		downshiftMultiSelectProps.getDropdownProps(
			{},
			{
				suppressRefError: true
			}
		),
		{
			suppressRefError: true
		}
	);

	const activeDescendant =
		downshiftProps.highlightedIndex < 0
			? undefined
			: downshiftProps.getItemProps({
					item: filteredItemsToDisplay[downshiftProps.highlightedIndex],
					index: downshiftProps.highlightedIndex
			  }).id;

	const downshiftMenuProps = downshiftProps.getMenuProps(
		{
			ref,
			tabIndex: disabled ? undefined : 0,
			'aria-multiselectable': true,
			className: ['dropdown-menu-items w-100 p-0 overflow-auto flex-grow-1', invalid && 'is-invalid', disabled && 'overflow-hidden']
				.filter(Boolean)
				.join(' '),
			onKeyDown: onControlA,
			'aria-activedescendant': activeDescendant
		},
		{
			suppressRefError: true
		}
	);

	const downshiftLabelProps = downshiftProps.getLabelProps({
		children: calculateLabel()
	});

	const countOfVisibleItems = countItems(filteredItems.filter(item => item.value !== undefined));
	const areSomeSelected = downshiftMultiSelectProps.selectedItems.length > 0 && downshiftMultiSelectProps.selectedItems.length < countOfVisibleItems;
	const areAllSelected = downshiftMultiSelectProps.selectedItems.length > 0 && downshiftMultiSelectProps.selectedItems.length === countOfVisibleItems;

	return (
		<div className="listbox-menu d-flex flex-column flex-grow-1" data-testid={testId}>
			<Label {...downshiftLabelProps} />

			<div className={`listbox-menu-block d-flex flex-column flex-grow-1 ${invalid ? 'is-invalid' : ''}`}>
				<div className="input-group listbox-menu-header-group">
					<div className="input-group-text listbox-menu-select-all">
						{loading ? (
							<IconLoading data-testid="listbox-loading-spinner" />
						) : (
							<Checkbox
								id={`checkbox-all-${id}`}
								checked={areAllSelected}
								aria-checked={areAllSelected}
								aria-label={`Select all ${label}`}
								disabled={filteredItemsToDisplay.length === 0}
								onChange={onSelectCheckboxClicked}
								indeterminate={areSomeSelected}
								data-testid="select-all-checkbox"
								inputonly
							/>
						)}
					</div>

					<Search
						groupClassName="listbox-search"
						ref={searchRef as unknown as React.Ref<HTMLInputElement>}
						value={searchValue}
						placeholder={searchPlaceholder}
						onChange={onSearchInputChange}
						onKeyDown={onControlA}
						aria-label={`Search ${label}`}
						aria-autocomplete="list"
						aria-labelledby={parentLabelId}
						autoComplete="off"
						className="listbox-search-input"
						onClear={() => {
							setSearchValue('');
						}}
					/>

					{exclusionFilter ? (
						<div className="input-group-text listbox-menu-exclude">
							<Checkbox
								id={`checkbox-exclude-${id}`}
								label={excludeLabel}
								checked={exclude}
								aria-checked={exclude}
								onChange={event => setExclude(event.target.checked)}
								data-testid="exclude-checkbox"
								className="mb-0"
							/>
						</div>
					) : null}
				</div>

				<ul {...downshiftMenuProps} onFocus={onListFocused}>
					{loading ? (
						<div className="w-100 position-relative">
							<OptionItem
								itemProps={{ role: 'option' }}
								data-testid="listbox-loading-label"
								className="dropdown-item pe-none"
								textTruncation={textTruncation}
								aria-disabled={true}>
								{loadingLabel}
							</OptionItem>
						</div>
					) : (
						<OptionsList
							hiddenGroups={hiddenGroups}
							getGroupItems={getGroupItems}
							allItems={filteredItems}
							visibleItems={filteredItemsToDisplay}
							highlightedIndex={downshiftProps.highlightedIndex}
							getItemProps={downshiftProps.getItemProps}
							notFoundPlaceholder={notFoundPlaceholder}
							selectedItems={downshiftMultiSelectProps.selectedItems}
							searchString={searchValue}
							multiple={true}
							leftPaddingBase={0.75}
							onGroupCollapse={collapseGroup}
							onGroupToggle={toggleGroup}
							textTruncation={textTruncation}
						/>
					)}
				</ul>
			</div>

			{invalid && !suppressValidationMessage ? (
				<div className="invalid-feedback">
					{parentLabel} {requiredFieldMessage}
				</div>
			) : null}
		</div>
	);
});
ListboxItemsList.displayName = 'ListboxItemsList';

export default ListboxItemsList;
