import { Theme } from '../types';

export const supportedThemes: Record<string, Theme> = {
	regular: 'regular',
	solid: 'solid',
	duoTone: 'duotone',
	fullColor: 'fullcolor'
};

export const defaultTheme: Theme = supportedThemes.regular;
