import * as React from 'react';
import { IconName as FaIconName } from '@fortawesome/fontawesome-common-types';
import { iconPrefix } from './utility';
import { useTransform } from './hooks/useTransform';
import { useIconMap } from '../shared/hooks';
import { useMask } from './hooks/useMask';
import { useDuoTone } from './hooks/useDuoTone';
import { useAnimation } from './hooks/useAnimation';
import { useBasicStyling } from './hooks/useBasicStyling';
import { defaultTheme } from '../shared';
import { blacklist } from './fontIconMapping';
import { FontIconProps, IconName } from './types';
import FallbackIcon from './FallbackIcon';
import { resolveTag } from '../tag-resolver';

const FontIcon = React.forwardRef((props: FontIconProps, ref: React.ForwardedRef<HTMLElement>): JSX.Element | null => {
	const {
		icon: initialIcon,
		theme: initialTheme,
		style,
		title,
		className,
		color,
		fixedWidth,
		size,
		inverse,
		border,
		rotation,
		stack,
		mask,
		bounce,
		flip,
		shake,
		spin,
		pulse,
		beat,
		fade,
		transform,
		swapOpacity,
		primaryStyle,
		secondaryStyle,
		tag,
		iconFallback = true,
		...iconProps
	} = props;

	let currentIcon = initialIcon;
	const tagMatchIcon = resolveTag(tag, initialTheme);
	if (tagMatchIcon) {
		currentIcon = tagMatchIcon as IconName;
	}
	const { icon, theme = defaultTheme } = useIconMap(currentIcon, initialTheme);

	if (blacklist.includes(icon as FaIconName)) {
		return null;
	} else if (!currentIcon && iconFallback) {
		return <FallbackIcon ref={ref} data-testid="fallback-icon" />;
	}

	const transformProps = useTransform(transform);
	const maskProps = useMask({ mask, theme: initialTheme });
	const { classList: basicStylingClassList } = useBasicStyling({ size, inverse, fixedWidth, border, rotation, stack });
	const { classList: animationClassList } = useAnimation({ bounce, flip, shake, spin, pulse, beat, fade });
	const { classList: duoToneClassList, style: duoToneStyle } = useDuoTone({ theme: initialTheme, swapOpacity, primaryStyle, secondaryStyle });

	const classes = [className, `${iconPrefix}-${theme}`, `${iconPrefix}-${icon}`, ...basicStylingClassList, ...animationClassList, ...duoToneClassList]
		.filter(Boolean)
		.join(' ');

	const iconStyle = {
		color,
		...duoToneStyle,
		...style
	};

	return <i aria-hidden="true" title={title} ref={ref} className={classes} style={iconStyle} {...iconProps} {...maskProps} {...transformProps} />;
});

FontIcon.displayName = 'FontIcon';

export default FontIcon;
