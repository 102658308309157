import { IconProps } from '../Icon';
import { utilityClasses } from './constants';
import { createClassWithSuffix } from './utility';

export interface UseAnimationOptions {
	classList: Array<string>;
}

export function useAnimation({ bounce, flip, shake, spin, pulse, beat, fade }: Omit<IconProps, 'icon'>): UseAnimationOptions {
	const classList = [
		bounce && utilityClasses.bounce,
		typeof flip === 'string' && createClassWithSuffix(utilityClasses.flipDirection, flip),
		typeof flip === 'boolean' && flip && utilityClasses.flip,
		shake && utilityClasses.shake
	].filter(Boolean) as Array<string>;

	if (pulse) {
		classList.push(utilityClasses.pulse);
	} else if (spin) {
		classList.push(utilityClasses.spin);
	}
	if (spin === 'reverse') {
		// used alongside spin/pulse classes
		classList.push(utilityClasses.reverse);
	}

	if (beat && fade) {
		classList.push(utilityClasses.beatFade);
	} else if (beat) {
		classList.push(utilityClasses.beat);
	} else if (fade) {
		classList.push(utilityClasses.fade);
	}

	return {
		classList
	};
}
