import React from 'react';

import InformationalRow from '../components/InformationalRow';

type ExpressionElement = string | number | JSX.Element;
type ExpressionWrapperProps = {
	expression: boolean;
	positiveResult: ExpressionElement;
	colSpan: number;
	children?: string;
	isPresentationTable?: boolean;
};

export const ExpressionWrapper = (props: ExpressionWrapperProps): ExpressionElement => {
	const { expression, positiveResult, colSpan, isPresentationTable, children } = props;
	if (expression) {
		return positiveResult;
	}
	return (
		<InformationalRow colSpan={colSpan} isPresentationTable={isPresentationTable}>
			{children}
		</InformationalRow>
	);
};

ExpressionWrapper.defaultProps = {
	positiveResult: null
};
