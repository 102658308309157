import React from 'react';
import { FormGroupProviderProps } from './types';

export const FormGroupContext = React.createContext<FormGroupProviderProps | undefined>(undefined);
FormGroupContext.displayName = 'FormGroupContext';

export default function FormGroupProvider({ controlId, ...props }: React.PropsWithChildren<FormGroupProviderProps>): JSX.Element {
	return <FormGroupContext.Provider value={{ controlId }} {...props} />;
}

FormGroupProvider.displayName = 'FormGroupProvider';
