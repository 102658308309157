import React, { useState } from 'react';
import { AccordionProps } from './types';

import AccordionItem from './AccordionItem';
import AccordionHeader from './AccordionHeader';
import AccordionBody from './AccordionBody';

import { AccordionContext } from './AccordionContext';

const Accordion = ({
	type,
	condensed = type === 'list',
	flush,
	alwaysOpen,
	activeKey,
	defaultActiveKey,
	className,
	id,
	onSelect,
	...props
}: AccordionProps): JSX.Element => {
	const { Provider } = AccordionContext;

	const [openItems, setOpenItems] = useState([defaultActiveKey] ?? [activeKey] ?? []);

	const isOpen = (eventKey: string) => {
		return openItems?.some(item => item === eventKey);
	};

	const toggleOpen = (eventKey: string) => {
		if (openItems?.some(item => item === eventKey)) {
			if (alwaysOpen) {
				return setOpenItems(openItems.filter(item => item !== eventKey));
			}
			setOpenItems([]);
		} else if (eventKey) {
			if (alwaysOpen) {
				return setOpenItems([...openItems, eventKey]);
			}
			setOpenItems([eventKey]);
		}
		onSelect?.(eventKey);
	};

	const classes = [className, 'accordion', type === 'list' && 'accordion-list-link', condensed && 'accordion-condensed', flush && 'accordion-flush']
		.filter(Boolean)
		.join(' ');

	return (
		<Provider value={{ id, toggleOpen, isOpen }}>
			<div className={classes} {...props} />
		</Provider>
	);
};

Accordion.displayName = 'Accordion';
Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;

export default Accordion;
