import React from 'react';
import { ListGroupItemProps } from './types';
import { useImage } from '../../helpers/hooks/useImage';

import { InlineHelper } from '../../helpers';

export const listGroupItemVariants = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light'];

const ListGroupItem = ({
	imgSrc,
	imgAlt,
	imgClassName = 'img-3x img-thumbnail',
	href,
	action,
	disabled,
	actions,
	variant,
	active,
	as: ListGroupItemWrapper = href ? 'a' : 'li',
	className,
	children,
	...props
}: ListGroupItemProps) => {
	const variantsClasses = ['list-group-item', action && '-action', variant && `-${variant}`].filter(Boolean).join('');
	const classes = [className, variantsClasses, active && 'active', disabled && 'disabled'].filter(Boolean).join(' ');

	return (
		<ListGroupItemWrapper href={href} className={classes} aria-disabled={disabled} tabIndex={disabled ? -1 : undefined} {...props}>
			{(imgSrc && imgAlt) || actions ? (
				<div className="row">
					{imgSrc && imgAlt && useImage({ src: imgSrc, alt: imgAlt, className: imgClassName })}
					<div className="col-auto">
						<div className="list-group-item-text">{children}</div>
					</div>
					{actions && <InlineHelper className="col-auto ms-auto">{actions}</InlineHelper>}
				</div>
			) : (
				children
			)}
		</ListGroupItemWrapper>
	);
};

ListGroupItem.displayName = 'ListGroupItem';

export default ListGroupItem;
