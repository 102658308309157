import * as React from 'react';
import PropType from 'prop-types';
import { ListItemIcon } from './ListItemIcon';
import { useListStyle } from './useListStyle';
import { utilityClasses } from '../utility';

export function ListIcons({ className, style, ...props }: React.HTMLAttributes<HTMLUListElement>): JSX.Element {
	const listStyle = useListStyle(style);
	const classes = [className, utilityClasses.listIcons].filter(Boolean).join(' ');

	return <ul className={classes} style={listStyle} {...props} />;
}
ListIcons.propTypes = {
	className: PropType.string,
	style: PropType.object
};

ListIcons.Icon = ListItemIcon;
