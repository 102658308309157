import React, { ForwardedRef, useImperativeHandle } from 'react';
import DragAndDrop from './DragAndDrop';
import { BLACKLIST_FILE_EXTENSIONS, ERROR_MESSAGE, FIFTEEN_MEGABYTE } from './utility/constants';
import { fieldHasErrors, InlineHelper } from '../../../helpers';
import useFileUploadInternal from './hooks/useFileUploadInternal';
import { FileTypeSpecifier, FileUploadProps, FileUploadRef } from './types';
import { sharedDefaultProps } from './utility/sharedPropTypes';
import { inputDefaultProps } from '../../../props/inputProps';
import FileUploadFilesList from './FileUploadFilesList';
import Label from '../../utility/Label';
import Helpblock from '../../utility/Helpblock';
import FormGroupValidation from '../shared/FormGroupValidation';
import { useElementIds } from '../../../helpers/hooks/useElementIds';

import { FormControlFeedback } from '../form-control';
import FormGroup from '../form-group/FormGroup';

const fileTypeSpecifierToLowercase = (data: FileTypeSpecifier): FileTypeSpecifier => {
	if (typeof data === 'string') {
		return data.toLowerCase();
	}

	return data.map(item => item.toLowerCase());
};

const FileUpload = React.forwardRef(
	(
		{
			id: propsId,
			label,
			'aria-label': ariaLabel,
			noFilesText = 'No file(s) chosen',
			tooltip,
			dirty = false,
			accept = sharedDefaultProps.accept,
			blackList = BLACKLIST_FILE_EXTENSIONS,
			maxSize = FIFTEEN_MEGABYTE,
			duplicateFile = ERROR_MESSAGE.duplicateFile,
			disallowedFileType = ERROR_MESSAGE.disallowedFileType,
			fileTooLarge = ERROR_MESSAGE.fileTooLarge,
			tooManyFiles = ERROR_MESSAGE.tooManyFiles,
			fileEmpty = ERROR_MESSAGE.fileEmpty,
			customFileList,
			hideFileList = false,
			preview = false,
			stateReducer,
			fileListFileTypeColumnText,
			fileListFileNameColumnText,
			groupClassName = 'mb-3',
			inline,
			required,
			validators = [],
			helptext,
			requiredFieldMessage = FormGroupValidation.defaultProps.requiredFieldMessage,
			internalProps,
			defaultValue,
			disabled,
			...props
		}: FileUploadProps,
		ref: ForwardedRef<FileUploadRef>
	) => {
		const dragAndDropRef = React.useRef<HTMLInputElement>(null);
		const acceptLowercase = fileTypeSpecifierToLowercase(accept);
		const blacklistLowercase = fileTypeSpecifierToLowercase(blackList);

		const { data, dispatch, addFiles, removeFiles, formState } =
			internalProps ??
			useFileUploadInternal({
				name: props.name,
				required: required ?? false,
				blackList: blacklistLowercase,
				accept: acceptLowercase,
				maxSize,
				dirty,
				stateReducer,
				defaultValue,
				multiple: props.multiple ?? sharedDefaultProps.multiple,
				disabled,
				errorMessages: {
					duplicateFile,
					disallowedFileType,
					fileTooLarge,
					tooManyFiles,
					fileEmpty
				},
				validators
			});

		const { id } = useElementIds({ prefix: 'fileupload', id: propsId });
		const helpblockId = helptext ? id + '_helptext' : null;
		const formGroupClasses = [groupClassName, inline && 'row', inline && 'gx-2'].filter(Boolean);
		const isDisabled = formState?.isDisabled || disabled;

		useImperativeHandle(ref, () => ({
			element: dragAndDropRef.current ?? undefined,
			removeFiles,
			addFiles
		}));

		const getErrorMessage = () => {
			if (isDisabled) return null;

			if (required && data.fileList.length === 0) {
				return `${label || ariaLabel} ${requiredFieldMessage}`;
			}

			return null;
		};
		const errorMessage = getErrorMessage();
		const isInvalid = data.isInvalid || fieldHasErrors(props.name, formState?.errors) || (Array.isArray(errorMessage) && errorMessage.length > 0);

		return (
			<FormGroup className={formGroupClasses.join(' ')}>
				<Label id={id + '_label'} required={required} tooltip={tooltip} htmlFor={id} column={inline}>
					{label}
				</Label>
				<InlineHelper inline={inline}>
					<DragAndDrop
						id={id}
						ref={dragAndDropRef}
						fileUpload={{
							data,
							dispatch,
							addFiles
						}}
						accept={acceptLowercase}
						maxSize={maxSize}
						isInvalid={isInvalid}
						aria-label={label ? undefined : ariaLabel || undefined}
						disabled={isDisabled}
						{...props}
					/>

					{!hideFileList ? (
						customFileList ? (
							customFileList({ data, dispatch })
						) : (
							<FileUploadFilesList
								isInvalid={isInvalid}
								fileListFileNameColumnText={fileListFileNameColumnText}
								fileListFileTypeColumnText={fileListFileTypeColumnText}
								fileList={data.fileList}
								disabled={isDisabled}
								preview={preview}
								dispatch={dispatch}
								noFilesText={noFilesText}
							/>
						)
					) : null}

					<Helpblock id={helpblockId}>{helptext}</Helpblock>

					{!props.suppressValidationMessage && (required || validators.length > 0) ? (
						<FormControlFeedback type="invalid">{getErrorMessage()}</FormControlFeedback>
					) : null}

					{data.showErrorAlert ? (
						<FormControlFeedback type="invalid" style={{ display: 'block' }}>
							<ul className="list-group" style={{ listStyle: 'none' }}>
								{data.errorList.map((error, index) => {
									return <li key={index}>{error}</li>;
								})}
							</ul>
						</FormControlFeedback>
					) : null}
				</InlineHelper>
			</FormGroup>
		);
	}
);
FileUpload.defaultProps = {
	...inputDefaultProps
};
FileUpload.displayName = 'FileUpload';

export default FileUpload;
