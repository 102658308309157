import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { inputDefaultProps, inputPropTypes } from '../../props/inputProps';

import { applyEventListener, formatDate, useCombinedRefs, Validation } from '../../helpers';
import { IconBack, iconMap, IconNext } from '@optic-delight/icons';
import ReactDatePicker from 'react-datepicker';
import { Button } from '../button';
import Input from './Input';
import { DatePickerHotKeys } from '../../helpers/keyboardKeys';
import { useFormContext } from './form-element';
import { useControlledFocusOnError } from './shared/hooks/useControlledFocusOnError';

function getDate(offsetDays = 0) {
	let aDate = new Date(Date.now());
	aDate.setDate(aDate.getDate() + offsetDays);
	return aDate;
}

function addMonths(date = getDate(), offsetMonths = 0) {
	const aDate = new Date(date.getTime());
	aDate.setMonth(aDate.getMonth() + offsetMonths);
	return aDate;
}

export function parseDateString(dateString) {
	if (!dateString) {
		return undefined;
	}

	let parts = dateString.split('/');
	const date = new Date(parts[2], parts[0] - 1, parts[1]);

	return isNaN(date.getTime()) ? undefined : date;
}

const DatePickerAddOn = ({ embedded, addOns }) => {
	if (!embedded && addOns) {
		let addOnsArray = Array.isArray(addOns) ? addOns : [addOns];
		return addOnsArray.map((addOn, index) => (
			<span key={index} className="input-group-text">
				{addOn}
			</span>
		));
	}

	return null;
};
DatePickerAddOn.propTypes = {
	addOns: PropTypes.node,
	embedded: PropTypes.bool
};

const CustomInput = React.forwardRef(({ embedded, button, startDate, buttonTitle, fieldName, className, ...props }, ref) => {
	const { ref: controlRef } = useControlledFocusOnError({ name: fieldName });
	const combinedRef = useCombinedRefs(ref, controlRef);
	if (!embedded && button) {
		return (
			<Button ref={combinedRef} className={className} iconDirection="append" icon={iconMap['calendar-grid'].iconName} {...props}>
				<span className="me-2">{startDate ? formatDate(startDate) : buttonTitle}</span>
			</Button>
		);
	}

	return (
		<Button
			ref={ref}
			icon={iconMap['calendar-grid'].iconName}
			className={[embedded && 'input-group-text', className].filter(Boolean).join(' ')}
			style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
			{...props}>
			<span className="visually-hidden">{`choose date${startDate ? ', selected date is ' + formatDate(startDate, { dateStyle: 'full' }) : ''}`}</span>
		</Button>
	);
});
CustomInput.propTypes = {
	embedded: PropTypes.bool,
	button: PropTypes.bool,
	startDate: PropTypes.instanceOf(Date),
	buttonTitle: PropTypes.string,
	fieldName: PropTypes.string,
	className: PropTypes.string
};

const DatePicker = React.forwardRef(
	(
		{
			id,
			name,
			label,
			button,
			block,
			tooltip,
			helptext = !button && 'MM/DD/YYYY',
			defaultValue,
			value,
			onChange,
			onDatePickerChange,
			required,
			dirty,
			offset,
			validators,
			groupClassName,
			'aria-label': ariaLabel,
			inline,
			buttonTitle = 'Select date',
			'data-testid': dataTestId,
			embedded,
			addOnPrepend,
			addOnAppend,
			disabled,
			readOnly,
			placeholder,
			...props
		},
		ref
	) => {
		const inputHiddenClass = [button && 'd-none'].filter(Boolean).join('');
		if (props.excludeDates) {
			props.excludeDates = props.excludeDates.length ? props.excludeDates.map(date => parseDateString(date)) : parseDateString(props.excludeDates);
		}
		if (props.minDate) {
			props.minDate = parseDateString(props.minDate);
		}
		const [startDate, setStartDate] = useState(parseDateString(value || defaultValue));
		const inputRef = useCombinedRefs(useRef(), ref);
		const formContext = useFormContext();

		const setDate = useCallback(
			date => {
				const dateWithLeadingZeros = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
				setStartDate(date);
				inputRef.current.value = dateWithLeadingZeros;
				applyEventListener(onDatePickerChange, [inputRef.current]);
			},
			[inputRef, onDatePickerChange]
		);

		const handleChange = useCallback(
			e => {
				const newDate = parseDateString(e.target.value);
				setStartDate(newDate || undefined);
				inputRef.current.value = e.target.value;

				applyEventListener(onChange, [e]);
			},
			[inputRef, onChange]
		);

		const handleBlur = useCallback(
			e => {
				let targetValue = e.target.value;
				if (targetValue === DatePickerHotKeys.today) {
					setDate(getDate());
					e.target.value = inputRef.current.value;
					applyEventListener(onChange, [e]);
				} else if (targetValue === DatePickerHotKeys.previousDay) {
					setDate(getDate(-1));
					e.target.value = inputRef.current.value;
					applyEventListener(onChange, [e]);
				} else if (targetValue === DatePickerHotKeys.nextDay) {
					setDate(getDate(1));
					e.target.value = inputRef.current.value;
					applyEventListener(onChange, [e]);
				} else {
					e.target.value = formatDate(e.target.value);
					handleChange(e);
				}

				applyEventListener(props.onBlur, [e]);
			},
			[setDate, inputRef, props.onBlur, onChange, handleChange]
		);
		const isDisabled = formContext?.formState?.isDisabled || disabled;

		// It is necessary so that any change of the controlled datepicker, will change its value
		useEffect(() => {
			if (!defaultValue) {
				setStartDate(parseDateString(value));
			}
		}, [value, defaultValue]);

		const updatableFormValue = formContext.watch && formContext?.watch(name);
		useEffect(() => {
			if (updatableFormValue && typeof updatableFormValue === 'string') {
				setStartDate(parseDateString(updatableFormValue));
			}
		}, [updatableFormValue]);

		const component = (
			<Input
				ref={inputRef}
				type={button ? 'hidden' : undefined}
				groupClassName={groupClassName}
				id={id}
				name={name}
				label={label}
				aria-label={ariaLabel}
				aria-hidden={button}
				tooltip={tooltip}
				helptext={helptext}
				value={value}
				defaultValue={defaultValue}
				onBlur={handleBlur}
				onChange={handleChange}
				required={required}
				disabled={isDisabled}
				dirty={dirty}
				validators={validators}
				maxLength="10"
				size="10"
				className={inputHiddenClass}
				data-testid={dataTestId}
				inline={inline}
				embedded={embedded}
				readOnly={readOnly}
				addOnPrepend={embedded && addOnPrepend}
				placeholder={placeholder}
				addOnAppend={
					<>
						<DatePickerAddOn embedded={embedded} addOns={addOnPrepend} />
						<ReactDatePicker
							id={id + '_picker'}
							selected={startDate}
							onChange={date => {
								setDate(date);
								if (formContext?.setValue) {
									formContext.setValue(inputRef.current.name, inputRef.current.value, { shouldValidate: true });
								}
							}}
							onChangeRaw={e => {
								if (!e.target.value) {
									return;
								}
								handleChange(e);
							}}
							preventOpenOnFocus
							dateFormat="MM/dd/yyyy"
							showLeadingZeros={true}
							disabled={isDisabled || readOnly}
							customInput={<CustomInput embedded={embedded} button={button} startDate={startDate} buttonTitle={buttonTitle} fieldName={name} />}
							popperPlacement="bottom"
							popperModifiers={[
								{
									name: 'offset',
									options: {
										enabled: true,
										offset: offset
									}
								}
							]}
							renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
								const monthYearFormat = { year: 'numeric', month: 'long' };
								return (
									<div className="clearfix">
										<button
											type="button"
											className="btn btn-link float-start previous"
											onClick={decreaseMonth}
											disabled={prevMonthButtonDisabled}
											aria-label={`Previous Month, ${formatDate(addMonths(date, -1), monthYearFormat)}`}>
											<IconBack />
										</button>
										<span className="current-month">{formatDate(date, monthYearFormat)}</span>
										<button
											type="button"
											className="btn btn-link float-end next"
											onClick={increaseMonth}
											disabled={nextMonthButtonDisabled}
											aria-label={`Next Month, ${formatDate(addMonths(date, 1), monthYearFormat)}`}>
											<IconNext />
										</button>
									</div>
								);
							}}
							readOnly={readOnly}
							{...props}
						/>
						<DatePickerAddOn embedded={embedded} addOns={addOnAppend} />
					</>
				}
			/>
		);

		return button && block ? <div className="d-grid">{component}</div> : component;
	}
);
DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
	...inputPropTypes,

	/**
	 * Input field value.  If used, must have an onChange event for it
	 */
	value: PropTypes.any,

	/**
	 * input onChange event
	 */
	onChange: PropTypes.func,

	/**
	 * date picker onChange event.  returns the input reference
	 */
	onDatePickerChange: PropTypes.func,

	/**
	 * input onBlur event
	 */
	onBlur: PropTypes.func,

	/**
	 * popper offset
	 */
	offset: PropTypes.array,

	/**
	 * testing utility helper
	 */
	'data-testid': PropTypes.string,

	/**
	 * Title for button mode
	 */
	buttonTitle: PropTypes.string,

	/**
	 * Render button
	 */
	button: PropTypes.bool,

	/**
	 * display button datepicker with 100% width
	 */
	block: PropTypes.bool
};

DatePicker.defaultProps = {
	...inputDefaultProps,
	offset: [-100, 0],
	validators: [Validation.date]
};

export default DatePicker;
