import React, { useRef } from 'react';
import Input from '../Input';
import Button from '../../button/Button';
import { IconBack, IconClose, IconSearch } from '@optic-delight/icons';
import { debounce, useCombinedRefs } from '../../../helpers';
import OptionsList from '../select/OptionsList';
import { AutoCompleteSearchInputProps } from './types';
import { useCombobox } from 'downshift';
import { IGroupedListOption, IListOption } from '../select';
import OptionItem from '../select/OptionItem';

export const SearchInputPlaceholder = 'Search...';
export const SearchNotFoundPlaceholder = 'Nothing found';

const AutoCompleteSearch = React.forwardRef<HTMLInputElement, AutoCompleteSearchInputProps>(
	(
		{
			'aria-label': ariaLabel,
			className,
			onSelect,
			placeholder = SearchInputPlaceholder,
			notFoundPlaceholder = SearchNotFoundPlaceholder,
			groupClassName,
			resultItems = [],
			maxVisibleItems = 5,
			searchDebounceTimeout = 350,
			onResultSelected,
			onOpenedToggle,
			onChange,
			...props
		},
		ref
	) => {
		const isFirstRender = React.useRef(true);
		const searchCombinedRef = useCombinedRefs(useRef<HTMLInputElement>(), ref) as unknown as React.MutableRefObject<HTMLInputElement>;
		const internalAriaLabel = ariaLabel || 'Search';

		const itemsToDisplay: IGroupedListOption[] = resultItems
			.map(item => ({
				...item,
				isGroup: false
			}))
			.slice(0, maxVisibleItems);
		const resultLengthDiff = resultItems.length - itemsToDisplay.length;

		const clearInput = () => {
			setInputValue('');
			closeMenu();
		};

		const findItems = React.useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				onChange?.(e);
			},
			[onChange]
		);

		const onToggleSearch = () => {
			toggleMenu();
			if (isOpen) {
				searchCombinedRef.current.focus();
			} else {
				setInputValue('');
			}
		};

		const findItemsDebounced = React.useMemo(() => debounce(findItems, searchDebounceTimeout), [findItems, searchDebounceTimeout]);

		const {
			isOpen,
			inputValue,
			highlightedIndex,
			setInputValue,
			getMenuProps,
			getInputProps,
			getComboboxProps,
			getToggleButtonProps,
			getItemProps,
			closeMenu,
			openMenu,
			toggleMenu
		} = useCombobox<IListOption>({
			items: itemsToDisplay,
			onSelectedItemChange({ selectedItem }) {
				onResultSelected?.(selectedItem?.value ?? '');
				searchCombinedRef.current.blur();
				closeMenu();
			},
			onIsOpenChange(changes) {
				if (!changes.isOpen) {
					searchCombinedRef.current.blur();
					setInputValue('');
				}
			},
			itemToString: item => item?.label || ''
		});

		const onClearInput = () => {
			clearInput();
			searchCombinedRef.current.focus();
		};

		const onGoBack = () => {
			clearInput();
			closeMenu();
		};

		const menuIsVisible = isOpen && itemsToDisplay.length > 0;

		const searchContainerClasses = [
			'd-flex justify-content-end flex-grow-1',
			'auto-complete-search',
			isOpen ? 'auto-complete-search-focused' : 'auto-complete-search-unfocused',
			className
		]
			.filter(Boolean)
			.join(' ');

		const inputClasses = ['auto-complete-search-input', groupClassName].filter(Boolean).join(' ');

		if (onOpenedToggle) {
			React.useEffect(() => {
				if (isFirstRender.current) return;
				onOpenedToggle(isOpen);
			}, [onOpenedToggle, isOpen]);
		}

		React.useEffect(() => {
			isFirstRender.current = false;
		});

		return (
			<div {...getComboboxProps({ className: searchContainerClasses })}>
				{isOpen ? (
					<Button tabIndex={-1} className="auto-complete-search-back-button rounded-0" onClick={onGoBack} aria-label="Back">
						<IconBack />
					</Button>
				) : null}

				<Input
					type="search"
					placeholder={placeholder}
					aria-label={internalAriaLabel}
					addOnRole="search"
					width={0}
					onFocus={openMenu}
					groupClassName={inputClasses}
					addOnAppend={
						<span className="px-0">
							{inputValue ? (
								<>
									<Button
										className="search-input-icon"
										data-testid="clear-search"
										onClick={onClearInput}
										aria-label={`Clear ${internalAriaLabel}`}
										tabIndex={-1}>
										<IconClose />
									</Button>
									<div className="border-right search-input-icon-divider" />
								</>
							) : null}
							<Button
								className="search-input-icon"
								data-testid="expand-search"
								aria-label={`Expand ${internalAriaLabel}`}
								{...getToggleButtonProps({
									'aria-describedby': undefined,
									onClick: onToggleSearch
								})}>
								<IconSearch />
							</Button>
						</span>
					}
					value={inputValue}
					embedded={true}
					{...props}
					{...getInputProps({
						ref: searchCombinedRef,
						onChange: findItemsDebounced,
						'aria-labelledby': undefined
					})}
				/>

				<ul
					{...getMenuProps({
						className: `dropdown-menu w-100 top-100 p-0 ${menuIsVisible ? 'show' : ''}`,
						'aria-label': 'Search results',
						'aria-labelledby': undefined
					})}>
					{menuIsVisible ? (
						<>
							<OptionsList
								allItems={itemsToDisplay}
								getItemProps={getItemProps}
								hiddenGroups={[]}
								highlightedIndex={highlightedIndex}
								notFoundPlaceholder={notFoundPlaceholder}
								visibleItems={itemsToDisplay}
							/>
							{resultLengthDiff > 0 ? (
								<OptionItem className="text-muted mb-1" role="option">
									+ {resultLengthDiff} more
								</OptionItem>
							) : null}
						</>
					) : null}
				</ul>
			</div>
		);
	}
);
AutoCompleteSearch.displayName = 'AutoCompleteSearch';

export default AutoCompleteSearch;
