export const SpecialDateValues = {
	Today: 't',
	PreviousBusinessDay: 'p',
	NextBusinessDay: 'n'
};

export const Weekday = {
	Sunday: 0,
	Monday: 1,
	Tuesday: 2,
	Wednesday: 3,
	Thursday: 4,
	Friday: 5,
	Saturday: 6
};
