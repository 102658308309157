import React from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import { FormControl } from 'react-bootstrap';
import { typingDebounceRate } from '../../utility/constants';

export default function NumberFilter({ column: { filterValue, setFilter, Header }, readOnly, disabled }) {
	const [value, setValue] = React.useState(filterValue);
	const mountedRef = React.useRef(false);
	React.useEffect(() => {
		if (!mountedRef.current) {
			mountedRef.current = true;
			return;
		}
		setValue(filterValue);
	}, [mountedRef, filterValue]);

	const onChange = useAsyncDebounce(newValue => {
		setFilter(newValue || undefined); // Set undefined to remove the filter entirely
	}, typingDebounceRate);

	const handleChange = newValue => {
		setValue(newValue);
		onChange(newValue?.trim());
	};

	return (
		<FormControl
			aria-label={'Filter ' + Header}
			type="number"
			value={value || ''}
			onChange={e => handleChange(e.target.value)}
			disabled={disabled}
			readOnly={readOnly}
		/>
	);
}

NumberFilter.propTypes = {
	/**
	 * column definition
	 */
	column: PropTypes.any,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool
};
