import PropTypes from 'prop-types';
import React from 'react';

export type TranslatableText = string | React.ReactElement;

export interface UseTranslatableTextData {
	globalFilterPlaceholder?: string;
	filterResultsLabel?: TranslatableText;
	loadingText?: TranslatableText;
	noResultsText?: TranslatableText;
	searchResultsText?: TranslatableText;
	filterResultsText?: TranslatableText;
	lengthMenuShowText?: TranslatableText;
	lengthMenuOfText?: TranslatableText;
	clearAllText?: TranslatableText;
}

export interface TranslatableTextProviderProps {
	value: UseTranslatableTextData;
}

export const translatableTextPropTypes = {
	/**
	 * Placeholder text for the global filter
	 */
	globalFilterPlaceholder: PropTypes.string,

	/**
	 * Label for filtered results options
	 */
	filterResultsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

	/**
	 * loading state text
	 */
	loadingText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

	/**
	 * Custom text when data is empty
	 */
	noResultsText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

	/**
	 * Custom text to display current search results data
	 */
	searchResultsText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

	/**
	 * Custom text to display current filter results data
	 */
	filterResultsText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

	/**
	 * custom `show` text for `show {pageSize} of {total}`
	 */
	lengthMenuShowText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

	/**
	 * custom `of` text for `show {pageSize} of {total}`
	 */
	lengthMenuOfText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

	/**
	 * custom text for the "clear all" button when filtering for results
	 */
	clearAllText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};
