import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Button } from '@optic-delight/ui-framework';
import { FallbackProps } from '@optic-delight/benefitsolver-services/build/types/components/error-boundary/ErrorBoundary';
import Users from './user/Users';
import Layout from './scaffolding/Layout';
import AuthorizedRoute from './authorization/AuthorizedRoute';
import Logoff from './authorization/Logoff';
import { AuthenticationProvider } from './authorization/AuthenticationProvider';
import Companies from './companies/Companies';

const ModifyUser = lazy(() => import('./user/ModifyUser'));
const Login = lazy(() => import('./authorization/Login'));

function App(): JSX.Element {
	return (
		<AuthenticationProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route
							index
							element={
								<AuthorizedRoute>
									<Users />
								</AuthorizedRoute>
							}
						/>
						<Route
							path="users"
							element={
								<AuthorizedRoute>
									<Users />
								</AuthorizedRoute>
							}
						/>
						<Route
							path="modify"
							element={
								<AuthorizedRoute>
									<React.Suspense fallback={<>...</>}>
										<ModifyUser />
									</React.Suspense>
								</AuthorizedRoute>
							}
						/>
						<Route
							path="modify/:id"
							element={
								<AuthorizedRoute>
									<React.Suspense fallback={<>...</>}>
										<ModifyUser />
									</React.Suspense>
								</AuthorizedRoute>
							}
						/>
						<Route
							path="login"
							element={
								<React.Suspense fallback={<>...</>}>
									<Login />
								</React.Suspense>
							}
						/>
						<Route
							path="logoff"
							element={
								<React.Suspense fallback={<>...</>}>
									<Logoff />
								</React.Suspense>
							}
						/>
						<Route path="*" element={<NotFound />} />

						<Route path="companies" element={<Companies />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</AuthenticationProvider>
	);
}

function NotFound() {
	return <>{'404: not found'}</>;
}

export function AppFallback({ error, resetErrorBoundary }: FallbackProps) {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			{process.env.NODE_ENV !== 'production' && <pre>{error.message}</pre>}
			<Button onClick={resetErrorBoundary}>Try again</Button>
		</div>
	);
}

export default App;
