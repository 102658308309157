import React from 'react';
import useModal from './hooks/useModal';

import { Modal } from 'react-bootstrap';
import ModalTitle from './ModalTitle';

const ModalHeader = (props: React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
	const { closeLabel, onHide } = useModal();
	return (
		<Modal.Header closeButton closeLabel={closeLabel} onHide={onHide}>
			<ModalTitle {...props} />
		</Modal.Header>
	);
};

ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;
