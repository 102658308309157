import React from 'react';
import { DropdownHeaderProps } from './types';

const DropdownHeader = ({ as: Component = 'div', className, ...props }: DropdownHeaderProps) => {
	const classes = [className, 'dropdown-header'].filter(Boolean).join(' ');
	return <Component className={classes} role="heading" {...props} />;
};

DropdownHeader.displayName = 'DropdownHeader';

export default DropdownHeader;
