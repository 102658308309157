import * as React from 'react';
import Icon from '../FontIcon';
import { iconMap } from '../fontIconMapping';
import { FontIconProps, MappingIconName } from '../types';

export type CreateIconProps = Omit<FontIconProps, 'icon'>;

export const createDisplayName = (iconName: MappingIconName): string => {
	const properCasedName = iconName
		.split('-')
		.map(part => part.charAt(0).toUpperCase() + part.slice(1))
		.join('');
	return `Icon${properCasedName}`;
};

export const createIcon = (iconName: MappingIconName): React.ForwardRefExoticComponent<CreateIconProps> => {
	const iconMapDefinition = iconMap[iconName];
	const IconImpl = React.forwardRef((props: CreateIconProps, ref: React.ForwardedRef<HTMLElement>): JSX.Element => {
		return (
			<Icon
				ref={ref}
				icon={iconMapDefinition.iconName}
				theme={iconMapDefinition.theme}
				spin={iconMapDefinition.spin}
				color={iconMapDefinition.color}
				style={iconMapDefinition.style}
				{...props}
			/>
		);
	});
	IconImpl.displayName = createDisplayName(iconName);
	return IconImpl;
};
