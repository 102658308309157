import React, { MutableRefObject, useCallback, useRef } from 'react';
import { useLatestRef } from '../../../helpers/hooks/useLatestRef';
import { callAll, handleRefs } from '../../../helpers';
import { useElementIds } from '../../../helpers/hooks/useElementIds';
import { useLatestFormContext } from './useLatestFormContext';
import { useStateCallbacks } from './useStateCallbacks';
import { useEventHandlers } from './useEventHandlers';
import { ReadViewDefaultChildren } from '../ReadView';
import { useInlineEditReducer } from './useInlineEditReducer';
import { ActionButtonsProps, InlineEditInternalProps, InlineEditProps, InlineEditReducerState, InlineEditValue, UseInlineEditPropsData } from '../types';

const defaultValueToString = (value?: InlineEditValue): string => {
	return value?.toString() || ReadViewDefaultChildren;
};

export const useInlineEdit = ({ stateReducer, onClickOutside, ...props }: InlineEditProps): UseInlineEditPropsData => {
	const internalProps: InlineEditInternalProps = {
		valueToString: defaultValueToString,
		...props
	};
	const [internalState, dispatch] = useInlineEditReducer({ stateReducer, ...internalProps });
	const latest = useLatestRef<{ state: InlineEditReducerState; props: InlineEditInternalProps }>({ state: internalState, props: internalProps });
	const formContext = useLatestFormContext(latest);
	const elementIds = useElementIds({ prefix: 'inline-edit', ...props });
	const containerRef = useRef<HTMLElement | null>(null);
	const fieldRef = useRef<HTMLElement | null>(null);
	const readViewRef = useRef<HTMLButtonElement>();

	const stateCallbacks = useStateCallbacks({
		dispatch,
		latest,
		formContext
	});

	useEventHandlers({
		containerRef,
		fieldRef,
		latest,
		state: internalState,
		dispatch,
		formContext,
		onClickOutside
	});

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			stateCallbacks.setCurrentValue(e.target.value);
		},
		[stateCallbacks]
	);

	const getInlineEditProps = useCallback(
		({ ref = undefined, ...overrideProps } = {}) => {
			const { label, 'aria-label': ariaLabel, tooltip, helptext, required } = latest.current.props;
			const containerProps = {
				className: [
					'editable-group',
					typeof latest.current.props.groupClassName !== 'undefined' ? latest.current.props.groupClassName : 'mb-3',
					latest.current.props.inline && 'row gx-2'
				]
					.filter(Boolean)
					.join(' ')
			};
			const labelProps = {
				children: label,
				'aria-label': ariaLabel,
				column: latest.current.props.inline,
				tooltip,
				helptext,
				required,
				id: `${elementIds.id}_label`,
				htmlFor: elementIds.id
			};
			const helpBlockProps = {
				id: `${elementIds.id}-helpblock`,
				children: latest.current.props.helptext
			};
			return {
				ref: handleRefs(ref, (containerNode: HTMLElement) => {
					containerRef.current = containerNode;
				}),
				containerProps,
				labelProps,
				helpBlockProps,
				...overrideProps
			};
		},
		[latest, elementIds]
	);

	const getFieldProps = useCallback(
		({ ref = undefined, onChange = undefined, ...overrideProps } = {}) => {
			const {
				name,
				required,
				disabled,
				multiple,
				validators,
				label,
				'aria-label': ariaLabel,
				requiredFieldMessage,
				suppressInputValidationMessage
			} = latest.current.props;
			const { selectedValue: stateValue, dirty } = latest.current.state;

			return {
				ref: handleRefs(ref, (fieldNode: HTMLElement) => {
					fieldRef.current = fieldNode;
				}),
				id: elementIds.id,
				name,
				required,
				disabled: formContext.isDisabled || disabled,
				multiple,
				validators,
				dirty,
				requiredFieldMessage,
				suppressValidationMessage: suppressInputValidationMessage,
				errorLabel: label || ariaLabel,
				helptext: undefined,
				groupClassName: '', // removes bottom margin from FormGroup
				defaultValue: stateValue,
				onChange: callAll(onChange, handleChange),
				...overrideProps
			};
		},
		[latest, elementIds, handleChange, formContext]
	);

	const getReadViewProps = useCallback(
		({ onClick = undefined, ...overrideProps } = {}) => {
			const { disabled, readOnly, 'data-testid': testId } = latest.current.props;
			const { selectedValue: stateValue } = latest.current.state;

			return {
				ref: readViewRef as MutableRefObject<HTMLButtonElement>, // using callback refs via `handleRefs` causes overlays to be placed incorrect in relation to this target
				disabled: formContext.isDisabled || disabled || readOnly,
				'aria-labelledby': `${elementIds.id}_label`,
				'data-testid': testId ? `${testId}-read-view` : undefined,
				onClick: callAll(onClick, stateCallbacks.showEdit),
				children: latest.current.props.valueToString(stateValue),
				...overrideProps
			};
		},
		[elementIds, latest, formContext, stateCallbacks.showEdit]
	);

	const getActionButtonProps = useCallback(
		(overrideProps: ActionButtonsProps = {}): ActionButtonsProps => {
			return {
				onConfirm: event => {
					return callAll(latest.current.props.onConfirm, stateCallbacks.handleConfirm)(event, latest.current.state.currentValue);
				},
				onCancel: callAll(latest.current.props.onCancel, stateCallbacks.handleCancel),
				...overrideProps
			};
		},
		[latest, stateCallbacks]
	);

	return {
		isEdit: latest.current.state.isEdit,
		currentValue: latest.current.state.currentValue,
		selectedValue: latest.current.state.selectedValue,
		isInvalid: latest.current.state.isInvalid,
		getInlineEditProps,
		getFieldProps,
		getReadViewProps,
		getActionButtonProps,
		showEdit: stateCallbacks.showEdit,
		hideEdit: stateCallbacks.hideEdit,
		setValue: stateCallbacks.setValue,
		setCurrentValue: stateCallbacks.setCurrentValue
	};
};
