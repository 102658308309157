import { DrillDownInitialToggleLabel } from './DrillDownToggle';

export const defaultUseDrillDownProps = {
	items: [],
	multiple: false,
	isOpen: false,
	className: 'drilldown',
	show: false,
	initialToggleLabel: DrillDownInitialToggleLabel
};

export const sort = items => {
	return [...items].sort((first, second) => {
		const firstHasChildren = Array.isArray(first?.group);
		const secondHasChildren = Array.isArray(second?.group);
		if (!firstHasChildren && secondHasChildren) {
			return -1;
		} else if (firstHasChildren && !secondHasChildren) {
			return 1;
		}

		return 0;
	});
};

export const search = (value, items) => {
	return items.reduce((accumulator, item) => {
		if (Array.isArray(item?.group)) {
			const sortedItems = sort(item.group);
			const results = search(value, sortedItems);
			if (results.length) {
				const filteredItem = {
					...item,
					group: results
				};
				accumulator.push(filteredItem);
			}
		} else if (item.toString().toLowerCase().includes(value)) {
			accumulator.push(item);
		}
		return accumulator;
	}, []);
};
