import React from 'react';
import { NavigationContext } from './NavigationContext';
import { NavigationProviderProps } from './types';

export default function NavigationProvider({
	show,
	setShow,
	onSelect,
	activeKey,
	setActiveContent,
	...props
}: React.PropsWithChildren<NavigationProviderProps>): JSX.Element {
	return <NavigationContext.Provider value={{ show, setShow, onSelect, activeKey, setActiveContent }} {...props} />;
}

NavigationProvider.displayName = 'NavigationProvider';
