import React from 'react';
import { ColumnInstance } from 'react-table';
import { ConditionalElement } from '../../../types';
import { IconClose } from '@optic-delight/icons';
import { FilterContext } from '../../utility/FilterContext';
import { useTranslatableText } from '../../../translatable-text';

interface AppliedFiltersProps {
	columns?: Array<ColumnInstance>;
	filterLabelsMapping?: (columnId: string, value: unknown) => string | undefined;
}

export const defaultProps = {
	columns: []
};

export function AppliedFilters({ columns = defaultProps.columns, filterLabelsMapping }: AppliedFiltersProps): ConditionalElement {
	const { filterResultsLabel: label, clearAllText } = useTranslatableText();
	const filterContext = React.useContext(FilterContext);

	const clearFilter = (column: ColumnInstance) => {
		column.setFilter('');
		filterContext.onFilterClear?.([column]);
	};

	const clearAllFilters = () => {
		columns.forEach(column => column.setFilter(''));
		filterContext.onFilterClear?.(columns);
	};

	return columns.length ? (
		<div className="mb-2">
			<strong>{label}</strong>
			{columns.map((column, index) => {
				return (
					<button key={column.id || index} type="button" className="btn btn-default btn-sm ms-1" onClick={() => clearFilter(column)}>
						{filterLabelsMapping?.(column.id, column.filterValue) ?? column.filterValue}
						<IconClose className="ms-1" />
					</button>
				);
			})}
			<button type="button" className="btn btn-link ms-1" onClick={clearAllFilters}>
				{clearAllText}
			</button>
		</div>
	) : null;
}
