import * as React from 'react';
import FontIcon from './font-icon/FontIcon';
import FallbackIcon from './font-icon/FallbackIcon';
import { FontIconProps, IconName } from './font-icon/types';
import SpriteIcon from './sprite-icon/SpriteIcon';
import { SpriteIconName, SpriteIconProps } from './sprite-icon/types';
import { useIconMap } from './shared/hooks';
import { supportedThemes, Theme } from './shared';
import { resolveTag } from './tag-resolver';

export type IconProps = (Omit<FontIconProps, 'icon'> | Omit<SpriteIconProps, 'icon'>) &
	Pick<FontIconProps, 'theme'> & {
		icon: IconName | SpriteIconName;
	};

const Icon = React.forwardRef(
	({ icon: initialIcon, theme: initialTheme, tag, iconFallback = true, ...props }: IconProps, ref: React.ForwardedRef<HTMLElement>): JSX.Element | null => {
		let currentIcon: IconName | SpriteIconName = initialIcon;
		const tagMatchIcon = resolveTag(tag, initialTheme);
		if (tagMatchIcon) {
			currentIcon = tagMatchIcon;
		}

		const { icon, theme } = useIconMap(currentIcon, initialTheme);

		if (!currentIcon && iconFallback) {
			return <FallbackIcon ref={ref} data-testid="fallback-icon" />;
		} else if (theme === supportedThemes.fullColor) {
			return <SpriteIcon ref={ref} icon={icon as SpriteIconName} iconFallback={iconFallback} {...(props as Omit<SpriteIconProps, 'icon'>)} />;
		}

		return <FontIcon ref={ref} icon={icon as IconName} theme={theme as Theme} iconFallback={iconFallback} {...(props as Omit<FontIconProps, 'icon'>)} />;
	}
);

Icon.displayName = 'Icon';

export default Icon;
