import { UserInterfaceTypeResolverOptions, ResolverProcessor } from '../types';

export const listAdapter = ({ contract, level }: UserInterfaceTypeResolverOptions, { process }: ResolverProcessor) => {
	const mappedChildren = contract.children?.map(child => {
		if (child.type === 'list-item') return child;

		return {
			type: 'list-item',
			children: [child]
		};
	});

	return { children: process({ contract: mappedChildren, level: level++, parent: contract }) };
};
