// Federal Tax ID/EIN format - 9 digits in the form NN-NNNNNNN
export const EIN_REGEX_CHECK = /^(\d{2})-?(\d{7})$/;

// SIN format - 9 digits in the form NNN-NNN-NNN
export const SIN_REGEX_CHECK = /^(\d{3})-?(\d{3})-?(\d{3})$/;

// Medicare Beneficiary Identifier (MBI) - 11 character in the form XXXX-XXX-XXXX:
//   character 1 – numeric values 1 thru 9
//   character 2 – alphabetic values A thru Z (minus S, L, O, I, B, Z)
//   character 3 – alpha-numeric values 0 thru 9 and A thru Z (minus S, L, O, I, B, Z)
//   character 4 – numeric values 0 thru 9
//   character 5 – alphabetic values A thru Z (minus S, L, O, I, B, Z)
//   character 6 – alpha-numeric values 0 thru 9 and A thru Z (minus S, L, O, I, B, Z)
//   character 7 – numeric values 0 thru 9
//   character 8 – alphabetic values A thru Z (minus S, L, O, I, B, Z)
//   character 9 – alphabetic values A thru Z (minus S, L, O, I, B,Z)
//   character 10 – numeric values 0 thru 9
//   character 11 – numeric values 0 thru 9
const mbiDisallowedCharacters = '(?![sloibz_])';
const mbiAlphabetic = `${mbiDisallowedCharacters}[a-z]`;
const mbiAlphaNumeric = `${mbiDisallowedCharacters}\\w`;
export const MBI_REGEX_CHECK = {
	strict: new RegExp(`^[1-9]${mbiAlphabetic}${mbiAlphaNumeric}\\d-?${mbiAlphabetic}${mbiAlphaNumeric}\\d-?${mbiAlphabetic}${mbiAlphabetic}\\d\\d$`, 'i'),
	loose: /^(\w{4})-?(\w{3})-?(\w{4})$/,
	repeated: /^([0-9])([A-Z])\1{2}[-]?\1{2}[-]?\1{4}$/
};
