import { buildCustomerFromDatasetData, buildRoleFromMemberRoleData, CustomerProps, MemberRolesProps } from '../types';

export const UNKNOWN = -1;
/**
 *
 * defines what a "customer" should look like for a react application.
 * `customer` allows for other keys, however core functionality should be explicitly called out here
 *
 * @param bscAdmin
 * @param coNum
 * @param rest
 * @returns {{roles: {}, csrf: string, coNum: (number), mmNum: (number), bscAdmin: (boolean)}}
 */
export const buildCustomerFromDataset = ({ bscAdmin, coNum, ...rest }: Omit<CustomerProps, 'roles'>): buildCustomerFromDatasetData => {
	return {
		bscAdmin: typeof bscAdmin === 'boolean' ? bscAdmin : 't' === bscAdmin,
		// eslint-disable-next-line eqeqeq
		coNum: parseInt(coNum) == (coNum as unknown as number) ? parseInt(coNum) : UNKNOWN,
		mmNum: UNKNOWN,
		csrf: '',
		roles: {},
		...rest
	};
};

/**
 * Takes a MemberRole object and maps it to a new object
 *
 * @param arId
 * @param asId
 * @returns {{asId: *, arId: *}}
 */
export const buildRoleFromMemberRole = ({ ar_id: arId = '', as_id: asId = '' }: MemberRolesProps): buildRoleFromMemberRoleData => {
	return {
		arId,
		asId
	};
};
