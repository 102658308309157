import React from 'react';
import { ColumnInstance } from 'react-table';
import { iconPrefix, supportedThemes } from '@optic-delight/icons';

interface IconSortableProps {
	column: ColumnInstance;
}

export const sortable = {
	unsorted: {
		ariaLabel: 'unsorted',
		sortIcon: 'sort',
		color: 'text-black-50'
	},
	descending: {
		ariaLabel: 'sort descending',
		sortIcon: 'sort-down',
		color: 'text-black'
	},
	ascending: {
		ariaLabel: 'sorted ascending',
		sortIcon: 'sort-up',
		color: 'text-black'
	}
};

export function IconSortable({ column }: IconSortableProps): JSX.Element {
	let options = sortable.unsorted;
	if (column.isSortedDesc) {
		options = sortable.descending;
	} else if (column.isSorted) {
		options = sortable.ascending;
	}

	// needs BSC-61525 to be worked before @optic-delight/icons component(s) can be used here
	return <i aria-label={options.ariaLabel} className={`${iconPrefix}-${supportedThemes.solid} ${iconPrefix}-${options.sortIcon} ${options.color} ms-1`} />;
}
