import React from 'react';
import { ModalSuccessProps } from './types';

import Modal from './Modal';
import ModalTitle from './ModalTitle';
import ModalDescription from './ModalDescription';

import ModalSuccessHeader from './ModalSuccessHeader';
import ModalSuccessBody from './ModalSuccessBody';
import ModalSuccessFooter from './ModalSuccessFooter';

const defaultContinueLabel = 'Continue';
const defaultCloseLabel = 'Close';

const ModalSuccess = ({
	continueLabel = defaultContinueLabel,
	closeLabel = defaultCloseLabel,
	onHide,
	onContinue,
	children,
	icon,
	...props
}: ModalSuccessProps): JSX.Element => {
	const footers = React.Children.map(children, child => {
		if (child.type?.name === ModalSuccessFooter.name) {
			return child;
		}
	});
	return (
		<Modal icon={icon} onContinue={onContinue} type="success" continueLabel={continueLabel} onHide={onHide} fullscreen={true} {...props}>
			<Modal.Body>
				<div className="modal-confirmation">
					{children}
					{footers?.length < 1 && <ModalSuccessFooter>{footers}</ModalSuccessFooter>}
				</div>
			</Modal.Body>
		</Modal>
	);
};

ModalSuccess.displayName = 'ModalSuccess';
ModalSuccess.Header = ModalSuccessHeader;
ModalSuccess.Title = ModalTitle;
ModalSuccess.Description = ModalDescription;
ModalSuccess.Body = ModalSuccessBody;
ModalSuccess.Footer = ModalSuccessFooter;

ModalSuccess.actionLabel = defaultContinueLabel;
ModalSuccess.closeLabel = defaultCloseLabel;

export default ModalSuccess;
