import React from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import { FormControl, InputGroup } from 'react-bootstrap';
import { IconClose, IconSearch } from '@optic-delight/icons';
import { useTranslatableText } from '../../../translatable-text';
import { typingDebounceRate } from '../../utility/constants';

/**
 * - Searching each table column is enabled by default
 * - Full react-table documentation can be found on their github page [useGlobalFilter api](https://github.com/tannerlinsley/react-table/blob/v7.0.4/docs/api/useGlobalFilter.md)
 *
 */
export default function GlobalFilter({ globalFilter, setGlobalFilter, 'aria-label': ariaLabel = 'Search Table', ...props }) {
	const [value, setValue] = React.useState(globalFilter);
	const { globalFilterPlaceholder: placeholder } = useTranslatableText();
	const onChange = useAsyncDebounce(filterValue => {
		setGlobalFilter(filterValue || undefined);
	}, typingDebounceRate);

	const handleChange = newValue => {
		setValue(newValue);
		onChange(newValue?.trim());
	};

	React.useEffect(() => {
		setValue(globalFilter);
	}, [globalFilter]);

	return (
		<InputGroup className="input-group-embedded">
			<InputGroup.Text>
				<IconSearch />
			</InputGroup.Text>
			<FormControl
				type="search"
				aria-label={ariaLabel}
				placeholder={placeholder}
				value={value || ''}
				onChange={e => handleChange(e.target.value)}
				{...props}
			/>
			<InputGroup.Text as="button" type="button" aria-label="clear" onClick={() => handleChange('')}>
				<IconClose />
			</InputGroup.Text>
		</InputGroup>
	);
}

GlobalFilter.propTypes = {
	/**
	 * The resolved function from this string/function will be used to filter the table's data.
	 * - If a string is passed, the function with that name located on either the custom filterTypes option or the built-in filtering types object will be used.
	 * - If a function is passed, it will be used directly.
	 */
	globalFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),

	/**
	 * An instance-level function used to update the global filter value
	 */
	setGlobalFilter: PropTypes.func,

	'aria-label': PropTypes.string,

	disabled: PropTypes.bool
};

GlobalFilter.defaultProps = {
	'aria-label': 'Search Table',
	globalFilter: ''
};
