import { RowSizeValue, GridGenerateClassName, ColSizeValue, GridSizes } from '../types';

export const sizes: Array<keyof GridSizes<RowSizeValue> & keyof GridSizes<ColSizeValue>> = ['lg', 'md', 'sm', 'xl', 'xs', 'xxl'];
export const prefixCol = 'col';
export const prefixRow = 'row';

const isValidValue = (val?: ColSizeValue) => {
	if (typeof val === 'object') {
		return (
			(typeof val?.span === 'number' && val?.span <= 12 && val?.span > 0) ||
			val?.span === true ||
			val?.span === 'auto' ||
			(typeof val?.offset === 'number' && val?.offset <= 12 && val?.offset > 0) ||
			(typeof val?.order === 'number' && val?.order <= 12 && val?.order > 0) ||
			val?.order === 'last' ||
			val?.order === 'first'
		);
	}
	return (typeof val === 'number' && val <= 12 && val > 0) || val === 'auto' || val === true;
};
// Function for generate className for Col/Row
export const useGrid = ({ prefix, gridSizes }: GridGenerateClassName) => {
	const filteredSizes = sizes.filter(size => isValidValue(gridSizes[size]));
	const rowClassName = filteredSizes.length
		? filteredSizes.map(item => `${prefix}${item !== 'xs' ? '-' + item : ''}-${gridSizes[item]}`).join(' ')
		: undefined;
	const colClassName = () => {
		if (filteredSizes.length) {
			const spans: string[] = [];
			const classes: string[] = [];
			filteredSizes.forEach(item => {
				const propValue = gridSizes[item];
				delete gridSizes[item];
				let span;
				let offset;
				let order;
				if (typeof propValue === 'object' && propValue != null) {
					({ span, offset, order } = propValue);
				} else {
					span = propValue;
				}

				const infix = item !== 'xs' ? `-${item}` : '';
				span && spans.push(span === true ? `${prefix}${infix}` : `${prefix}${infix}-${span}`);
				order && classes.push(`order${infix}-${order}`);
				typeof offset !== 'undefined' && classes.push(`offset${infix}-${offset}`);
			});
			return [spans.length && spans.join(' '), classes.length && classes.join(' ')].filter(Boolean).join(spans.length && classes.length ? ' ' : '');
		}
		return undefined;
	};
	return { rowClassName, colClassName: colClassName() };
};
