import { UserInterfaceTypeResolver } from '../types';
import { gridAdapter, widgetAdapter, promoAdapter, listAdapter } from '../adapter';
import { Button, Col, ListGroup, Row, Widget } from '../../components';
import { childrenOrTitleAdapter } from '../adapter/children-or-title';
import PromoWidget from '../../components/widget/PromoWidget';
import { listWithTitleResolver } from './list-resolver';

export const componentResolver: UserInterfaceTypeResolver = (options, processor) => {
	const { contract } = options;

	switch (contract.type) {
		case 'row':
			return [Row, gridAdapter(contract)];
		case 'column':
			return [Col, gridAdapter(contract)];
		case 'button':
			return [
				Button,
				{
					icon: contract.icon,
					href: contract.link,
					variant: contract.metadata?.variant,
					children: childrenOrTitleAdapter(options, processor)
				}
			];
		case 'widget':
			return [Widget, widgetAdapter(options, processor)];
		case 'widget-promo':
			return [PromoWidget, promoAdapter(options, processor)];
		case 'widget-header':
			return [Widget.Header, { children: childrenOrTitleAdapter(options, processor) }];
		case 'widget-body':
			return [Widget.Body];
		case 'widget-footer':
			return [Widget.Footer];
		case 'list':
			if (contract.title) {
				return listWithTitleResolver(options, processor);
			}
			return [ListGroup, listAdapter(options, processor)];
		case 'list-item':
			return [ListGroup.Item, { className: 'px-0 py-1 border-0' }];
	}
	return [];
};
