import React from 'react';
import { NavigationDropdownToggleProps } from '../types';

export const NavigationDropdownToggle = React.forwardRef<HTMLAnchorElement, NavigationDropdownToggleProps>(
	({ children, className, 'aria-expanded': ariaExpanded, ...props }, ref) => {
		const classes = [className, 'nav-link', ariaExpanded && 'show'].filter(Boolean).join(' ');

		return (
			<a ref={ref} tabIndex={0} role="button" className={classes} aria-expanded={ariaExpanded} {...props}>
				{children}
			</a>
		);
	}
);
NavigationDropdownToggle.displayName = 'NavigationDropdownToggle';
