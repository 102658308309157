import React from 'react';

import Card from './Card';
import { CardElementProps } from './types';

const CalloutCardHeader = ({ ...props }: CardElementProps): JSX.Element => <Card.Title {...props} />;

CalloutCardHeader.displayName = 'CalloutCardHeader';

export default CalloutCardHeader;
