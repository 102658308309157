import React from 'react';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';

export default function Layout(): JSX.Element {
	return (
		<div className="d-flex flex-column min-vh-100">
			<div className="flex-grow-1">
				<a className="visually-hidden-focusable w-100 p-2 bg-white" href="#main-content">
					Skip to Main Content
				</a>
				<Header />
				<div id="main-content" />
				<Body />
			</div>
			<Footer />
		</div>
	);
}
