import React from 'react';
import Button from './Button';
import { ButtonProps } from './types';

const CustomizedButtonWrapper = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ 'aria-label': ariaLabel, children, ...props }, ref): JSX.Element => (
		<Button aria-label={ariaLabel} ref={ref} {...props}>
			{!ariaLabel && children}
		</Button>
	)
);

CustomizedButtonWrapper.displayName = 'CustomizedButtonWrapper';

export default CustomizedButtonWrapper;
