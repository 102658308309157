import React from 'react';
import { isValueUnset, stringify } from '../../../helpers';

export const isNotNullObject = obj => {
	return obj && typeof obj === 'object';
};

// flattens combobox items into a single level array
export const flattenItems = (items = [], group = undefined) => {
	return items.reduce((accumulator, item) => {
		if (isNotNullObject(item)) {
			if (item.group) {
				accumulator.push(...flattenItems(item.group, item.content));
			} else {
				const itemCopy = { ...item };
				if (group) {
					itemCopy['group'] = group;
				}
				accumulator.push(itemCopy);
			}
		} else if (typeof item === 'string' || typeof item === 'number') {
			accumulator.push(item.toString());
		}
		return accumulator;
	}, []);
};

// converts combobox items into a standardized format
export const normalizeItems = (items = []) => {
	return items.reduce((accumulator, item) => {
		if (item) {
			if (Object.prototype.hasOwnProperty.call(item, 'group')) {
				// opt group - recursively normalize items within the group
				if (Array.isArray(item.group)) {
					accumulator.push({
						content: item.content || '',
						group: normalizeItems(item.group)
					});
				}
			} else if (item.content || item.value) {
				const content = item.content || item.value || '';
				if (content) {
					accumulator.push({
						content,
						value: stringify(item.value ?? content)
					});
				}
			} else if (typeof item === 'string' || typeof item === 'number' || React.isValidElement(item)) {
				// string item - set text and value to the string
				accumulator.push({
					content: item,
					value: stringify(item)
				});
			}
		}

		return accumulator;
	}, []);
};

export const comparatorTypes = {
	exact: 'exact',
	inclusive: 'include',
	exclusive: 'exclude',
	startsWith: 'startsWith',
	notStartsWith: 'notStartsWith'
};

export const compare = (compareType, a, b) => {
	switch (compareType) {
		case comparatorTypes.exact:
			return a.toLowerCase() === b.toLowerCase();
		case comparatorTypes.exclusive:
			return !a.toLowerCase().includes(b.toLowerCase());
		case comparatorTypes.inclusive:
			return a.toLowerCase().includes(b.toLowerCase());
		case comparatorTypes.notStartsWith:
			return !a.toLowerCase().startsWith(b.toLowerCase());
		default:
			return a.toLowerCase().startsWith(b.toLowerCase());
	}
};

// gets combobox items by string value
export const getItemsByContent = (items = [], value = '', filterType = comparatorTypes.startsWith) => {
	if (value === '') {
		return items;
	}

	return items.reduce((accumulator, item) => {
		if (isNotNullObject(item)) {
			// account for normalized object array
			if (item.group) {
				const reducedGroupItems = getItemsByContent(item.group, value);
				if (reducedGroupItems.length > 0) {
					accumulator.push({
						...item,
						group: reducedGroupItems
					});
				}
			} else if (compare(filterType, stringify(item.content), value.toString())) {
				accumulator.push(item);
			}
		} else if ((typeof item === 'string' || typeof item === 'number') && compare(filterType, item.toString(), value.toString())) {
			// account for simple string or number array
			accumulator.push(item);
		}

		return accumulator;
	}, []);
};

export const itemToString = (item, property = 'content') => {
	return isNotNullObject(item) ? stringify(item[property]) : item?.toString() || '';
};

export const toDelimitedListValidation = (validators, delimiter = '|') => {
	return validators.map(validator => {
		const originalValidateFn = validator.validate;
		return {
			...validator,
			validate: value => {
				return (
					isValueUnset(value) ||
					value.split(delimiter).reduce((accumulator, aValue) => {
						return accumulator && originalValidateFn(aValue);
					}, true)
				);
			}
		};
	});
};
