import { ResolverProcessor, UserInterfaceContract, UserInterfaceTypeResolverOptions } from '../types';

export const widgetAdapter = ({ contract, level }: UserInterfaceTypeResolverOptions, { process }: ResolverProcessor) => {
	const kids = contract?.children || [];
	const header = kids.find(child => child.type === 'widget-header');
	const footer = kids.find(child => child.type === 'widget-footer');
	const body = kids.find(child => child.type === 'widget-body');
	const other = kids.filter(child => child !== header && child !== body && child !== footer);

	const children = [
		contract.title && { type: 'widget-header', title: contract.title },
		!contract.title && header,
		(footer || body || other) && {
			type: 'widget-body',
			children: [contract.description && { type: 'text', title: contract.description }, ...(body?.children || []), ...other, footer].filter(Boolean)
		}
	].filter(Boolean) as UserInterfaceContract[];

	return { children: process({ contract: children, level: level++, parent: contract }) };
};

export const promoAdapter = (options: UserInterfaceTypeResolverOptions, processor: ResolverProcessor) => {
	const { contract, level } = options;

	return {
		media: processor.process({ contract: { type: 'media', source: contract.source }, level: level + 1, parent: contract }),
		...widgetAdapter(options, processor)
	};
};
