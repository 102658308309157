import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

export default function SelectFilter({ column: { filterValue, setFilter, preFilteredRows = [], id, Header }, disabled, readOnly }) {
	/*
	 * Calculate the options for filtering
	 * using the preFilteredRows
	 */
	const options = React.useMemo(() => {
		const optionSet = new Set();
		preFilteredRows.forEach(row => {
			optionSet.add(row.values[id]);
		});
		return [...optionSet.values()].sort();
	}, [id, preFilteredRows]);

	return (
		<FormControl
			aria-label={'Filter ' + Header}
			as="select"
			value={filterValue}
			onChange={e => {
				setFilter(e.target.value || undefined);
			}}
			bsPrefix="form-control mb-0"
			disabled={disabled}
			readOnly={readOnly}>
			<option value="">All</option>
			{options.map((option, i) => (
				<option key={i} value={option}>
					{option}
				</option>
			))}
		</FormControl>
	);
}

SelectFilter.propTypes = {
	/**
	 * column definition
	 */
	column: PropTypes.any,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool
};
