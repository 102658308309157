import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { LiveRegionProps } from '../types';

const LiveRegion = ({ title }: LiveRegionProps) => {
	const [status, setStatus] = useState('active');

	useEffect(() => {
		const statusTimeout = setTimeout(() => setStatus('inactive'), 2000);
		return () => {
			clearTimeout(statusTimeout);
		};
	}, []);

	return status === 'active'
		? ReactDOM.createPortal(
				<span role="status" aria-live="polite" className="visually-hidden">
					navigated to {title}
				</span>,
				document.body
		  )
		: null;
};

LiveRegion.displayName = 'LiveRegion';

export default LiveRegion;
