import React, { CSSProperties, MutableRefObject } from 'react';

import { useCombinedRefs } from '../../helpers';
import { useElementIds } from '../../helpers/hooks/useElementIds';
import { directionToLogical } from '../tooltip/TooltipPortal';

import {
	arrow,
	offset,
	flip,
	shift,
	autoUpdate,
	useFloating,
	useInteractions,
	useRole,
	useDismiss,
	useClick,
	FloatingFocusManager,
	autoPlacement,
	Alignment
} from '@floating-ui/react';

import { PopoverProps } from './types';

import PopoverBody from './PopoverBody';
import PopoverHeader from './PopoverHeader';
import { Placement } from '../tooltip';

const Popover = ({
	as: Component = 'div',
	arrowProps = {},
	body = false,
	className,
	children,
	show = false,
	toggle,
	id,
	placement: defaultPlacement = 'top',
	...props
}: PopoverProps) => {
	const [open, setOpen] = React.useState(show);
	const [transition, setTransition] = React.useState(show);
	const mountedRef = React.useRef<boolean>(false);
	const timeoutRef = React.useRef<NodeJS.Timeout>(null) as MutableRefObject<NodeJS.Timeout>;
	const isAutoPlacement = defaultPlacement.startsWith('auto');

	React.useEffect(() => {
		if (!mountedRef.current) {
			mountedRef.current = true;
		}

		// clear timeout when component gets unmounted
		return () => {
			mountedRef.current = false;
			clearTimeout(timeoutRef.current);
		};
	}, []);

	const { ref: arrowRef, style: initialArrowStyle, className: arrowClassName, ...internalArrowProps } = arrowProps;
	const internalArrowRef = useCombinedRefs(React.useRef<HTMLDivElement>(null), arrowRef) as unknown as MutableRefObject<HTMLDivElement>;

	const elementIds = useElementIds({ prefix: 'popover', id });

	const { refs, floatingStyles, placement, context, middlewareData } = useFloating({
		open,
		onOpenChange: opened => {
			// css transitions are visible because of the timeouts below
			if (opened) {
				setOpen(true);
				timeoutRef.current = setTimeout(() => {
					if (mountedRef.current) {
						setTransition(true);
					}
				}, 50);
			} else {
				setTransition(false);
				timeoutRef.current = setTimeout(() => {
					if (mountedRef.current) {
						setOpen(false);
					}
				}, 100);
			}
		},
		placement: isAutoPlacement ? undefined : (defaultPlacement as Placement),
		// The order of middleware is important
		middleware: [
			offset(7),
			isAutoPlacement ? autoPlacement({ alignment: (defaultPlacement.replace('auto-', '') as Alignment) || undefined }) : flip(),
			shift(),
			arrow({
				element: internalArrowRef
			})
		],
		whileElementsMounted: autoUpdate
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([useClick(context), useRole(context), useDismiss(context)]);

	const side = directionToLogical(placement.split('-')[0]);
	const classes = [className, 'popover fade', transition && 'show', `bs-popover-${side}`].filter(Boolean).join(' ');

	const arrowStyle: CSSProperties = initialArrowStyle || { position: 'absolute' };
	if (middlewareData.arrow) {
		if (middlewareData.arrow.x) {
			arrowStyle['left'] = middlewareData.arrow.x;
		}
		if (middlewareData.arrow.y && !isAutoPlacement) {
			arrowStyle['top'] = middlewareData.arrow.y;
		}
	}

	return (
		<>
			{React.cloneElement(toggle, getReferenceProps({ ref: refs.setReference, 'aria-controls': `popover-toggle-${id}`, ...toggle.props }))}
			{open && (
				<FloatingFocusManager context={context} modal={false} returnFocus={false}>
					<Component
						ref={refs.setFloating}
						{...getFloatingProps({
							className: classes,
							id: elementIds.id,
							style: floatingStyles,
							...props
						})}>
						<div
							className={[arrowClassName, 'popover-arrow'].filter(Boolean).join(' ')}
							style={arrowStyle}
							ref={internalArrowRef}
							{...internalArrowProps}
						/>
						{body ? <PopoverBody>{children}</PopoverBody> : children}
					</Component>
				</FloatingFocusManager>
			)}
		</>
	);
};

Popover.displayName = 'Popover';
Popover.Body = PopoverBody;
Popover.Header = PopoverHeader;

export default Popover;
