import { iconMap, Theme, IconName, MappingIconName } from './iconMapping';

export function useIconMap(iconName: IconName, theme: Theme | undefined): Record<string, unknown> {
	if (Object.prototype.hasOwnProperty.call(iconMap, iconName)) {
		const mapping = iconMap[iconName as MappingIconName];
		return {
			icon: mapping.iconName,
			theme: theme || mapping.theme
		};
	}

	return {
		icon: iconName,
		theme: theme
	};
}
