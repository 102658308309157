import React, { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Fieldset from '../../utility/Fieldset';
import { applyEventListener, isInputChecked, noop } from '../../../helpers';
import { useCheckGroupValidation } from './hooks/useCheckGroupValidation';
import { CheckGroupContextProps, CheckGroupProps } from '../types';
import { useFormContext } from '../form-element';
import { useLatestRef } from '../../../helpers/hooks/useLatestRef';

const defaults = {
	isGroup: () => false,
	onGroupChange: noop
};

export const CheckGroupContext = React.createContext<CheckGroupContextProps>({
	...defaults
});
CheckGroupContext.displayName = 'CheckGroupContext';

export const checkGroupDefaultProps = {
	dirty: false,
	button: undefined,
	required: undefined
};

const CheckGroup = ({
	dirty = checkGroupDefaultProps.dirty,
	button = checkGroupDefaultProps.button,
	required = checkGroupDefaultProps.required,
	size,
	...props
}: CheckGroupProps): JSX.Element => {
	const { formState } = useFormContext();
	const [activeSwitchItems, setActiveSwitchItems] = useState<(string | number)[]>([]);
	const latest = useLatestRef({ props });
	const [isInvalid, setIsInvalid] = useCheckGroupValidation({
		dirty,
		children: props.children,
		name: props.name,
		formState,
		required
	});

	const handleGroupChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsInvalid(required && !isInputChecked(e.target));
		if (props.switch) {
			const active = [];
			if (e.target.type === 'radio') {
				document.getElementsByName(e.target.name).forEach(element => {
					const input = element as HTMLInputElement;
					if (input.checked) {
						active.push(input.value);
					}
				});
			}
			if (e.target.checked) {
				active.push(e.target.value);
			}
			setActiveSwitchItems(active as []);
		}
		applyEventListener(props.onChange, [e]);
	};

	React.useEffect(() => {
		if (latest.current.props.switch) {
			const childrenArray = React.Children.toArray(latest.current.props.children);
			const reactElementChildren = childrenArray.filter(child => typeof child !== 'number' && typeof child !== 'string') as React.ReactElement[];
			const checkedChildren = reactElementChildren.filter(child => child.props && (child.props.checked || child.props.defaultChecked));
			const checkedValues = checkedChildren.map(child => child.props.value) as (string | number)[];
			setActiveSwitchItems(checkedValues);
		}
	}, [latest, setActiveSwitchItems]);

	const { Provider } = CheckGroupContext;
	const buttonGroupClassName = props.switch ? 'btn-group-toggle-state' : undefined;

	return (
		<Provider
			value={{
				button: props.switch || button,
				required,
				disabled: formState?.isDisabled || props.disabled,
				inline: props.inline,
				name: props.name,
				dirty,
				isGroup: () => true,
				onGroupChange: handleGroupChange,
				activeSwitchItems,
				size
			}}>
			<Fieldset id={props.id} label={props.label} tooltip={props.tooltip} helptext={props.helptext} required={required} isInvalid={isInvalid}>
				{props.switch || button ? (
					<ButtonGroup size={size} className={buttonGroupClassName}>
						{props.children}
					</ButtonGroup>
				) : (
					props.children
				)}
			</Fieldset>
		</Provider>
	);
};
CheckGroup.displayName = 'CheckGroup';

export default CheckGroup;
