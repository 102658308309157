import React from 'react';
import { CellProps, ColumnInterface, UseRowSelectInstanceProps } from 'react-table';
import { IndeterminateCheckbox } from '../IndeterminateCheckbox';
import { Collection } from '../../../types';
import { UseRowSelectionProps } from '../types';

export const rowSelectionClass = 'table-col-check';
export const useRowSelectionColumn = (props: Partial<UseRowSelectionProps> = {}): Pick<ColumnInterface, 'Header' & 'Footer' & 'id' & 'className'> => {
	const { id = 'selection', getHeaderProps, getCellProps } = props || {};
	const HeaderCheckbox = (instance: UseRowSelectInstanceProps<Collection>) => {
		// explicit removal of the title, as we are setting aria-label instead
		const { isAllPageRowsSelected, getToggleAllPageRowsSelectedProps } = instance;
		return (
			<IndeterminateCheckbox
				{...getToggleAllPageRowsSelectedProps({
					...getHeaderProps?.(instance)
				})}
				aria-label={`Toggle all current page rows ${isAllPageRowsSelected ? 'unselected' : 'selected'}`}
				title={undefined}
			/>
		);
	};
	HeaderCheckbox.displayName = 'HeaderCheckbox';

	const CellCheckbox = (cellProps: CellProps<Collection>) => {
		const { row } = cellProps;
		// explicit removal of the title, as we are setting aria-label instead
		return (
			<IndeterminateCheckbox
				{...row.getToggleRowSelectedProps({
					...getCellProps?.(cellProps)
				})}
				aria-label={`Toggle row ${row.index + 1} ${row.isSelected ? 'unselected' : 'selected'}`}
				title={undefined}
			/>
		);
	};

	return {
		id,
		Header: HeaderCheckbox,
		Cell: CellCheckbox,
		className: rowSelectionClass
	};
};
