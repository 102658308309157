import React from 'react';
import { Pagination } from 'react-bootstrap';
import { PaginationArray, usePagination } from './usePagination';

interface PaginationItemsProps {
	pageIndex: number;
	pageCount: number;
	onClick: (pageIndex: number) => void;
	canPreviousPage?: () => boolean;
	canNextPage?: () => boolean;
}

export function PaginationItems({ pageIndex, pageCount, onClick, canPreviousPage, canNextPage }: PaginationItemsProps): React.ReactNode {
	const pages: PaginationArray = usePagination(pageIndex + 1, pageCount);
	return pages.map((page, index) => {
		if (page === '...') {
			return <Pagination.Ellipsis key={index} disabled />;
		} else {
			const usePaginationIndex = page - 1;
			const isActive = pageIndex === usePaginationIndex;
			let isDisabled = false;
			if (usePaginationIndex < pageIndex) {
				isDisabled = !canPreviousPage;
			} else if (usePaginationIndex > pageIndex) {
				isDisabled = !canNextPage;
			}

			return (
				<Pagination.Item key={index} onClick={() => onClick(page - 1)} active={isActive} disabled={isDisabled}>
					{page}
				</Pagination.Item>
			);
		}
	});
}
