import * as React from 'react';
import { FieldValues, useFormContext as useReactHookFormContext } from 'react-hook-form';
import { FormContextBridge, FormState, UseFormReturn } from '../types';
import { FormContext } from '../Form';

export const useFormContext = <TFieldValues extends FieldValues>(): UseFormReturn<TFieldValues> => {
	const formContext = React.useContext(FormContext) as FormContextBridge;
	const reactHookFormContext = useReactHookFormContext<TFieldValues>() || {};

	if (reactHookFormContext.formState) {
		(reactHookFormContext.formState as FormState<TFieldValues>)['isDisabled'] = formContext?.isDisabled ?? false;
	}

	return reactHookFormContext as UseFormReturn<TFieldValues>;
};
