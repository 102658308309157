import React from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';
import ComboboxInput from './ComboboxInput';
import { getItemsByContent, itemToString, comparatorTypes, normalizeItems } from './utility';
import useFormGroupValidation from '../shared/hooks/useFormGroupValidation';
import FormGroupValidation from '../shared/FormGroupValidation';
import { inputDefaultProps, inputPropTypes } from '../../../props/inputProps';
import { useSearchableItemsList } from '../select/hooks';
import OptionsList from '../select/OptionsList';
import { getSelectMenuHeight, isInHiddenGroup } from '../select/utility';
import { isValueUnset, noop } from '../../../helpers';
import { useControlledFocusOnError } from '../shared/hooks/useControlledFocusOnError';
import { useFlippedDropdownMenu } from '../../../helpers/hooks/useFlippedDropdownMenu';

const comboboxItemToListOption = item => {
	return {
		label: String(item.content),
		value: item.value,
		items: item.group?.map(child => comboboxItemToListOption(child))
	};
};

/**
 * Bootstrap UI wrapper for a `downshift-js` combobox
 *
 * It's important that we're using React.useMemo in the examples to ensure that our data isn't recreated on every render.
 * If we didn't use React.useMemo, the combobox would think it was receiving new data on every render and attempt
 * to recalculate a lot of logic every single time. Not cool!
 *
 * Documentation: https://www.downshift-js.com/use-combobox
 */
const Combobox = React.forwardRef((allProps, ref) => {
	const {
		loading = false,
		items,
		initialValue,
		selectedItem,
		onSelectedItemChange,
		strictMatch,
		compareType = comparatorTypes.startsWith,
		textTruncation = true,
		...props
	} = allProps;
	const isControlled =
		Object.prototype.hasOwnProperty.call(allProps, 'selectedItem') && Object.prototype.hasOwnProperty.call(allProps, 'onSelectedItemChange');
	const initialRender = React.useRef(true);
	const [selectedItems, setSelectedItems] = React.useState(() => {
		if (selectedItem || initialValue) {
			const item = getItemsByContent(items, selectedItem || initialValue, compareType);
			return item || [];
		}
		return [];
	});

	const { getGroupProps, getLabelProps, getInputProps, getHelpblockProps, validation, inputRef } = useFormGroupValidation({
		ref,
		...props
	});
	const { setValue = noop } = validation.formContext;
	const normalizedItems = React.useMemo(() => {
		return normalizeItems(items).map(item => comboboxItemToListOption(item));
	}, [items]);

	const { filteredItems, searchValue, setSearchValue, hiddenGroups, collapseGroup } = useSearchableItemsList({
		items: normalizedItems,
		value: selectedItem || initialValue || '',
		compareType
	});
	const filteredItemsToDisplay = filteredItems.filter(item => !isInHiddenGroup(item, hiddenGroups));

	const { id: labelId, ...labelProps } = getLabelProps();
	const { id: inputId, name, disabled: toggleBtnDisabled = undefined, ...inputProps } = getInputProps();

	// downshift setup
	const useComboboxOptions = useCombobox({
		inputId: inputId,
		labelId: labelId,
		items: filteredItemsToDisplay,
		initialInputValue: initialValue || '',
		[isControlled ? 'selectedItem' : 'initialSelectedItem']: selectedItems[0] || '',
		initialHighlightedIndex: items.findIndex(item => itemToString(item, 'value') === itemToString(selectedItems[0], 'value')),
		itemToString,
		stateReducer: (state, actionAndChanges) => {
			const { changes, type } = actionAndChanges;

			const isGroup = changes.selectedItem?.isGroup || false;

			switch (type) {
				case useCombobox.stateChangeTypes.InputBlur:
					if (strictMatch) {
						// resets the visible input value to the previously selected item content.
						const previouslySelectedItem = selectedItems[0];
						return {
							...changes,
							inputValue: itemToString(previouslySelectedItem, 'label'),
							selectedItem: previouslySelectedItem
						};
					} else {
						setValue(changes.inputValue);
						return {
							...changes,
							selectedItem: changes.inputValue
						};
					}

				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick:
					if (isGroup) {
						collapseGroup(changes.selectedItem);
						return {
							...changes,
							isOpen: true,
							highlightedIndex: state.highlightedIndex
						};
					}

					// sets the visible input value to the selected item content.
					return {
						...changes,
						inputValue: itemToString(changes.selectedItem, 'label')
					};

				default:
					return changes;
			}
		},
		onInputValueChange: ({ inputValue }) => {
			// reduce the visible list of items to those that match the input value
			setSearchValue(inputValue);
		},
		onSelectedItemChange: ({ selectedItem }) => {
			if (!selectedItem) {
				return;
			}

			const value = selectedItem?.value ?? selectedItem;

			validation.setIsInvalid(validation.isRequired && isValueUnset(value));
			setSelectedItems([value]);
			onSelectedItemChange?.([value]);
		}
	});

	const dropdownMenuProps = useComboboxOptions.getMenuProps({
		style: {
			height: getSelectMenuHeight(filteredItemsToDisplay.length)
		}
	});

	// useCombobox could have generated an id if one was not provided, use actualId from here on out
	const { reset } = useComboboxOptions;
	const { id } = useComboboxOptions.getInputProps();
	const clearFilteredItems = () => {
		setSearchValue('');
	};

	// sets the hidden input value whenever the selected item changes
	React.useEffect(() => {
		if (!toggleBtnDisabled) {
			setValue(name, selectedItems.map(selectedItem => itemToString(selectedItem, 'value')).join('|'));
		}
	}, [selectedItems, name, setValue, toggleBtnDisabled]);

	/*
	 * reset the listbox items and selected item when items prop changes.
	 * this will reset downshift and trigger a useEffect which calls setValue() for react-hook-form
	 */
	React.useEffect(() => {
		// skip running on initial render, so initially selected items work properly
		if (initialRender.current) {
			initialRender.current = false;
			return;
		}

		setSearchValue('');
		setSelectedItems([]);
		reset();
	}, [setSearchValue, items, reset]);

	React.useEffect(() => {
		if (isControlled) {
			const currentItems = selectedItem ? getItemsByContent(items, selectedItem, compareType) : [];
			setSelectedItems(prevItems => {
				const prevItemValues = prevItems.map(item => itemToString(item, 'value'));
				const currItemValues = currentItems.map(item => itemToString(item, 'value'));
				if (prevItemValues === currItemValues) {
					return prevItems;
				}
				return currentItems;
			});
		}
	}, [isControlled, compareType, items, selectedItem]);

	const { ref: controlRef } = useControlledFocusOnError({ name });

	const { dropdownMenuRef, isDropdownMenuFlipped } = useFlippedDropdownMenu(useComboboxOptions.isOpen);

	return (
		<FormGroupValidation
			inputRef={inputRef}
			validation={validation}
			groupProps={getGroupProps({
				controlId: id,
				className: `form-select-wrapper ${props.groupClassName ?? ''}`
			})}
			labelProps={getLabelProps({
				...useComboboxOptions.getLabelProps({
					...labelProps,
					htmlFor: id
				})
			})}
			helpblockProps={getHelpblockProps()}
			inline={props.inline}>
			<input ref={inputRef} type="hidden" name={name} disabled={toggleBtnDisabled} />
			<div className={['position-relative', validation.isInvalid && 'is-invalid'].filter(Boolean).join(' ')}>
				<ComboboxInput
					ref={controlRef}
					loading={loading}
					useComboboxOptions={useComboboxOptions}
					clearFilteredItems={clearFilteredItems}
					isInvalid={validation.isInvalid}
					disabled={toggleBtnDisabled || inputProps.readOnly}
					{...inputProps}
				/>

				<div
					ref={dropdownMenuRef}
					className={['dropdown-menu w-100 p-0', useComboboxOptions.isOpen && 'show', isDropdownMenuFlipped && 'dropdown-menu-end']
						.filter(Boolean)
						.join(' ')}>
					<ul className="dropdown-menu-items w-100 p-0 overflow-auto" {...dropdownMenuProps} data-testid="select-dropdown-menu">
						{useComboboxOptions.isOpen ? (
							<OptionsList
								getItemProps={useComboboxOptions.getItemProps}
								allItems={filteredItems}
								visibleItems={filteredItemsToDisplay}
								hiddenGroups={hiddenGroups}
								highlightedIndex={useComboboxOptions.highlightedIndex}
								notFoundPlaceholder=""
								searchString={searchValue}
								onGroupCollapse={collapseGroup}
								selectedItems={selectedItems}
								textTruncation={textTruncation}
							/>
						) : null}
					</ul>
				</div>
			</div>
		</FormGroupValidation>
	);
});
Combobox.displayName = 'Combobox';

Combobox.defaultProps = {
	...inputDefaultProps,
	items: [],
	strictMatch: true
};

Combobox.propTypes = {
	...inputPropTypes,
	/**
	 * A list of items to be displayed within the combobox.
	 *
	 * `[String|Number|Object]`
	 *
	 * Object format must be
	 * `{ content: String|Number|Component, value: String|Number }`
	 */
	items: PropTypes.array,

	/**
	 * Sets the initial value, selected item, and highlighted index of the combobox
	 */
	initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/**
	 * Change handler to be triggered when selected item(s) change
	 */
	onSelectedItemChange: PropTypes.func,

	/**
	 * Enforce strict item matching.  When `false` user provided input that does NOT match a combobox item will be accepted as valid.
	 */
	strictMatch: PropTypes.bool,

	/**
	 * Switches the <Combobox> component to the loading state.
	 */
	loading: PropTypes.bool,

	/**
	 * CompareType option for finding options in menu
	 */
	compareType: PropTypes.oneOf([
		comparatorTypes.exact,
		comparatorTypes.inclusive,
		comparatorTypes.exclusive,
		comparatorTypes.startsWith,
		comparatorTypes.notStartsWith
	])
};

export default Combobox;
