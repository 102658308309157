import React from 'react';

import { AccordionHeaderProps } from './types';
import { useAccordionContext, useAccordionItemContext } from './hooks';

import { createAccordionId } from './utility';

const AccordionHeader = ({ onClick, 'data-testid': dataTestId, className, ...props }: AccordionHeaderProps): JSX.Element => {
	const { id, isOpen, toggleOpen } = useAccordionContext();

	const eventKey = useAccordionItemContext().eventKey;
	const headerId = createAccordionId({ id, eventKey, suffix: 'header' });
	const collapseId = createAccordionId({ id, eventKey, suffix: 'collapse' });
	const btnClasses = ['accordion-button', !isOpen(eventKey) && 'collapsed'].filter(Boolean).join(' ');
	const classes = [className, 'accordion-header'].filter(Boolean).join(' ');
	return (
		<h2 className={classes} id={collapseId} aria-labelledby={headerId} data-testid={dataTestId}>
			<button
				aria-expanded={isOpen(eventKey)}
				onClick={e => {
					toggleOpen(eventKey);
					if (onClick) {
						onClick(e);
					}
				}}
				type="button"
				className={btnClasses}
				{...props}
			/>
		</h2>
	);
};

AccordionHeader.displayName = 'AccordionHeader';

export default AccordionHeader;
