/*
 * Can use this helper on any website to log key presses to the console and get pertinent info for the key press
 * document.addEventListener('keydown', event => console.log(event));
 */
import { KeyboardEvent } from 'react';
import { KeyboardControlKeys, KeyboardKey, KeyboardKeys } from './types';

export const DatePickerHotKeys = {
	previousDay: 'p',
	today: 't',
	nextDay: 'n'
};

export const Keys: KeyboardKeys = {
	ArrowUp: { key: 'ArrowUp', code: 'ArrowUp', keyCode: 38 },
	ArrowDown: { key: 'ArrowDown', code: 'ArrowDown', keyCode: 40 },
	ArrowLeft: { key: 'ArrowLeft', code: 'ArrowLeft', keyCode: 37 },
	ArrowRight: { key: 'ArrowRight', code: 'ArrowRight', keyCode: 39 },
	Enter: { key: 'Enter', code: 'Enter', keyCode: 13 },
	Space: { key: ' ', code: 'Space', keyCode: 32 },
	Home: { key: 'Home', code: 'Home', keyCode: 36 }, // macbook: fn + left
	End: { key: 'End', code: 'End', keyCode: 35 }, // macbook: fn + right,
	Asterisk: { key: '*', code: 'Digit8', keyCode: 56 },
	Escape: { key: 'Escape', code: 'Escape', keyCode: 27 },
	A: { key: 'a', code: 'KeyA', keyCode: 65 },
	Shift: { shiftKey: true },
	Ctrl: { ctrlKey: true }
};

export const isKeyPress = (key: KeyboardKey, event: KeyboardEvent): boolean => {
	return (key.shiftKey || false) === event.shiftKey && (key.ctrlKey || false) === event.ctrlKey && key.keyCode === event.keyCode;
};

const keyMap: { [key: KeyboardControlKeys]: KeyboardControlKeys } = Object.keys(Keys).reduce(
	(accumulator: { [key: KeyboardControlKeys]: KeyboardControlKeys }, key: KeyboardControlKeys) => {
		accumulator[key] = key;
		return accumulator;
	},
	{}
);

const pick = <T extends { [key: string]: unknown }>(object: T, keys: Array<keyof T> = []): T => {
	return keys.reduce<T>((accumulator, key) => {
		if (Object.prototype.hasOwnProperty.call(object, key)) {
			accumulator[key] = object[key];
		}
		return accumulator;
	}, {} as T);
};

const merge = <T extends { [key: string]: unknown }>(object: T, keys: Array<keyof T> = []): T => {
	const keysToCombine = pick(object, keys);
	let combinedValue = {} as T;
	Object.keys(keysToCombine).forEach(key => {
		combinedValue = { ...combinedValue, ...(keysToCombine[key] as object) };
	});
	return combinedValue;
};

// https://www.w3.org/TR/wai-aria-practices/#TreeView
// Type-ahead is recommended for all listboxes, especially those with more than seven options:
// - Type a character: focus moves to the next item with a name that starts with the typed character.
// - Type multiple characters in rapid succession: focus moves to the next item with a name that starts with the string of characters typed.
export const TreeViewKeys = pick(Keys, [
	// moves focus to the previous node, without opening the node.  If on first node, does nothing
	keyMap.ArrowUp,
	// moves focus to the next node, without opening the node.  If on last node, does nothing
	keyMap.ArrowDown,
	/*
	 * when focus is on an open node, closes the node
	 * when focus is on a child node that is also either an end node or a closed node, moves focus to it's parent
	 * when focus is on a root node that is also either an end node or a closed node, does nothing
	 */
	keyMap.ArrowLeft,
	/*
	 * when focus is on a closed node, opens the node; focus does not move
	 * when focus is on an open node, moves focus to the first child node
	 * when focus is on an end node, does nothing
	 */
	keyMap.ArrowRight,
	// performs default action (click event) for the focused node
	keyMap.Enter,
	// performs default action (click event) for the focused node
	keyMap.Space,
	// moves focus to first node without opening or closing a node
	keyMap.Home,
	// moves focus to the last node that can be focused without expanding any nodes that are closed
	keyMap.End,
	/*
	 * (Optional)
	 * expands all closed sibling nodes that are at the same level as the focused node
	 * focus does not move
	 */
	keyMap.Asterisk
]);

// https://www.w3.org/TR/wai-aria-practices/#Listbox
export const ListboxKeys = pick(Keys, [
	// Moves focus to the previous option. Optionally, in a single-select listbox, selection may also move with focus
	keyMap.ArrowUp,
	// Moves focus to the next option. Optionally, in a single-select listbox, selection may also move with focus
	keyMap.ArrowDown,
	// (Optional): Moves focus to first option. Optionally, in a single-select listbox, selection may also move with focus. Supporting this key is strongly recommended for lists with more than five options
	keyMap.Home,
	// (Optional): Moves focus to last option. Optionally, in a single-select listbox, selection may also move with focus. Supporting this key is strongly recommended for lists with more than five options
	keyMap.End,
	// multiple selection. changes the selection state of the focused option
	keyMap.Space
]);
// multiple selection. (Optional): Moves focus to and toggles the selected state of the previous option
ListboxKeys.ShiftArrowUp = merge(Keys, [keyMap.Shift, keyMap.ArrowUp]);
// multiple selection. (Optional): Moves focus to and toggles the selected state of the next option
ListboxKeys.ShiftArrowDown = merge(Keys, [keyMap.Shift, keyMap.ArrowDown]);
// multiple selection. (Optional): Selects contiguous items from the most recently selected item to the focused item
ListboxKeys.ShiftSpace = merge(Keys, [keyMap.Shift, keyMap.Space]);
// multiple selection. (Optional): Selects the focused option and all options up to the first option. Optionally, moves focus to the first option
ListboxKeys.CtrlShiftHome = merge(Keys, [keyMap.Ctrl, keyMap.Shift, keyMap.Home]);
// multiple selection. (Optional): Selects the focused option and all options down to the last option. Optionally, moves focus to the last option
ListboxKeys.CtrlShiftEnd = merge(Keys, [keyMap.Ctrl, keyMap.Shift, keyMap.End]);
// multiple selection. (Optional): Selects all options in the list. Optionally, if all options are selected, it may also unselect all options
ListboxKeys.CtrlA = merge(Keys, [keyMap.Ctrl, keyMap.A]);
