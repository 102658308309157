import React from 'react';
import { ProgressBarProps, ProgressBarSizes } from './types';
import { getPercentage } from './chart/utility';

export const progressBarVariants = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'dark', 'purple', 'orange', 'teal'];
export const progressBarSizes = ['md', 'lg', 'sm'];

export const createStyles = (size: ProgressBarSizes) => {
	const sizes = {
		sm: {
			height: '0.5rem'
		},
		md: {
			height: '1rem'
		},
		lg: {
			height: '1.5rem'
		}
	};
	return sizes[size];
};

export default function ProgressBar({
	isChild,
	className,
	striped,
	label,
	children,
	variant,
	now,
	min = 0,
	max = 100,
	size = 'md',
	animated,
	visuallyHidden,
	style,
	...props
}: ProgressBarProps): JSX.Element {
	const classes = [
		className,
		'progress-bar',
		variant && `bg-${variant} bg-opacity-100`,
		striped && 'progress-bar-striped',
		animated && 'progress-bar-animated'
	]
		.filter(Boolean)
		.join(' ');
	const labelClasses = [visuallyHidden && 'visually-hidden'].filter(Boolean).join(' ');
	const progressBarComponent = (
		<div
			{...props}
			className={classes}
			role="progressbar"
			aria-valuenow={now}
			aria-valuemin={min}
			aria-valuemax={max}
			style={{ ...style, width: `${getPercentage({ now, min, max })}%` }}>
			{label && <span className={labelClasses}>{label}</span>}
			{children}
		</div>
	);

	const stacked = React.Children.map(children, child => {
		if (child?.type?.name === ProgressBar.name) {
			return React.cloneElement(child, { isChild: true });
		}
		return child;
	});

	if (isChild) {
		return progressBarComponent;
	}

	if (stacked?.length) {
		return (
			<div className="progress" style={style}>
				{stacked.map(item => item)}
			</div>
		);
	}

	return (
		<div className="progress" style={{ ...createStyles(size), ...style }}>
			{progressBarComponent}
		</div>
	);
}
