import React from 'react';
import { AccordionItemProps } from './types';
import { AccordionItemContext } from './AccordionContext';

const AccordionItem = ({ align, className, eventKey, ...props }: AccordionItemProps): JSX.Element => {
	const { Provider } = AccordionItemContext;
	const classes = [className, align && `accordion-item-${align}`, 'accordion-item'].filter(Boolean).join(' ');
	return (
		<Provider
			value={{
				eventKey
			}}>
			<div className={classes} {...props} />
		</Provider>
	);
};

export default AccordionItem;
