import * as React from 'react';

import FontIcon from '../FontIcon';
import { utilityClasses } from '../utility';
import { useListStyle } from './useListStyle';
import { FontIconProps } from '../types';

export interface ListItemIconProps extends FontIconProps {
	listItemStyle?: React.CSSProperties;
}

export const baseTestId = `li-icon-container`;

export function ListItemIcon({ listItemStyle, ...props }: ListItemIconProps): JSX.Element {
	const itemStyle = useListStyle(listItemStyle);

	return (
		<span data-testid={`${baseTestId}${props['data-testid']}`} className={utilityClasses.listItemIcon} style={itemStyle}>
			<FontIcon {...props} />
		</span>
	);
}
