import { utilityClasses } from '../utility';
import { createClassWithSuffix } from '../../shared';
import { FontIconProps } from '../types';

export interface UseBasicStylingOptions {
	classList: Array<string>;
}

export function useBasicStyling({ size, inverse, fixedWidth, border, rotation, stack }: Omit<FontIconProps, 'icon'>): UseBasicStylingOptions {
	const classList = [
		inverse && utilityClasses.inverse,
		fixedWidth && utilityClasses.fixedWidth,
		border && utilityClasses.border,
		size && createClassWithSuffix(utilityClasses.size, size),
		rotation && createClassWithSuffix(utilityClasses.rotation, rotation),
		stack && createClassWithSuffix(utilityClasses.stack, stack)
	].filter(Boolean) as Array<string>;

	return {
		classList
	};
}
