import * as React from 'react';
import { utilityClasses } from '../utility';
import { LayerCounter } from './LayerCounter';
import { LayerText } from './LayerText';

export interface LayerIconProps extends React.HTMLAttributes<HTMLSpanElement> {
	fixedWidth?: boolean;
}

export function LayerIcons({ className, fixedWidth, ...props }: LayerIconProps): JSX.Element {
	const classes = [className, utilityClasses.layers, fixedWidth && utilityClasses.fixedWidth].filter(Boolean).join(' ');

	return <span className={classes} {...props} />;
}

LayerIcons.Counter = LayerCounter;
LayerIcons.Text = LayerText;
