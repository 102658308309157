import React from 'react';
import { ListGroupProps } from './types';

import ListGroupItem from './ListGroupItem';
import ListGroupItemTitle from './ListGroupItemTitle';
import ListGroupItemCheckbox from './ListGroupItemCheckbox';
import ListGroupItemClickable from './ListGroupItemClickable';
import ListGroupItemRadio from './ListGroupItemRadio';

export const listGroupHorizontal = [true, 'sm', 'md', 'lg', 'xl', 'xxl'];

const ListGroup = ({ numbered, as: ListGroupWrapper = numbered ? 'ol' : 'ul', variant, className, horizontal, ...props }: ListGroupProps) => {
	const horizontalClasses = [
		listGroupHorizontal.some(item => item === horizontal) && 'list-group-horizontal',
		listGroupHorizontal.some(item => typeof horizontal === 'string' && item === horizontal) && `-${horizontal}`
	]
		.filter(Boolean)
		.join('');
	const classes = [className, 'list-group', numbered && 'list-group-numbered', variant === 'flush' && 'list-group-flush', horizontalClasses]
		.filter(Boolean)
		.join(' ');

	React.Children.map(props.children, child => {
		if (child.type?.name === ListGroupItemCheckbox.name || child.type?.name === ListGroupItemRadio.name) {
			ListGroupWrapper = 'div';
		}
	});
	return <ListGroupWrapper className={classes} {...props} />;
};

ListGroup.displayName = 'ListGroup';
ListGroup.Item = ListGroupItem;
ListGroup.ItemTitle = ListGroupItemTitle;
ListGroup.ItemClickable = ListGroupItemClickable;
ListGroup.ItemRadio = ListGroupItemRadio;
ListGroup.ItemCheckbox = ListGroupItemCheckbox;

export default ListGroup;
