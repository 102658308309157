import { MutableRefObject, useCallback, useMemo } from 'react';
import { buildRegisterProps, noop } from '../../../helpers';
import { InlineEditReducerState, FormContext, InlineEditProps, InlineEditValue } from '../types';
import { useFormContext } from '../../forms';

export const useLatestFormContext = (latest: MutableRefObject<{ state: InlineEditReducerState; props: InlineEditProps }>): FormContext => {
	const formContextReturn = useFormContext();
	const formContext = useMemo(() => {
		return (
			formContextReturn || {
				setValue: noop
			}
		);
	}, [formContextReturn]);
	const isDisabled = formContext?.formState?.isDisabled || latest.current.props.disabled;

	buildRegisterProps(formContext.register, {
		name: latest.current.props.name,
		required: latest.current.props.required,
		validators: latest.current.props.validators,
		value: latest.current.state.selectedValue,
		disabled: isDisabled
	});

	const setValue = useCallback(
		(newValue: InlineEditValue) => {
			if (latest.current.props.name && !isDisabled) {
				formContext.setValue(latest.current.props.name, newValue, { shouldValidate: true });
			}
		},
		[formContext, isDisabled, latest]
	);

	return { isDisabled, setValue };
};
