import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@optic-delight/icons';

/**
 * Used in conjunction with `NavProgress`
 */
const NavProgressItem = ({ as: Component = 'a', eventKey, children, complete, active, className, ...props }) => {
	const classes = ['progress-dot-step', active && 'current', !active && complete && 'complete', className].filter(Boolean).join(' ');

	return (
		<Component key={eventKey} className={classes} {...props}>
			<div className="progress-dot-label">{children}</div>
			<div className="progress-dot-icon">{complete && <Icon size="sm" icon="check" />}</div>
		</Component>
	);
};
NavProgressItem.displayName = 'NavProgressItem';

NavProgressItem.defaultProps = {
	complete: false,
	active: false
};

NavProgressItem.propTypes = {
	as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
	children: PropTypes.string.isRequired,
	className: PropTypes.string,
	eventKey: PropTypes.string,

	/**
	 * the completed state of the NavProgress Item. Set from `NavProgress`
	 */
	complete: PropTypes.bool,

	/**
	 * the active state of the NavProgress Item. Set from `NavProgress` defaultActiveKey
	 */
	active: PropTypes.bool
};

export default NavProgressItem;
