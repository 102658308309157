import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../button';

export default function SelectedItemButton({ children, ...props }) {
	return (
		<Button className="selected-item-button" aria-label={`Remove item, ${children}`} {...props}>
			<span className="selected-item-button-text">{children}</span>
			<strong className="ms-2" aria-hidden={true}>
				x
			</strong>
		</Button>
	);
}

SelectedItemButton.propTypes = {
	children: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
};
