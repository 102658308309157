import React from 'react';
import { PopoverBodyProps } from './types';

const PopoverBody = ({ className, as: Component = 'div', ...props }: PopoverBodyProps) => {
	const classes = [className, 'popover-body'].filter(Boolean).join(' ');

	return <Component className={classes} {...props} />;
};

PopoverBody.displayName = 'PopoverBody';

export default PopoverBody;
