import React from 'react';

import { CalloutCardGroupProps } from './types';

const CalloutCardGroup = ({ label, value }: CalloutCardGroupProps): JSX.Element => (
	<div className="callout-group mb-0">
		<div className="callout-label">{label}</div>
		<div className="callout-value">{value}</div>
	</div>
);

CalloutCardGroup.displayName = 'CalloutCardGroup';

export default CalloutCardGroup;
