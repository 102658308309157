export class AuditRecord {
	public route: string;
	public hash: string;
	public totalTime: number;
	public details: { timestamp: number };

	constructor(route: string, hash = 'unknown') {
		this.route = route;
		this.hash = hash;
		this.totalTime = 0;
		this.details = {
			timestamp: Math.floor(Date.now() / 1000)
		};
	}
}
