import React, { ForwardedRef } from 'react';
import { CustomizedButtonProps } from './types';
import CustomizedButtonWrapper from './CustomizedButtonWrapper';

export const EditButtonDefaultText = 'Edit';

const EditButton = React.forwardRef(({ children = EditButtonDefaultText, ...props }: CustomizedButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
	return (
		<CustomizedButtonWrapper variant="default" icon="edit" iconDirection="prepend" ref={ref} {...props}>
			{children}
		</CustomizedButtonWrapper>
	);
});
EditButton.displayName = 'EditButton';

export default EditButton;
