import React from 'react';
import PropTypes from 'prop-types';

import { ColumnHeader } from '../components/ColumnHeader';
import { ExpressionWrapper, isColumnEmpty } from '../utility';

import { IconLoading } from '@optic-delight/icons';
import { useTranslatableText } from '../../translatable-text';

const VerticalTable = ({
	headerGroups,
	isPresentationTable,
	getTableBodyProps,
	paginationEnabled,
	rows,
	page,
	prepareRow,
	renderRowSubComponent,
	visibleColumns,
	loading = false,
	disabled = false
}) => {
	const hasVisibleHeaders = headerGroups.filter(headerGroup => headerGroup.headers.some(item => !isColumnEmpty(item, 'Header'))).length > 0;
	const { noResultsText, loadingText } = useTranslatableText();

	return (
		<>
			{hasVisibleHeaders ? (
				<thead>
					{headerGroups.map(headerGroup => {
						const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps({
							role: isPresentationTable ? undefined : 'row'
						});
						return (
							<tr key={headerGroupKey} {...headerGroupProps}>
								{headerGroup.headers.map(column => {
									const { key: headerKey, ...headerProps } = column.getHeaderProps({
										className: [column.className, column.canSort && 'table-col-sortable'].filter(Boolean).join(' '),
										style: column.style,
										loading,
										disabled,
										role: isPresentationTable ? undefined : 'columnheader'
									});
									return <ColumnHeader key={headerKey} column={column} {...headerProps} />;
								})}
							</tr>
						);
					})}
				</thead>
			) : null}
			<tbody
				{...getTableBodyProps({
					role: isPresentationTable ? undefined : 'rowgroup'
				})}>
				{(paginationEnabled ? page : rows).map(row => {
					prepareRow(row);
					const { key: rowKey, ...rowProps } = row.getRowProps({
						role: isPresentationTable ? undefined : 'row'
					});
					return (
						<React.Fragment key={rowKey}>
							<tr {...rowProps}>
								{row.cells.map(cell => {
									const { key: cellKey, ...cellProps } = cell.getCellProps({
										className: cell.column.className,
										style: cell.column.style,
										role: isPresentationTable ? undefined : 'cell'
									});
									// This expression for all type of table (expandable, with actions).
									return (
										<td key={cellKey} {...cellProps}>
											{cell.column.cellFormat ? cell.column.cellFormat(cell.value) : cell.render('Cell')}
										</td>
									);
								})}
							</tr>
							{row.isExpanded && renderRowSubComponent ? (
								<tr {...rowProps} id={`rowExpander${row.id}`} aria-live="polite">
									<td colSpan={visibleColumns.length}>
										{/*
										  Inside it, call our renderRowSubComponent function. In reality,
										  you could pass whatever you want as props to
										  a component like this, including the entire
										  table instance. But for this example, we'll just
										  pass the row
										*/}
										<div role="region">{renderRowSubComponent({ row })}</div>
									</td>
								</tr>
							) : null}
						</React.Fragment>
					);
				})}
				{headerGroups.map(headerGroup => {
					const { key: headerGroupKey } = headerGroup.getHeaderGroupProps();
					return (
						<ExpressionWrapper
							key={headerGroupKey}
							expression={!!rows.length}
							colSpan={headerGroup.headers.length}
							isPresentationTable={isPresentationTable}>
							{loading ? (
								<>
									<IconLoading className="me-2" />
									{loadingText}
								</>
							) : (
								noResultsText
							)}
						</ExpressionWrapper>
					);
				})}
			</tbody>
		</>
	);
};

VerticalTable.propTypes = {
	headerGroups: PropTypes.array.isRequired,
	isPresentationTable: PropTypes.bool,
	rows: PropTypes.array.isRequired,
	getTableBodyProps: PropTypes.func,
	paginationEnabled: PropTypes.bool,
	page: PropTypes.array,
	prepareRow: PropTypes.func,
	visibleColumns: PropTypes.array,
	renderRowSubComponent: PropTypes.func,
	loading: PropTypes.bool,
	disabled: PropTypes.bool
};

VerticalTable.defaultProps = {
	loading: false,
	isPresentationTable: false
};

export default VerticalTable;
