import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { StringifyArgs } from './types';

export const callAll =
	<Args extends unknown[]>(...fns: (((...args: Args) => void) | undefined)[]) =>
	(...args: Args): void => {
		fns.forEach(fn => fn?.(...args));
	};

export const noop: () => void = () => {
	return;
};

export const stringify = (item?: StringifyArgs) => {
	if (typeof item === 'object') {
		if (React.isValidElement(item)) {
			return ReactDOMServer.renderToStaticMarkup(item);
		}
		return JSON.stringify(item);
	}

	return item?.toString();
};

export function getFileExtension(filename: string) {
	const lastDotIndex = filename.lastIndexOf('.');
	const lastSlashIndex = filename.lastIndexOf('/');
	if (lastDotIndex < 0 || lastSlashIndex > lastDotIndex) {
		return '';
	}
	return filename.substring(lastDotIndex + 1).toLowerCase();
}

export function removeFileExtension(filename: string) {
	const lastDotIndex = filename.lastIndexOf('.');
	const lastSlashIndex = filename.lastIndexOf('/');
	if (lastDotIndex < 1 || lastDotIndex === lastSlashIndex + 1 || lastDotIndex < lastSlashIndex) {
		return filename;
	}
	return filename.substring(0, lastDotIndex);
}

export function prettifySizeValue(size: number, fractionDigits = 3) {
	const ONE_KB_IN_BYTES = 1024;
	const ONE_MB_IN_BYTES = ONE_KB_IN_BYTES * 1024;
	const ONE_GB_IN_BYTES = ONE_MB_IN_BYTES * 1024;

	let label;
	if (size < ONE_KB_IN_BYTES) {
		label = `${size} B`;
	} else if (size < ONE_MB_IN_BYTES) {
		label = `${(size / ONE_KB_IN_BYTES).toFixed(fractionDigits)} KB`;
	} else if (size < ONE_GB_IN_BYTES) {
		label = `${(size / ONE_MB_IN_BYTES).toFixed(fractionDigits)} MB`;
	} else {
		label = `${(size / ONE_GB_IN_BYTES).toFixed(fractionDigits)} GB`;
	}

	return label.replace(/\.?0+\s/, ' ');
}

export function remToPx(rem: number) {
	if (!rem) {
		return 0;
	}

	return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function debounce<Params extends unknown[]>(func: (...args: Params) => unknown, timeout: number): (...args: Params) => void {
	let timer: NodeJS.Timeout;
	return (...args: Params) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func(...args);
		}, timeout);
	};
}

export class ResizeObserverStub {
	observe() {
		// do nothing
	}
	unobserve() {
		// do nothing
	}
	disconnect() {
		// do nothing
	}
}
