import React from 'react';
import ReactDOM from 'react-dom';
import { withErrorBoundary } from '@optic-delight/benefitsolver-services';
import App, { AppFallback } from './App';

import '@optic-delight/bootstrap-theme-benefitsolver/build/theme.css';

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_BACKEND === 'true') {
	const { worker } = require('./mocks/browser');
	worker.start();
}

const root = document.getElementById('app-root');
const Wrapped = withErrorBoundary(App, {
	FallbackComponent: AppFallback
});
ReactDOM.render(<Wrapped />, root);
