import { useContext } from 'react';

import { CheckGroupContext } from '../CheckGroup';
import { useElementIds } from '../../../../helpers/hooks/useElementIds';

export default function useCheckable(props) {
	const groupContext = useContext(CheckGroupContext) || {};
	const { isGroup, activeSwitchItems, onGroupChange, ...groupProps } = groupContext;
	const { id: innerId } = useElementIds({ prefix: 'checkable', id: props.id });

	// remove undefined keys
	Object.keys(groupProps).forEach(key => groupProps[key] === undefined && delete groupProps[key]);

	const combinedProps = {
		...props,
		id: innerId,
		...groupProps
	};
	return {
		getProps: propOverrides => ({ ...combinedProps, ...propOverrides }),
		isGroup,
		activeSwitchItems,
		checkGroup: {
			onChange: onGroupChange
		}
	};
}
