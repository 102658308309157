import { asDate } from '../date';
import { PossibleDate } from '../types';

export function formatDate(value: PossibleDate, options: string | Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }) {
	const date = asDate(value);
	if (date) {
		if (typeof options === 'string') {
			const dateParts: Record<string, string> = new Intl.DateTimeFormat('en-US', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				fractionalSecondDigits: 3,
				hour12: false
			})
				.formatToParts(date)
				.filter(part => part.type !== 'literal')
				.reduce((accumulator, part) => {
					if (part.type === 'year' && options.indexOf('yyyy') === -1) {
						part.value = part.value.substring(2, 4);
					} else if ((part.type as string) === 'fractionalSecond' && options.indexOf('SSS') === -1) {
						// cast part.type as string because Intl.DateTimeFormatPartTypes is missing fractionalSecond
						part.value = part.value.substring(0, 2);
					}
					accumulator[part.type] = part.value;
					return accumulator;
				}, {} as Record<string, string>);

			return options
				.replace(/dd/g, dateParts.day)
				.replace(/MM/g, dateParts.month)
				.replace(/y{1,4}/g, dateParts.year)
				.replace(/hh/g, dateParts.hour)
				.replace(/mm/g, dateParts.minute)
				.replace(/ss/g, dateParts.second)
				.replace(/f{1,3}/g, dateParts.fractionalSecond);
		} else {
			return new Intl.DateTimeFormat('en-US', options).format(date);
		}
	}

	return value;
}
