import React from 'react';
import { Icon } from '@optic-delight/icons';
import CheckableInput from './CheckableInput';
import useCheckable from './hooks/useCheckable';
import { buttonVariants } from '../../button';
import { ButtonCheckableProps } from '../types';
import { FormControlFeedback } from '../form-control';
import { Label } from '../../utility';

const ButtonCheckable = React.forwardRef<HTMLInputElement, ButtonCheckableProps>((props, ref) => {
	const { getProps, activeSwitchItems, isGroup } = useCheckable(props);

	const { inline, label, icon, variant, ...buttonCheckableProps } = getProps({
		// remove props that do not belong on inputs
		dirty: null
	});
	const size: 'sm' | 'lg' = buttonCheckableProps.size;

	const classes = [
		'btn mb-0 form-check',
		buttonVariants.includes(variant) ? `btn-${variant}` : 'btn-default',
		buttonCheckableProps.disabled && 'disabled',
		activeSwitchItems?.includes(props.value) && 'active',
		inline && 'form-check-inline'
	]
		.filter(Boolean)
		.join(' ');

	const paddingSizes = {
		default: '2rem',
		sm: '1.75rem',
		lg: '2.25rem'
	};

	return (
		<>
			<Label htmlFor={buttonCheckableProps.id} className={classes} style={icon ? undefined : { paddingLeft: paddingSizes[size ?? 'default'] }}>
				<CheckableInput ref={ref} {...buttonCheckableProps} />
				{icon && <Icon icon={icon} className="me-1" />}
				{label}
			</Label>
			{!isGroup() && buttonCheckableProps.required && <FormControlFeedback type="invalid">{label + ' is a required field'}</FormControlFeedback>}
		</>
	);
});
ButtonCheckable.displayName = 'ButtonCheckable';

export default ButtonCheckable;
