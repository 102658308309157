interface InputModeOptions {
	type: string;
	inputMode?: string;
}

export const getInputModeProps = (type: string, inputMode?: string): InputModeOptions | undefined => {
	const inputModes: { [key: string]: InputModeOptions } = {
		text: {
			type: 'text',
			inputMode: 'text'
		},
		numeric: {
			type: 'text',
			inputMode: 'numeric'
		},
		phone: {
			type: 'text',
			inputMode: 'tel'
		},
		decimal: {
			type: 'text',
			inputMode: 'decimal'
		},
		email: {
			type: 'email',
			inputMode: 'email'
		},
		url: {
			type: 'text',
			inputMode: 'url'
		},
		search: {
			type: 'text',
			inputMode: 'search'
		}
	};
	if (inputMode) {
		return {
			...inputModes[type],
			inputMode
		};
	}
	return inputModes[type];
};
