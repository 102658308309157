const prefix = 'inline-edit-';

export const inlineEditStateChangeTypes = {
	ToggleMode: `${prefix}toggle-mode`,
	EditMode: `${prefix}edit-mode`,
	ReadMode: `${prefix}read-mode`,
	ConfirmEdit: `${prefix}confirm-edit`,
	CancelEdit: `${prefix}cancel-edit`,
	SetValue: `${prefix}set-value`,
	SetCurrentValue: `${prefix}set-current-value`,
	OnChange: `${prefix}on-change`,
	OnClickOutside: `${prefix}on-click-outside`,
	OnFocus: `${prefix}on-focus`
};
