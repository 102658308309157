import * as React from 'react';
import { Transform, useTransform } from '../hooks/useTransform';
import { utilityClasses } from '../utility';

export interface LayerTextProps extends React.HTMLAttributes<HTMLSpanElement> {
	inverse?: boolean;
	transform?: Transform;
}

export function LayerText({ className, transform, inverse, ...props }: LayerTextProps): JSX.Element {
	const transformProps = useTransform(transform);

	const classes = [className, utilityClasses.layersText, inverse && utilityClasses.inverse].filter(Boolean).join(' ');

	return <span className={classes} {...transformProps} {...props} />;
}
