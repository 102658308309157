import React from 'react';
import { MeasurementsCacheRefProps, VirtualListWrapperProps } from './types';
import { measureElement, useVirtualizer, Virtualizer } from '@tanstack/react-virtual';

const VirtualListWrapper = ({ count, estimateSize, itemRender, textTruncation = true }: VirtualListWrapperProps) => {
	const scrollElement = React.useRef<HTMLDivElement>(null);
	const measurementsCache = React.useRef<MeasurementsCacheRefProps>({});

	const measureElementMaxSize = React.useCallback((element: HTMLLIElement, instance: Virtualizer<HTMLDivElement, HTMLLIElement>) => {
		const index = instance.indexFromElement(element);

		const prevSize = instance.measurementsCache[index].size;
		const size = measureElement(element, instance);
		const newSize = Math.max(prevSize, size);
		if (!measurementsCache.current[index] || newSize > measurementsCache.current[index]) {
			measurementsCache.current[index] = newSize;
		}

		return newSize;
	}, []);

	const rowVirtualizer = useVirtualizer<HTMLDivElement, HTMLLIElement>({
		count: count,
		getScrollElement: () => scrollElement.current,
		estimateSize,

		// 30 is just for testing,to render more items
		// 15 is for runtime, less than for testing, but little more than number of items to fit in dropdown area
		overscan: process.env.NODE_ENV === 'test' ? 30 : 15,

		// if text is not truncated the element size is measured.
		...(!textTruncation && { measureElement: measureElementMaxSize })
	});

	const ref = React.useMemo(
		() => (node: HTMLLIElement | null) => {
			rowVirtualizer.measureElement(node);
		},
		[rowVirtualizer]
	);

	return (
		<div className="w-100 h-100 overflow-auto accordion" ref={scrollElement}>
			<div className="w-100 position-relative" style={{ height: rowVirtualizer.getTotalSize() }}>
				{rowVirtualizer.getVirtualItems().map(virtualItem => itemRender(virtualItem, ref))}
			</div>
		</div>
	);
};
VirtualListWrapper.displayName = 'VirtualListWrapper';

export default VirtualListWrapper;
