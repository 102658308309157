import React from 'react';
import PropTypes from 'prop-types';
import SelectedItemButton from '../utility/SelectedItemButton';

const DrillDownSelectedItems = ({ selectedItems, onClick, ...props }) => {
	return selectedItems.length > 0 ? (
		<div className="d-flex flex-wrap gap-1 mb-2">
			{selectedItems.map((item, index) => {
				return (
					<React.Fragment key={index}>
						<SelectedItemButton onClick={e => onClick(e, item)} {...props}>
							{item}
						</SelectedItemButton>
					</React.Fragment>
				);
			})}
		</div>
	) : null;
};
DrillDownSelectedItems.displayName = 'DrillDownSelectedItems';

DrillDownSelectedItems.defaultProps = {
	selectedItems: [],
	variant: 'neutral',
	size: 'sm'
};

DrillDownSelectedItems.propTypes = {
	/*
	 * List of items
	 */
	selectedItems: PropTypes.array,

	onClick: PropTypes.func.isRequired
};

export default DrillDownSelectedItems;
