import React from 'react';
import PropTypes from 'prop-types';

import { DropdownButton as ReactDropdownButton } from 'react-bootstrap';

/**
 * see [react-bootstrap - Dropdowns](https://react-bootstrap.github.io/components/dropdowns/#single-button-dropdowns) for complete documentation on the underlying implementation
 */
export default function DropdownButton(buttonProps) {
	const { children, ...props } = buttonProps;
	return (
		<ReactDropdownButton menuRole="menu" {...props}>
			{children}
		</ReactDropdownButton>
	);
}

DropdownButton.defaultProps = {
	variant: 'default',
	align: 'end'
};

DropdownButton.propTypes = {
	className: PropTypes.string, // without this you get className is not assignable error
	onSelect: PropTypes.func, // without this you get onSelect is not assignable error

	/**
	 * button dropdown content
	 */
	children: PropTypes.any.isRequired,

	/**
	 * An html id attribute for the Toggle button, necessary for assistive technologies, such as screen readers
	 */
	id: PropTypes.string.isRequired,

	/**
	 * The content of the non-toggle Button
	 */
	title: PropTypes.node.isRequired
};
