import React from 'react';
import { ColumnInstance } from 'react-table';
import { ConditionalElement } from '../../../types';
import { AppliedFilters } from './AppliedFilters';
import { useTranslatableText } from '../../../translatable-text';
import { rowSpacer } from '../utility';

interface FilterResultsProps {
	columns: Array<ColumnInstance>;
	resultsLength: number;
	searchCriteria?: string;
	filterLabelsMapping?: (columnId: string, value: unknown) => string | undefined;
}

export const resultsFoundTestId = 'filtered-results';

export function FilterResults({ columns, resultsLength, searchCriteria = '', filterLabelsMapping }: FilterResultsProps): ConditionalElement {
	const { searchResultsText, filterResultsText } = useTranslatableText();
	const resultsFoundText = searchCriteria ? searchResultsText + ` "${searchCriteria}"` : filterResultsText;

	const filteredColumns = columns.filter(column => !!column.filterValue);

	const areResultsFiltered = filteredColumns.length > 0 || searchCriteria;
	return areResultsFiltered ? (
		<div className={rowSpacer} data-testid={resultsFoundTestId}>
			<div className="h3 text-break">{`${resultsFoundText} - ${resultsLength}`}</div>
			<AppliedFilters columns={filteredColumns} filterLabelsMapping={filterLabelsMapping} />
		</div>
	) : null;
}
