import React from 'react';

import CardTitle from './CardTitle';

import { InlineHelper } from '../../helpers/components';
import { CardHeaderProps } from './types';

const CardHeader = ({ className, actions, children, ...props }: CardHeaderProps): JSX.Element => {
	const classes = [className, 'card-header'].filter(Boolean).join(' ');
	return (
		<div className={classes} {...props}>
			<div className="row">
				<div className="col">
					<CardTitle>{children}</CardTitle>
				</div>
				{actions && <InlineHelper className="col-auto ms-auto">{actions}</InlineHelper>}
			</div>
		</div>
	);
};

CardHeader.displayName = 'CardHeader';

export default CardHeader;
