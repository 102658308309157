import React from 'react';

import { prefixRow, useGrid } from './hooks/useGrid';
import { RowProps } from './types';

const Row = ({ lg, md, sm, xl, xs, xxl, className, ...props }: RowProps): JSX.Element => {
	const { rowClassName } = useGrid({ gridSizes: { lg, md, sm, xl, xs, xxl }, prefix: `${prefixRow}-cols` });
	const classes = [className, prefixRow, rowClassName].filter(Boolean).join(' ');
	return <div className={classes} {...props} />;
};

Row.displayName = 'Row';

export default Row;
