import React from 'react';
import Tooltip from '../tooltip/Tooltip';
import { LabelProps } from './types';
import useFormGroup from '../forms/form-group/hook/useFormGroup';

const Label = React.forwardRef<HTMLElement, LabelProps>(
	({ visuallyHidden = false, as: Component = 'label', column = false, className, id, required = false, tooltip, htmlFor, ...props }, ref) => {
		const { controlId } = useFormGroup();

		const classes = [
			className,
			'form-label',
			typeof column === 'boolean' && column && 'col-form-label col-auto',
			typeof column === 'string' && `col-form-label-${column} col`,
			visuallyHidden && 'visually-hidden'
		]
			.filter(Boolean)
			.join(' ');

		return props.children ? (
			<Component ref={ref} className={classes} id={id} htmlFor={htmlFor ?? controlId} {...props}>
				{props.children}
				{required && <span className="text-danger"> *</span>}
				<Tooltip data-testid="tooltip-wrapper" id={id ? id + '_tooltip' : undefined}>
					{tooltip}
				</Tooltip>
			</Component>
		) : null;
	}
);

Label.displayName = 'Label';
export default Label;
