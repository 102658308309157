import React from 'react';

import { NavigationContext } from '../NavigationContext';
import HookContextError from '../../../helpers/HookContextError';

export default function useNavigation() {
	const context = React.useContext(NavigationContext);
	if (!context) {
		throw new HookContextError(useNavigation.name, NavigationContext.displayName);
	}

	return context;
}
