import React from 'react';
import { ListGroupItemProps } from './types';
import ListGroupItem from './ListGroupItem';

const ListGroupItemClickable = (props: ListGroupItemProps) => {
	return <ListGroupItem {...props} action />;
};

ListGroupItemClickable.displayName = 'ListGroupItemClickable';

export default ListGroupItemClickable;
