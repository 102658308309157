import React from 'react';
import DropdownItem, { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import useNavigation from '../hooks/useNavigation';
import { useActiveItem } from '../hooks/useActiveItem';

export function NavigationDropdownItem({ eventKey, onClick, href, ...props }: DropdownItemProps) {
	const { onSelect, activeKey, setActiveContent } = useNavigation();
	const { isActive, getLinkProps, eventKey: itemKey } = useActiveItem({ eventKey, href, activeKey, setActiveContent });

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		onSelect(itemKey, event);
		onClick?.(event);
	};

	return (
		<li>
			<DropdownItem active={isActive()} eventKey={eventKey} onClick={handleClick} {...getLinkProps(props)} />
		</li>
	);
}
