import { dataAttributes, utilityClasses } from '../utility';
import { createClassWithSuffix } from '../../shared';

export interface Transform {
	scale?: number; // grow-99, shrink-99, (negative means use shrink)
	x?: number; // left-99, right-99 (negative means use left)
	y?: number; // up-99, down-99 (negative means use down)
	rotate?: number; // rotate-90, rotate-180, rotate-270, rotate--30?
	flip?: 'horizontal' | 'vertical' | 'both'; // flip-h, flip-v, flip-h flip-v
}

export function useTransform(transformProp?: string | Transform): Record<string, unknown> {
	const transformAttributes: Record<string, unknown> = {};
	if (transformProp) {
		if (typeof transformProp === 'string') {
			transformAttributes[dataAttributes.transform] = transformProp;
		} else {
			const transform: Transform = transformProp;
			transformAttributes[dataAttributes.transform] =
				[
					transform.scale && transform.scale > 0 && createClassWithSuffix(utilityClasses.grow, transform.scale),
					transform.scale && transform.scale < 0 && createClassWithSuffix(utilityClasses.shrink, transform.scale * -1),
					transform.x && transform.x > 0 && createClassWithSuffix(utilityClasses.right, transform.x),
					transform.x && transform.x < 0 && createClassWithSuffix(utilityClasses.left, transform.x * -1),
					transform.y && transform.y > 0 && createClassWithSuffix(utilityClasses.up, transform.y),
					transform.y && transform.y < 0 && createClassWithSuffix(utilityClasses.down, transform.y * -1),
					transform.rotate && createClassWithSuffix(utilityClasses.rotate, transform.rotate),
					transform.flip === 'both' && `${utilityClasses.flipHorizontal} ${utilityClasses.flipVertical}`,
					transform.flip === 'horizontal' && utilityClasses.flipHorizontal,
					transform.flip === 'vertical' && utilityClasses.flipVertical
				]
					.filter(Boolean)
					.join(' ') || undefined;
		}
	}

	return transformAttributes;
}
