import React from 'react';
import PropTypes from 'prop-types';
import FormText from 'react-bootstrap/FormText';

export default function Helpblock(props) {
	return props.id && props.children ? <FormText as="div" className="text-muted" {...props} /> : null;
}

Helpblock.propTypes = {
	/**
	 * help block content
	 * @required for display
	 */
	children: PropTypes.any,

	/**
	 * id for the help block.
	 * @required for display
	 */
	id: PropTypes.string
};
