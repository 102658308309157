import { useReducer } from 'react';
import { inlineEditReducer } from '../inlineEditReducer';
import { InlineEditReducer, InlineEditReducerState, UseInlineEditReducerProps, UseInlineEditReducerData } from '../types';

const defaultStateReducer: InlineEditReducer = (state, action) => {
	return action.changes || {};
};

export const useInlineEditReducer = (props: UseInlineEditReducerProps): UseInlineEditReducerData => {
	const { stateReducer = defaultStateReducer, isEdit, multiple, selectedValue, required, dirty } = props;

	const enhancedReducer: InlineEditReducer = (state, action) => {
		const changes = inlineEditReducer(state, action);
		return stateReducer(state, { ...action, changes });
	};

	const initializerState: InlineEditReducerState = {
		isEdit: typeof isEdit !== 'undefined' ? isEdit : false,
		selectedValue: multiple && !Array.isArray(selectedValue) ? [] : selectedValue,
		currentValue: multiple && !Array.isArray(selectedValue) ? [] : selectedValue,
		isInvalid: required && dirty,
		dirty
	};

	const [internalState, dispatch] = useReducer(enhancedReducer, initializerState);

	return [internalState, dispatch];
};
