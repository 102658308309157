import React from 'react';
import { CancelButtonProps } from './types';
import CustomizedButtonWrapper from './CustomizedButtonWrapper';

export const CancelButtonDefaultText = 'Cancel';

export const cancelButtonDefaultProps = {
	icon: null,
	variant: 'neutral' as const
};

const CancelButton = React.forwardRef<HTMLButtonElement, CancelButtonProps>(({ children = CancelButtonDefaultText, ...props }, ref) => (
	<CustomizedButtonWrapper {...props} icon={null} variant={cancelButtonDefaultProps.variant} ref={ref}>
		{children}
	</CustomizedButtonWrapper>
));

CancelButton.displayName = 'CancelButton';

export default CancelButton;
