import React from 'react';
import { ActionButtonDropDownDividerProps } from './types';
import DropdownDivider from '../DropdownDivider';

const ActionButtonDropdownDivider = (props: ActionButtonDropDownDividerProps): JSX.Element => {
	return <DropdownDivider {...props} />;
};

ActionButtonDropdownDivider.displayName = 'ActionButtonDropdownDivider';

export default ActionButtonDropdownDivider;
