import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';
import Helpblock from './Helpblock';

import FormControlFeedback from '../forms/form-control/FormControlFeedback';
import FormGroup from '../forms/form-group/FormGroup';

export default function Fieldset(props) {
	const helpblockId = props.id ? props.id + '_helptext' : null;
	return (
		<FormGroup className="mb-3" as="fieldset" aria-describedby={helpblockId && props.helptext ? helpblockId : null}>
			<Label
				as="legend"
				id={props.id ? props.id + '_label' : null}
				required={props.required}
				tooltip={props.tooltip}
				className={props.isInvalid ? 'is-invalid' : null}>
				{props.label}
			</Label>

			{props.children}

			<Helpblock id={helpblockId}>{props.helptext}</Helpblock>
			{props.required && <FormControlFeedback type="invalid">{props.label + ' is a required field'}</FormControlFeedback>}
		</FormGroup>
	);
}

Fieldset.propTypes = {
	/**
	 * fieldset content
	 */
	children: PropTypes.any.isRequired,

	/**
	 * id of the fieldset.  Required for accessibility.
	 */
	id: PropTypes.string,

	/**
	 * label for the fieldset
	 */
	label: PropTypes.node,

	/**
	 * tooltip text
	 */
	tooltip: PropTypes.string,

	/**
	 * helptext to specify some information about the fieldset
	 */
	helptext: PropTypes.string,

	/**
	 * indicates if fieldset is required
	 */
	required: PropTypes.bool,

	/**
	 * Add "invalid" validation styles to the label
	 */
	isInvalid: PropTypes.bool
};

Fieldset.defaultProps = {
	required: false,
	isInvalid: false
};
