import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination, useExpanded, useRowSelect } from 'react-table';
import { Table } from 'react-bootstrap';
import Pagination from './pagination/Pagination';
import { componentArrayChildrenType, menuOptionType } from '../types/propTypes';
import { useRowSelectionColumn } from './row-selection/hooks/useRowSelectionColumn';
import { VerticalTable, HorizontalTable } from './render-mode';
import { TableFilters } from './filters/TableFilters';
import { isColumnEmpty } from './utility';
import { FilterContext } from './utility/FilterContext';
import { TranslatableTextProvider, translatableTextPropTypes } from '../translatable-text';

// if datatable is in the loading state, it creates an empty array every time
// so that it does not create a new array we took it out of the component
const emptyArray = [];

/**
 * - Use Datatable when enhanced table functionality is needed
 * - Features
 *  - Table Filter
 *  - Column sort
 *  - Pagination
 */
export default function Datatable({
	columns,
	data,
	tableFilter,
	sortable,
	paginate,
	rowSelection,
	rowSelectionOptions = {},
	striped,
	size,
	lengthMenu,
	caption,
	responsive,
	customFilters,
	actionButtons,
	initialSort,
	renderRowSubComponent,
	rowExpansionOptions = {},
	selectedRows,
	onSelectedRows,
	type,
	'data-testid': dataTestId,
	loading,
	useTableOptions,
	role,
	bordered,
	borderless,
	className,
	onFilterClear,
	filterResultsLabel,
	filterLabelsMapping,
	loadingText,
	noResultsText,
	globalFilterPlaceholder,
	searchResultsText,
	filterResultsText,
	lengthMenuShowText,
	lengthMenuOfText,
	clearAllText,
	initialFilteredColumns = [],
	...props
}) {
	const globalFilterEnabled = tableFilter === undefined || tableFilter;
	const paginationEnabled = paginate === undefined || paginate;
	const { rowSelection: innerRowSelection = rowSelection, ...innerRowSelectionOptions } = rowSelectionOptions;
	const { subRow = renderRowSubComponent, column: expandableColumn } = rowExpansionOptions;
	const classes = [className, type === 'horizontal' && 'table-horizontal'].filter(Boolean).join(' ');

	// useRowSelect requires usePagination hook to be in use.  Internally, we will supress displaying paginated items
	const hiddenPagination = innerRowSelection && !paginationEnabled;
	if (hiddenPagination) {
		lengthMenu = [[-1, 'all']]; // force all rows to display
	}

	// Plugin Order Matters
	let plugins = [
		useFilters,
		globalFilterEnabled && useGlobalFilter,
		useSortBy,
		useExpanded,
		(hiddenPagination || paginationEnabled) && usePagination,
		innerRowSelection && useRowSelect
	].filter(Boolean);

	if (sortable !== undefined && !sortable) {
		columns.map(column => (column['disableSortBy'] = true));
	}

	const { fetch, filteredRowCount, rowCount, ...tableOptions } = useTableOptions || {};
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		footerGroups,
		rows,
		prepareRow,
		pageCount,
		page,
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,
		columns: useTableColumns,
		visibleColumns,
		selectedFlatRows,
		state: { pageIndex, pageSize, globalFilter, filters, sortBy }
	} = useTable(
		{
			columns,
			data: loading ? emptyArray : data,
			initialState: {
				pageIndex: 0,
				pageSize: lengthMenu ? lengthMenu[0][0] : 25,
				sortBy: initialSort,
				selectedRowIds: selectedRows,
				filters: initialFilteredColumns
			},
			...tableOptions
		},
		...plugins,
		hooks => {
			if (expandableColumn) {
				hooks.visibleColumns.push(allColumns => [expandableColumn, ...allColumns]);
			}
			if (innerRowSelection) {
				hooks.visibleColumns.push(allColumns => [useRowSelectionColumn(innerRowSelectionOptions), ...allColumns]);
			}
		}
	);

	const isPresentationTable =
		role === 'presentation' || headerGroups.filter(headerGroup => headerGroup.headers.some(item => !isColumnEmpty(item, 'Header'))).length === 0;

	React.useEffect(() => {
		// reset the page index to 0 when filtering or sorting changes
		gotoPage?.(0);
	}, [gotoPage, globalFilter, filters, sortBy]);

	useEffect(() => {
		fetch?.({ pageIndex, pageSize, globalFilter, filters, sortBy });
	}, [fetch, pageIndex, pageSize, globalFilter, filters, sortBy]);

	useEffect(() => {
		onSelectedRows?.(selectedFlatRows);
	}, [selectedFlatRows, onSelectedRows]);

	return (
		<div className="ui-datatable-wrapper">
			<TranslatableTextProvider
				value={{
					globalFilterPlaceholder,
					filterResultsLabel,
					loadingText,
					noResultsText,
					searchResultsText,
					filterResultsText,
					lengthMenuShowText,
					lengthMenuOfText,
					clearAllText
				}}>
				<FilterContext.Provider value={{ onFilterClear }}>
					<TableFilters
						globalFilterEnabled={globalFilterEnabled}
						globalFilter={globalFilter}
						setGlobalFilter={setGlobalFilter}
						customFilters={customFilters}
						filterLabelsMapping={filterLabelsMapping}
						actionButtons={actionButtons}
						useTableColumns={useTableColumns}
						loading={loading}
						rowCount={filteredRowCount ?? rowCount ?? rows.length}
					/>

					<Table
						{...getTableProps({
							role: isPresentationTable ? 'presentation' : 'table',
							className: classes,
							...props
						})}
						striped={striped}
						size={size}
						responsive={responsive}
						bordered={bordered}
						borderless={borderless}
						data-testid={dataTestId}>
						{typeof caption === 'string' ? <caption>{caption}</caption> : caption}
						{type === 'vertical' ? (
							<VerticalTable
								headerGroups={headerGroups}
								isPresentationTable={isPresentationTable}
								getTableBodyProps={getTableBodyProps}
								paginationEnabled={paginationEnabled}
								rows={rows}
								page={page}
								prepareRow={prepareRow}
								renderRowSubComponent={subRow}
								visibleColumns={visibleColumns}
								loading={loading}
							/>
						) : (
							<HorizontalTable rows={rows} prepareRow={prepareRow} headerGroups={headerGroups} footerGroups={footerGroups} />
						)}
					</Table>
				</FilterContext.Provider>

				{!loading && paginationEnabled ? (
					<Pagination
						rows={rows}
						rowCount={rowCount}
						pageCount={pageCount}
						pageIndex={pageIndex}
						pageSize={pageSize}
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						setPageSize={setPageSize}
						gotoPage={gotoPage}
						previousPage={previousPage}
						nextPage={nextPage}
						menuOptions={lengthMenu}
					/>
				) : null}
			</TranslatableTextProvider>
		</div>
	);
}

Datatable.propTypes = {
	/**
	 * Table column definitions
	 */
	columns: PropTypes.array,

	/**
	 * Table data definitions
	 */
	data: PropTypes.array,

	/**
	 * Enable/Disable table filtering
	 */
	tableFilter: PropTypes.bool,

	/**
	 * Make tables more compact by cutting cell padding in half by setting size as `sm`.
	 */
	size: PropTypes.oneOf(['sm']),

	/**
	 * Enable/Disable table sorting
	 */
	sortable: PropTypes.bool,

	/**
	 * Adds zebra-striping to the table rows
	 */
	striped: PropTypes.bool,

	/**
	 * Enable/Disable table pagination
	 */
	paginate: PropTypes.bool,

	/**
	 * Enable/Disable row selection
	 */
	rowSelection: PropTypes.bool,

	/**
	 * An array specifying the number of rows to display at a time. The array value provide both the number of rows to display as well as a text equivalent for each value. Non numeric values will be treated as all.<br/>
	 * Ex) [<br/>
	 *  [number of rows to display],<br/>
	 *  [text description of number of rows to display]<br/>
	 * ]
	 */
	lengthMenu: menuOptionType,

	/**
	 * Optional table caption
	 */
	caption: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

	/**
	 * Enable/Disable table responsiveness at all or some breakpoints
	 */
	responsive: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['sm', 'md', 'lg', 'xl'])]),

	/**
	 * Define 0 to many "action" buttons to display inline with the global filter
	 */
	actionButtons: componentArrayChildrenType,

	/**
	 * Define 0 to many "filters" to display inline with the global filter
	 */
	customFilters: componentArrayChildrenType,

	/**
	 * Specify initial table sorting
	 * initialSort={
	 *     [
	 *         {
	 *             id: 'columnId',
	 *             desc: false
	 *         }
	 *     ]
	 * }
	 */
	initialSort: PropTypes.array,

	/**
	 * Specify initial table filtering
	 * preFilteredColumns={
	 *     [
	 *         {
	 *             id: 'columnId',
	 *             value: 'value'
	 *         }
	 *     ]
	 * }
	 */
	initialFilteredColumns: PropTypes.array,

	/**
	 * render callback for expandable table rows.  Accepts row argument
	 */
	renderRowSubComponent: PropTypes.func,

	/**
	 * Initially selected rows. Object keys are the index of the selected row, with a value of true. example: { "0": true }
	 */
	selectedRows: PropTypes.object,
	/**
	 * row selection callback. lists all currently selected rows.
	 */
	onSelectedRows: PropTypes.func,

	/**
	 * Type of table of render 'vertical' or horizontal
	 */
	type: PropTypes.string,

	/**
	 * Data-attribute for testing
	 */
	'data-testid': PropTypes.string,

	/**
	 * options supplied from `useServerSideTable` hook
	 */
	useTableOptions: PropTypes.object,

	/**
	 * enable/disable loading state view for the table
	 */
	loading: PropTypes.bool,

	/**
	 * table element `role` attribute
	 */
	role: PropTypes.string,

	/**
	 * Adds borders on all sides of the table and cells.
	 */
	bordered: PropTypes.bool,

	/**
	 * Removes all borders on the table and cells, including table header.
	 */
	borderless: PropTypes.bool,

	/**
	 * row selection configuration
	 */
	rowSelectionOptions: PropTypes.object,

	/**
	 * row expansion configuration
	 */
	rowExpansionOptions: PropTypes.object,

	/**
	 * Datatable className.
	 */
	className: PropTypes.string,

	/**
	 * Event handler called after filter was cleared
	 */
	onFilterClear: PropTypes.func,

	/**
	 * Method to perform value-label mapping to display human-readable applied filters
	 */
	filterLabelsMapping: PropTypes.func,

	...translatableTextPropTypes
};

Datatable.defaultProps = {
	tableFilter: true,
	sortable: true,
	striped: false,
	paginate: true,
	responsive: true,
	initialSort: [],
	selectedRows: {},
	type: 'vertical',
	loading: false
};
