import React, { ForwardedRef } from 'react';
import { CustomizedButtonProps } from './types';
import CustomizedButtonWrapper from './CustomizedButtonWrapper';

export const SaveButtonDefaultText = 'Save';

const SaveButton = React.forwardRef(({ children = SaveButtonDefaultText, ...props }: CustomizedButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
	return (
		<CustomizedButtonWrapper variant="primary" icon={null} ref={ref} {...props}>
			{children}
		</CustomizedButtonWrapper>
	);
});
SaveButton.displayName = 'SaveButton';

export default SaveButton;
