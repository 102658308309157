import { useEffect } from 'react';
import { inlineEditStateChangeTypes } from '../inlineEditActions';
import { UseInlineEditEventHandlersProps } from '../types';

export const useEventHandlers = (props: UseInlineEditEventHandlersProps): void => {
	const { containerRef, fieldRef, latest, state, dispatch, formContext, onClickOutside } = props;

	// auto focus input when it gets shown
	useEffect(() => {
		// for some reason, using `latest` isEdit value doesnt cause this to trigger
		if (fieldRef.current && state.isEdit) {
			fieldRef.current?.focus();
		}
	}, [state, fieldRef]);

	// close editable input on click outside
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (latest.current.state.isEdit) {
				const clickedWithinPopover = event.composedPath().find(element => (element as Element).classList?.contains('popover'));
				if (containerRef.current && !containerRef.current.contains(event.target as Node) && !clickedWithinPopover) {
					formContext.setValue(latest.current.state.selectedValue);
					dispatch({ type: inlineEditStateChangeTypes.OnClickOutside });
					onClickOutside?.();
				}
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dispatch, latest, containerRef, formContext, onClickOutside]);
};
