import React from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../../helpers';
import { IconExpand, IconLoading } from '@optic-delight/icons';
import Button from '../../button/Button';

const defaultRenderAddOn = props => {
	return (
		<button type="button" className="input-group-append input-group-text" {...props} aria-label="toggle menu">
			<IconExpand />
		</button>
	);
};
const ComboboxInput = React.forwardRef(
	(
		{
			loading,
			placeholder = loading ? 'Loading...' : 'Select...',
			useComboboxOptions,
			getDropdownProps,
			clearFilteredItems,
			isInvalid,
			renderAddOn,
			suppressRef,
			...props
		},
		ref
	) => {
		const { isOpen, openMenu, getInputProps, getComboboxProps, getToggleButtonProps } = useComboboxOptions;
		const inputProps = getInputProps(
			getDropdownProps(
				{
					ref,
					...props,
					disabled: loading ? true : props.disabled,
					className: [isInvalid && 'is-invalid', 'form-control'].filter(Boolean).join(' '),
					onFocus: () => {
						// open listbox menu when the input receives focus
						!isOpen && openMenu();
					}
				},
				suppressRef ? { suppressRefError: true } : undefined
			),
			suppressRef ? { suppressRefError: true } : undefined
		);

		const toggleProps = getToggleButtonProps({
			disabled: inputProps.disabled,
			onClick: () => !isOpen && clearFilteredItems()
		});

		// hack to prevent auto focus on input for open combobox menus. nothing worked trying to remove "ref" from getInputProps otherwise
		if (suppressRef) {
			inputProps.ref = null;
		}

		return (
			<div className={`input-group input-group-embedded ${isInvalid ? 'is-invalid ' : ''}`} {...getComboboxProps()}>
				<input placeholder={placeholder} aria-invalid={isInvalid} {...inputProps} value={loading ? '' : inputProps.value} />
				{loading ? (
					<Button aria-label={placeholder} disabled className="input-group-text">
						<IconLoading data-testid="icon-loading" role="status" />
					</Button>
				) : (
					renderAddOn(toggleProps)
				)}
			</div>
		);
	}
);
ComboboxInput.displayName = 'ComboboxInput';

ComboboxInput.defaultProps = {
	clearFilteredItems: noop, // defaulted for multiselect usage
	getDropdownProps: args => args, // defaulted for combobox usage
	renderAddOn: defaultRenderAddOn,
	suppressRef: false
};

ComboboxInput.propTypes = {
	useComboboxOptions: PropTypes.object.isRequired,
	clearFilteredItems: PropTypes.func,
	getDropdownProps: PropTypes.func,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	isInvalid: PropTypes.bool,
	innerRef: PropTypes.object,
	renderAddOn: PropTypes.func,
	/**
	 * For Listbox component to prevent autofocus on "open" combobox menu
	 */
	suppressRef: PropTypes.bool,
	loading: PropTypes.bool,
	placeholder: PropTypes.string
};

export default ComboboxInput;
