import React from 'react';
import ReactDOM from 'react-dom';
import { TooltipPortalProps } from './types';

export const tooltipPortalTestIdPrefix = 'tooltip-portal-';

export function directionToLogical(value: string): string {
	if (value === 'right') {
		return 'end';
	} else if (value === 'left') {
		return 'start';
	}
	return value;
}

export const TooltipPortal = React.forwardRef<HTMLDivElement, TooltipPortalProps>(
	({ transition, className, children, placement, arrowProps, ...props }, ref) => {
		const side: string = directionToLogical(placement.split('-')[0]);

		const tooltipClasses = [className, 'tooltip fade', transition && 'show', `bs-tooltip-${side}`].filter(Boolean).join(' ');

		const child = (
			<div ref={ref} role="tooltip" className={tooltipClasses} data-testid={`${tooltipPortalTestIdPrefix}${props.id}`} {...props}>
				<div className="tooltip-arrow" {...arrowProps} />
				<div className="tooltip-inner">{children}</div>
			</div>
		);

		return ReactDOM.createPortal(child, document.body);
	}
);
TooltipPortal.displayName = 'TooltipContent';
