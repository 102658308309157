import React from 'react';
import GlobalFilter from './global/GlobalFilter';
import ComponentArray from '../components/ComponentArray';
import { FilterResults } from './results/FilterResults';
import { ColumnInstance, FilterValue } from 'react-table';
import { rowSpacer } from './utility';

interface TableFiltersProps {
	globalFilterEnabled: boolean;
	globalFilter: string | (() => void);
	setGlobalFilter: (filterValue: FilterValue) => void;
	customFilters: never;
	filterLabelsMapping?: (columnId: string, value: unknown) => string | undefined;
	actionButtons: never;
	useTableColumns: Array<ColumnInstance>;
	loading: boolean;
	rowCount: number;
}

export const TableFilters = ({
	globalFilterEnabled,
	globalFilter,
	setGlobalFilter,
	customFilters,
	actionButtons,
	useTableColumns,
	loading,
	filterLabelsMapping,
	rowCount
}: TableFiltersProps): JSX.Element => {
	return (
		<>
			{globalFilterEnabled || customFilters || actionButtons ? (
				<div className="row">
					{globalFilterEnabled ? (
						<div className={`col-auto ${rowSpacer}`}>
							<GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} readOnly={loading} />
						</div>
					) : null}
					{customFilters ? (
						<div className="col-auto me-auto">
							<ComponentArray columns={useTableColumns} readOnly={loading}>
								{customFilters}
							</ComponentArray>
						</div>
					) : null}
					{actionButtons ? (
						<div className="col-auto ms-md-auto">
							<ComponentArray columns={useTableColumns}>{actionButtons}</ComponentArray>
						</div>
					) : null}
				</div>
			) : null}
			{loading ? null : (
				<FilterResults
					filterLabelsMapping={filterLabelsMapping}
					searchCriteria={globalFilter as string}
					columns={useTableColumns}
					resultsLength={rowCount}
				/>
			)}
		</>
	);
};
TableFilters.displayName = 'TableFilters';
