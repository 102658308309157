import React from 'react';
import { InlineHelper } from '../components';

export function useImage(image) {
	return (
		<InlineHelper className="col-auto">
			<img alt={image.alt} src={image.src} {...image} />
		</InlineHelper>
	);
}
