import React from 'react';
import { FormSelect, FormSelectProps } from 'react-bootstrap';
import { useTranslatableText } from '../../translatable-text';

type TupleOfSameLengthArrays = [Array<number>, Array<string | number>];
interface LengthMenuProps extends FormSelectProps {
	menuOptions: TupleOfSameLengthArrays;
	totalItems: number;
	value: string | number;
}

export function LengthMenu({
	totalItems,
	menuOptions = [
		[25, 50, 75, 100, -1],
		[25, 50, 75, 100, 'All']
	],
	value = 25,
	...props
}: LengthMenuProps): JSX.Element {
	const { lengthMenuShowText, lengthMenuOfText } = useTranslatableText();
	function lengthMenuOptions(menu: TupleOfSameLengthArrays, totalRows: number) {
		const pageSizes = menu[0];
		const descriptions = menu[1];

		return pageSizes.map((size, i) => (
			<option key={size} value={isNaN(size) || size <= 0 ? totalRows : size}>
				{descriptions[i]}
			</option>
		));
	}
	const smallestPageSize = menuOptions[0][0];

	return (
		<div className="d-flex justify-content-start align-items-center" data-testid="length-menu">
			<span className="me-2">{lengthMenuShowText}</span>
			{totalItems < smallestPageSize ? (
				<span className="me-2">{totalItems}</span>
			) : (
				<FormSelect className="me-2" value={value} aria-label={'Show ' + value + ' entries'} {...props}>
					{lengthMenuOptions(menuOptions, totalItems)}
				</FormSelect>
			)}
			<span className="me-2">{lengthMenuOfText}</span>
			<span className="me-2">{totalItems}</span>
		</div>
	);
}
