import { FetchTokenPromiseProps, JwtTokenProps, RefreshTokenProps, StorageKeyProp } from '../types';

export const createTokenManager = (storageKey: StorageKeyProp = null) => {
	let localStorageKey = storageKey;
	let jwtToken: JwtTokenProps = null;
	let refreshToken: RefreshTokenProps = null;
	let fetchTokenPromise: FetchTokenPromiseProps = null;

	const setLocalStorageKey = (key: string) => {
		if (localStorageKey !== null && localStorageKey !== key) {
			clearTokens();
		}
		localStorageKey = key || null;
	};

	const getToken = () => jwtToken;
	const setToken = (token: string) => {
		jwtToken = token;
	};

	const getRefreshToken = () => (localStorageKey !== null ? window.localStorage.getItem(localStorageKey) : refreshToken);

	const setRefreshToken = (token: string) => {
		if (localStorageKey !== null) {
			window.localStorage.setItem(localStorageKey, token);
		} else {
			refreshToken = token;
		}
	};

	const clearTokens = () => {
		jwtToken = null;
		refreshToken = null;
		window.localStorage.removeItem(localStorageKey as string);
	};

	const isFetchingToken = () => Boolean(fetchTokenPromise);
	const fetchToken = () => fetchTokenPromise;
	const setTokenFetcher = (fetcher: Promise<unknown>) => {
		fetchTokenPromise = fetcher.finally(() => {
			fetchTokenPromise = null;
		});
	};

	return {
		setLocalStorageKey,
		getToken,
		setToken,
		getRefreshToken,
		setRefreshToken,
		clearTokens,
		fetchToken,
		isFetchingToken,
		setTokenFetcher
	};
};

export default createTokenManager();
