import React from 'react';

import { CardImageProps } from './types';

const CardImage = ({ src, alt, className, variant, ...props }: CardImageProps): JSX.Element => {
	const cardImageClasses = ['card-img', variant && `-${variant}`].filter(Boolean).join('');
	const classes = [className, cardImageClasses].filter(Boolean).join(' ');
	return <img src={src} alt={alt} className={classes} {...props} />;
};

CardImage.displayName = 'CardImage';

export default CardImage;
