/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import { APPLICATION_ROOT } from '../utility';
import { AxeRegisterConfigProps, AxeRegisterContextProps, AxeLogger } from './types';

// By default run a11y compliance across the entire application
const DEFAULT_APP_CONTEXT = {
	include: [[`#${APPLICATION_ROOT}`]]
};

const DEFAULT_CONFIG = {
	branding: {
		// optional: adds application branding to the vulnerability URLs when issues are logged
		application: process.env.REACT_APP_NAME
	},
	runOnly: ['wcag21aa', 'wcag21a', 'wcag2aaa', 'wcag2aa', 'wcag2a'],
	disableDeduplicate: false
};

export const AXE_TIMEOUT = 1000;

/**
 * initiates accessibility testing within the application.  Defaults to analyzing the entire app from the root id.
 *
 * implementation uses @axe-core/react
 * https://github.com/dequelabs/axe-core-npm/tree/develop/packages/react
 *
 * @param context https://github.com/dequelabs/axe-core/blob/master/doc/API.md#context-parameter
 * @param config https://github.com/dequelabs/axe-core/blob/master/doc/API.md#api-name-axeconfigure
 */
function axeRegister(context: AxeRegisterContextProps = DEFAULT_APP_CONTEXT, config: AxeRegisterConfigProps = DEFAULT_CONFIG) {
	const derivedConfig = {
		...DEFAULT_CONFIG,
		...config
	};

	const derivedContext = {
		...DEFAULT_APP_CONTEXT,
		...(context as object)
	};

	const loggerFunc: AxeLogger = results => {
		if (results.violations.length) {
			const propertyStyles = 'font-weight: 700;';
			console.group('%c Accessibility issues found ', 'background: white; color: red;');
			results.violations.forEach(violation => {
				console.groupCollapsed(violation.help);
				console.log('%cdescription:', propertyStyles, violation.description);
				console.log('%cimpact:', propertyStyles, violation.impact);
				console.log('%chelpUrl:', propertyStyles, violation.helpUrl);
				console.log('%ctags:', propertyStyles, violation.tags.join(', '));
				violation.nodes.map(node => {
					return node.target.map(selector => {
						return console.dir(document.querySelectorAll(selector));
					});
				});
				console.groupEnd();
			});
			console.groupEnd();
		} else {
			console.log('%c No accessibility issues found ', 'background: lightblue; color: blue;');
		}
	};

	if (process.env.NODE_ENV !== 'production') {
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const axe = require('@axe-core/react');
		axe(React, ReactDOM, AXE_TIMEOUT, derivedConfig, derivedContext, loggerFunc);
	}
}

export default axeRegister;
