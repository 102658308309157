import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@optic-delight/icons';
import { FilePreviewProps } from './types';

const FilePreview = ({ file }: FilePreviewProps) => {
	const previewRef = React.useRef<HTMLImageElement>(null);

	const previewFactory = (aFile: File) => {
		const fileType = aFile.type || '';
		const mimeTypePrefix = fileType.substring(0, fileType.indexOf('/'));
		switch (mimeTypePrefix) {
			case 'image':
				return (
					<img
						ref={previewRef}
						alt={aFile.name}
						src={URL.createObjectURL(aFile)}
						onLoad={() => previewRef?.current?.src && URL.revokeObjectURL(previewRef?.current?.src)}
						style={{ maxHeight: '50px', maxWidth: '100%' }}
						data-testid={process.env.NODE_ENV === 'test' ? 'image-preview' : undefined}
					/>
				);
			case 'audio':
				return <Icon size="3x" icon="music" data-testid={process.env.NODE_ENV === 'test' ? 'audio-preview' : undefined} />;
			case 'video':
				return <Icon size="3x" icon="film" data-testid={process.env.NODE_ENV === 'test' ? 'video-preview' : undefined} />;
			default:
				return <Icon size="3x" icon="file" data-testid={process.env.NODE_ENV === 'test' ? 'file-preview' : undefined} />;
		}
	};

	return previewFactory(file);
};
FilePreview.displayName = 'FilePreview';

FilePreview.propTypes = {
	file: PropTypes.object
};

export default FilePreview;
