import React from 'react';
import Alert from './Alert';
import { AlertVariantProps } from './types';

/**
 *`<InfoAlert>` is an `<Alert>` with defaulted variant, header, description properties.
 */
const InfoAlert = React.forwardRef<HTMLDivElement, AlertVariantProps>(({ heading = 'Information', ...props }, ref) => {
	return <Alert heading={heading} ref={ref} variant="info" iconVariant {...props} />;
});
InfoAlert.displayName = 'InfoAlert';

export default InfoAlert;
