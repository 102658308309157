import PropTypes from 'prop-types';

export const menuOptionType = PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
	if (
		!Array.isArray(propValue) ||
		propValue.length !== 2 ||
		!Array.isArray(propValue[0]) ||
		!Array.isArray(propValue[1]) ||
		propValue[0].length !== propValue[1].length ||
		propValue[0].some(isNaN)
	) {
		return new Error(`Invalid prop ${propFullName} supplied to ${componentName}. Validation failed.`);
	}
});

export const componentArrayChildrenType = PropTypes.oneOfType([
	PropTypes.element,
	PropTypes.func,
	PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.func]))
]);
