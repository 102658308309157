import { inlineEditStateChangeTypes } from './inlineEditActions';
import { InlineEditReducerAction, InlineEditReducerState } from './types';

export const inlineEditReducer = (state: InlineEditReducerState, { type, ...action }: InlineEditReducerAction) => {
	let changes;
	switch (type) {
		case inlineEditStateChangeTypes.EditMode:
			changes = {
				isEdit: true,
				currentValue: state.selectedValue
			};
			break;
		case inlineEditStateChangeTypes.OnClickOutside:
		case inlineEditStateChangeTypes.CancelEdit:
		case inlineEditStateChangeTypes.ReadMode:
			changes = {
				isEdit: false
			};
			break;
		case inlineEditStateChangeTypes.ConfirmEdit:
		case inlineEditStateChangeTypes.SetValue:
			changes = {
				isEdit: false,
				dirty: true,
				isInvalid: action.required && (typeof action.selectedValue === 'undefined' || (action.selectedValue as []).length === 0),
				selectedValue: action.selectedValue
			};
			break;
		case inlineEditStateChangeTypes.SetCurrentValue:
			changes = {
				dirty: true,
				currentValue: action.currentValue
			};
			break;
		default:
			throw new Error(`Unhandled action type: ${type}`);
	}

	return {
		...state,
		...changes
	};
};
