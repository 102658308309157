import React, { ForwardedRef } from 'react';
import { CustomizedButtonProps } from './types';
import CustomizedButtonWrapper from './CustomizedButtonWrapper';

export const PreviousButtonDefaultText = 'Previous';

const PreviousButton = React.forwardRef(({ children = PreviousButtonDefaultText, ...props }: CustomizedButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
	return (
		<CustomizedButtonWrapper variant="neutral" icon="back" iconDirection="prepend" ref={ref} {...props}>
			{children}
		</CustomizedButtonWrapper>
	);
});
PreviousButton.displayName = 'PreviousButton';

export default PreviousButton;
