import React from 'react';

import { ListGroupItemCheckableProps } from './types';
import ListGroupItemCheckable from './ListGroupItemCheckable';

const ListGroupItemCheckbox = (props: ListGroupItemCheckableProps): JSX.Element => <ListGroupItemCheckable type="checkbox" {...props} />;

ListGroupItemCheckbox.displayName = 'ListGroupItemCheckbox';

export default ListGroupItemCheckbox;
