import React, { MutableRefObject, useCallback } from 'react';
import { UseActiveItemData, UseActiveItemGetLinkPropsData, UseActiveItemProps } from '../types';

export function useActiveItem({ eventKey, href, activeKey, setActiveContent }: UseActiveItemProps): UseActiveItemData {
	const mounted = React.useRef<boolean>(false);
	const itemRef = React.useRef<HTMLAnchorElement>() as MutableRefObject<HTMLAnchorElement>;
	const itemKey = eventKey ? eventKey : href;

	React.useEffect(() => {
		if (!mounted.current && activeKey === itemKey) {
			mounted.current = true;
			setActiveContent({
				key: itemKey,
				content: itemRef.current.textContent
			});
		}

		return () => {
			mounted.current = false;
		};
	}, [activeKey, itemKey, setActiveContent]);

	const isActive = useCallback(() => {
		return !!(activeKey && (activeKey === itemKey || (eventKey && activeKey === href)));
	}, [activeKey, itemKey, eventKey, href]);

	const getLinkProps = useCallback(
		(overrideProps: React.AnchorHTMLAttributes<HTMLAnchorElement>): UseActiveItemGetLinkPropsData => {
			return {
				ref: itemRef,
				'aria-current': isActive() ? 'page' : undefined,
				href,
				...overrideProps
			};
		},
		[href, isActive]
	);

	return {
		eventKey: itemKey,
		isActive,
		getLinkProps
	};
}
