import { CSSProperties } from 'react';
import { customCSSProperties } from '../utility';

export interface LayerStyleProps extends CSSProperties {
	inverse?: string; // any valid CSS color value
}

export function useLayerCounterStyle(style: LayerStyleProps = {}): CSSProperties {
	const { backgroundColor, borderRadius, lineHeight, maxWidth, minWidth, padding, scale, top, bottom, left, right, inverse, ...cssProperties } = style;
	return {
		[customCSSProperties.backgroundColor]: backgroundColor,
		[customCSSProperties.borderRadius]: borderRadius,
		[customCSSProperties.lineHeight]: lineHeight,
		[customCSSProperties.maxWidth]: maxWidth,
		[customCSSProperties.minWidth]: minWidth,
		[customCSSProperties.padding]: padding,
		[customCSSProperties.scale]: scale,
		[customCSSProperties.top]: top,
		[customCSSProperties.bottom]: bottom,
		[customCSSProperties.right]: right,
		[customCSSProperties.left]: left,
		[customCSSProperties.inverse]: inverse,
		...cssProperties
	};
}
