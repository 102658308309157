import awsSigV4 from './awsSigV4';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { configProp } from '../types';
import { AWS_SIGV4_INTERCEPTOR_REGION, AWS_SIGV4_INTERCEPTOR_SERVICE } from './constants';

export const applyAwsSigV4Interceptor = (client: AxiosInstance, accessKey: string, secretKey: string) => {
	return client.interceptors.request.use<Omit<AxiosRequestConfig, 'headers'>>(
		(config: configProp) => {
			const time = Date.now();
			const signingHeaders: Record<string, string> = {
				// const signingHeaders = {
				...config.headers,
				'X-Amz-Date': awsSigV4.toTime(time)
			};
			Object.keys(signingHeaders).forEach(key => {
				if (typeof signingHeaders[key] !== 'string') {
					delete signingHeaders[key];
				}
			});
			const signedHeaders = awsSigV4.createSignedHeaders(signingHeaders);
			const canonicalRequest = awsSigV4.createCanonicalRequest(config.method as string, `${config.baseURL}${config.url}`, '', signingHeaders, '');
			const signedString = awsSigV4.createStringToSign(time, AWS_SIGV4_INTERCEPTOR_REGION, AWS_SIGV4_INTERCEPTOR_SERVICE, canonicalRequest);
			const signature = awsSigV4.createSignature(secretKey, time, AWS_SIGV4_INTERCEPTOR_REGION, AWS_SIGV4_INTERCEPTOR_SERVICE, signedString);

			if (config.headers) {
				config.headers.common = { ...(config.headers.common as object) };
				config.headers.common['X-Amz-Date'] = signingHeaders['X-Amz-Date'];
				config.headers.common['Authorization'] = awsSigV4.createAuthorizationHeader(
					accessKey,
					`${awsSigV4.toDate(time)}/${AWS_SIGV4_INTERCEPTOR_REGION}/${AWS_SIGV4_INTERCEPTOR_SERVICE}/aws4_request`,
					signedHeaders,
					signature
				);
			}

			return config;
		},
		error => Promise.reject(error)
	);
};
