import { useCallback, useEffect, useRef } from 'react';
import { createAxiosInstance } from '../../http-client';
import { LIVE_KINNECT_INSTANCE_DEFAULTS } from '../LiveKinnectClient';
import { getAccessTokens } from '../utility/getAccessTokens';
import { useCustomer } from '../../customer';
import { GetUpdatedTokenProps, configProp } from '../types';
import { MISSING_REFRESH_TOKEN_MESSAGE, MISSING_ACCESS_TOKEN_MESSAGE } from '../utility/constants';

const useLiveKinnectAuthorizedClient = () => {
	const { customer, tokenManager } = useCustomer();
	const liveKinnectAuthorizedClientRef = useRef(createAxiosInstance(LIVE_KINNECT_INSTANCE_DEFAULTS));

	const getUpdatedToken = useCallback<GetUpdatedTokenProps>(
		async (refreshToken = undefined) => {
			// accessKey value is unimportant to the backend as the active session is whats ultimately validated against
			const response = await getAccessTokens('JSESSIONID', customer.csrf, refreshToken || undefined);
			if (!response.data?.token) {
				throw new Error(MISSING_ACCESS_TOKEN_MESSAGE);
			}
			tokenManager.setToken(response.data.token);
			tokenManager.setRefreshToken(response.data?.refreshToken);
		},
		[customer, tokenManager]
	);

	const refreshAccessToken = useCallback(async () => {
		if (!tokenManager.getRefreshToken()) {
			throw new Error(MISSING_REFRESH_TOKEN_MESSAGE);
		}
		return getUpdatedToken(tokenManager.getRefreshToken());
	}, [getUpdatedToken, tokenManager]);

	const getAccessToken = useCallback(async () => {
		if (!tokenManager.getToken()) {
			if (!tokenManager.isFetchingToken()) {
				if (tokenManager.getRefreshToken()) {
					tokenManager.setTokenFetcher(refreshAccessToken());
				} else {
					tokenManager.setTokenFetcher(getUpdatedToken());
				}
			}
			await tokenManager.fetchToken();
		}
	}, [getUpdatedToken, refreshAccessToken, tokenManager]);

	useEffect(() => {
		const liveKinnectAuthorizedClient = liveKinnectAuthorizedClientRef.current;
		// set auth token before each request
		liveKinnectAuthorizedClient.interceptors.request.use(
			async (config: configProp) => {
				await getAccessToken();
				if (config.headers) {
					config.headers['Authorization'] = `Bearer ${tokenManager.getToken()}`;
				}
				return config;
			},
			error => Promise.reject(error)
		);

		// refresh access tokens, and retry request
		liveKinnectAuthorizedClient.interceptors.response.use(
			response => {
				return response;
			},
			async function (error) {
				const originalRequest = error.config;
				if (originalRequest) {
					if (
						!originalRequest._retry &&
						error.response.status === 401 &&
						error.response.data?.errors?.some(
							(err: { type: string; message: string }) => err.type === 'AUTHENTICATION_ERROR' && err.message.match(/jwt token.*expired/i)
						)
					) {
						originalRequest._retry = true;
						try {
							await refreshAccessToken();
						} catch (refreshError) {
							if (refreshError instanceof Error) {
								if (refreshError?.message === MISSING_REFRESH_TOKEN_MESSAGE) {
									tokenManager.clearTokens();
									await getUpdatedToken();
								}
							}
						}
						return liveKinnectAuthorizedClient(originalRequest);
					} else if (originalRequest._retry && (error.response.status === 401 || error.response.status === 403)) {
						// request was retried and still resulted in an unauthorized/forbidden error, clear out access tokens
						tokenManager.clearTokens();
					}
				}
				return Promise.reject(error);
			}
		);
	}, [liveKinnectAuthorizedClientRef, getAccessToken, refreshAccessToken, tokenManager, getUpdatedToken]);

	return liveKinnectAuthorizedClientRef.current;
};

export default useLiveKinnectAuthorizedClient;
