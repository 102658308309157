import * as React from 'react';
import { useIconMap } from '../shared/hooks';
import { spriteIconPrefix, spriteUtilityClasses } from './utility/constants';
import { supportedThemes, createClassWithSuffix } from '../shared';
import { SpriteIconName, SpriteIconProps } from './types';
import FallbackIcon from '../font-icon/FallbackIcon';
import { resolveTag } from '../tag-resolver';

const spriteSizeClassNameMapping = {
	xs: 'xs',
	sm: 'sm',
	md: 'md',
	lg: 'lg',
	'1x': 'xs',
	'2x': 'sm',
	'3x': 'md',
	'4x': 'lg'
};

const SpriteIcon = React.forwardRef<HTMLElement, SpriteIconProps>((props, ref): JSX.Element | null => {
	const { icon: initialIcon, size = 'sm', className, tag, iconFallback = true, ...iconProps } = props;

	let currentIcon = initialIcon;
	const tagMatchIcon = resolveTag(tag, supportedThemes.fullColor);
	if (tagMatchIcon) {
		currentIcon = tagMatchIcon as SpriteIconName;
	}

	if (!currentIcon && iconFallback) {
		return <FallbackIcon ref={ref} data-testid="fallback-icon" />;
	}

	const { icon } = useIconMap(currentIcon, supportedThemes.fullColor);
	const classes = [
		className,
		spriteIconPrefix,
		`${spriteIconPrefix}-${icon}`,
		createClassWithSuffix(spriteUtilityClasses.size, spriteSizeClassNameMapping[size])
	]
		.filter(Boolean)
		.join(' ');

	return <i aria-hidden="true" ref={ref} className={classes} {...iconProps} />;
});

SpriteIcon.displayName = 'SpriteIcon';

export default SpriteIcon;
