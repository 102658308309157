import React from 'react';
import { UserInterfaceTypeResolver } from '../types';

export const listWithTitleResolver: UserInterfaceTypeResolver = ({ contract, level }, { process }) => {
	if (contract.type === 'list' && contract.title) {
		return [
			React.Fragment,
			{
				children: process({
					contract: [
						{
							type: 'block',
							children: [
								{
									type: 'text',
									title: contract.title
								},
								{
									type: 'list',
									children: contract.children
								}
							]
						}
					],
					level: level++
				})
			}
		];
	}

	return [];
};
