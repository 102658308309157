import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as BootstrapPagination } from 'react-bootstrap';
import { PreviousPageItem } from './PreviousPageItem';
import { NextPageItem } from './NextPageItem';
import { PaginationItems } from './PaginationItems';
import { LengthMenu } from './LengthMenu';
import { menuOptionType } from '../../types/propTypes';

/**
 * - Features
 *  - number of table entries
 *  - number of rows per page
 *  - page navigation
 *  - Full react-table documentation can be found on their github page [usePagination api](https://github.com/tannerlinsley/react-table/blob/v7.0.4/docs/api/usePagination.md)
 */

export default function Pagination({
	rows,
	rowCount,
	pageCount,
	pageIndex,
	pageSize,
	canPreviousPage,
	canNextPage,
	setPageSize,
	gotoPage,
	previousPage,
	nextPage,
	menuOptions
}) {
	return (
		<div className="d-flex flex-wrap pb-3">
			<LengthMenu
				menuOptions={menuOptions}
				value={pageSize}
				totalItems={rowCount ?? rows.length}
				onChange={e => {
					setPageSize(Number(e.target.value));
				}}
			/>
			{pageCount > 1 ? (
				<nav aria-label="Page Navigation" className="ms-auto justify-content-end align-items-end">
					<BootstrapPagination bsPrefix="pagination m-0">
						<PreviousPageItem pageIndex={pageIndex} onClick={previousPage} />
						<PaginationItems
							pageIndex={pageIndex}
							pageCount={pageCount}
							onClick={gotoPage}
							canPreviousPage={canPreviousPage}
							canNextPage={canNextPage}
						/>
						<NextPageItem pageIndex={pageIndex} pageCount={pageCount} onClick={nextPage} />
					</BootstrapPagination>
				</nav>
			) : null}
		</div>
	);
}

Pagination.propTypes = {
	/**
	 * An array of rows for the current page
	 */
	rows: PropTypes.array,

	/**
	 * `rows` length override for controlled table processing
	 */
	rowCount: PropTypes.number,

	/**
	 * Required if manualPagination is set to true
	 */
	pageCount: PropTypes.number,

	/**
	 * Required
	 */
	pageIndex: PropTypes.number,

	/**
	 * This is the current pageSize value, located on the state
	 */
	pageSize: PropTypes.number,

	/**
	 * If there are pages and the current pageIndex is greater than 0, this will be true
	 */
	canPreviousPage: PropTypes.bool,

	/**
	 * If there are pages and the current pageIndex is less than pageCount, this will be true
	 */
	canNextPage: PropTypes.bool,

	/**
	 * This function sets state.pageSize to the new value.
	 */
	setPageSize: PropTypes.func,

	/**
	 * This function, when called with a valid pageIndex, will set pageIndex to that value.
	 */
	gotoPage: PropTypes.func,

	/**
	 * This function decreases state.pageIndex by one
	 */
	previousPage: PropTypes.func,

	/**
	 * This function increases state.pageIndex by one.
	 */
	nextPage: PropTypes.func,

	/**
	 *An array specifying the number of rows to display at a time. The array value provide both the number of rows to display as well as a text equivalent for each value. Non numeric values will be treated as all.<br/>
	 *Ex) [<br/>
	 *[number of rows to display],<br/>
	 *[text description of number of rows to display]<br/>
	 *]
	 */
	menuOptions: menuOptionType
};

Pagination.defaultProps = {
	pageIndex: 0,
	pageCount: 1
};
