import React from 'react';
import { BreadcrumbItemProps } from './types';
import { breadcrumbBaseClass, breadcrumbCondensedContent } from './utility/constants';

export function BreadcrumbItem({ active, as: Component = 'a', href, children, condensed, ...props }: BreadcrumbItemProps) {
	const listItemClasses = [`${breadcrumbBaseClass}-item`, active && 'active'].filter(Boolean).join(' ');
	const isCondensed = !active && condensed;

	return (
		<li className={listItemClasses} aria-current={active ? 'page' : undefined}>
			{active ? (
				children
			) : (
				<Component href={href} tabIndex={-1} role={href ? undefined : 'button'} title={isCondensed ? children : undefined} {...props}>
					{isCondensed ? breadcrumbCondensedContent : children}
				</Component>
			)}
		</li>
	);
}
