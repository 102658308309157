import React, { ChangeEvent, ForwardedRef } from 'react';
import { useSelect } from 'downshift';
import { IGroupedListOption, SelectProps } from './types';
import FormGroupValidation from '../shared/FormGroupValidation';
import useFormGroupValidation from '../shared/hooks/useFormGroupValidation';
import { inputDefaultProps } from '../../../props/inputProps';
import { noop } from '../../../helpers';
import OptionsList from './OptionsList';
import { calculatePlainItems, useSearchableItemsList } from './hooks';
import Search from '../search/Search';
import { getSelectMenuHeight, isInHiddenGroup, selectInputReducerUtils, updateSelectReducerChanges } from './utility';
import { InputProps } from '../types';
import { IconLoading } from '@optic-delight/icons';
import { useControlledFocusOnError } from '../shared/hooks/useControlledFocusOnError';
import { useFlippedDropdownMenu } from '../../../helpers/hooks/useFlippedDropdownMenu';

export const SelectPlaceholder = 'Select...';
export const SelectLoadingPlaceholder = 'Loading...';
export const SelectSearchDefaultPlaceholder = 'Search...';
export const SelectNotFoundPlaceholder = 'Nothing found';

/**
 * Component implements a standard <select /> tag properties, excepts 'size' as it is replaced by
 * custom prop. Also, it has an amount of additional props derived from FieldProps
 */
const Select = React.forwardRef((props: SelectProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
	const {
		className,
		onChange,
		placeholder = SelectPlaceholder,
		loadingPlaceholder = SelectLoadingPlaceholder,
		searchPlaceholder = SelectSearchDefaultPlaceholder,
		notFoundPlaceholder = SelectNotFoundPlaceholder,
		compareType,
		requiredFieldMessage,
		suppressValidationMessage,
		'data-testid': dataTestId,
		items = React.useMemo(() => [], []),
		value,
		disabled,
		readOnly,
		loading = false,
		defaultValue,
		size,
		searchable = false,
		textTruncation = true,
		...selectProps
	} = props;

	const { filteredItems, searchValue, setSearchValue, searchRef, collapseGroup, initialSelectedValue, hiddenGroups } = useSearchableItemsList({
		items,
		value,
		defaultValue,
		compareType
	});
	const filteredItemsToDisplay = (filteredItems as IGroupedListOption[]).filter(item => !isInHiddenGroup(item, hiddenGroups));
	const isSearchVisible = typeof searchable === 'number' ? filteredItems.length >= searchable : searchable;
	const isDisabled = disabled || loading;

	function onSearchInputChange(event: ChangeEvent<HTMLInputElement>) {
		setSearchValue(event.target.value);
	}

	function forceFocusSearch() {
		if (searchRef.current !== document.activeElement) {
			searchRef.current?.focus();
		}
	}

	const downshiftProps = useSelect({
		id: selectProps.id,
		items: filteredItemsToDisplay || [],
		[typeof value !== 'undefined' ? 'selectedItem' : 'initialSelectedItem']:
			filteredItems.find(item => String(item.value) === String(initialSelectedValue)) || null,
		stateReducer: (state, actionAndChanges) => {
			const { changes, type } = actionAndChanges;

			const isGroup = changes.selectedItem?.isGroup || false;

			switch (type) {
				case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
					// without this code downshift don't let us put spaces in our search input,
					// but we use it only if no one item is currently highlighted
					if (state.highlightedIndex === -1) {
						forceFocusSearch();
						setSearchValue(searchValue + ' ');
						return updateSelectReducerChanges(changes, true, state.highlightedIndex);
					}

					if (changes.selectedItem && isGroup) {
						collapseGroup(changes.selectedItem);
						changes.selectedItem = state.selectedItem;
						return updateSelectReducerChanges(changes, true, state.highlightedIndex);
					}

					return updateSelectReducerChanges(changes, false, state.highlightedIndex);

				case useSelect.stateChangeTypes.MenuKeyDownEnter:
				case useSelect.stateChangeTypes.ItemClick:
					if (changes.selectedItem && isGroup) {
						collapseGroup(changes.selectedItem);
						changes.selectedItem = state.selectedItem;
					}

					return updateSelectReducerChanges(changes, isGroup, state.highlightedIndex);

				case useSelect.stateChangeTypes.MenuKeyDownEscape:
					return selectInputReducerUtils.menuKeydownEscape(changes, searchValue, setSearchValue);

				case useSelect.stateChangeTypes.MenuBlur:
					return selectInputReducerUtils.menuBlur(changes, searchRef, setSearchValue);

				case useSelect.stateChangeTypes.ToggleButtonKeyDownCharacter:
					return selectInputReducerUtils.toggleButtonKeydownCharacter(changes, setSearchValue);

				case useSelect.stateChangeTypes.MenuKeyDownCharacter:
					return selectInputReducerUtils.menuKeydownCharacter(changes, searchRef);
			}

			return changes;
		},
		onSelectedItemChange: ({ selectedItem: item }) => {
			if (!item || item.value === downshiftProps.selectedItem?.value) {
				return;
			}

			validation.setIsInvalid(validation.isRequired && item?.value === undefined);

			const element = inputRef?.current as unknown as HTMLInputElement;

			const setValueFn = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
			setValueFn?.call(element, item.value);

			const event = new Event('input', { bubbles: true });
			element.dispatchEvent(event);
		}
	});
	const { ref: buttonRef } = useControlledFocusOnError<HTMLButtonElement>({ name: selectProps.name });
	const downshiftInputProps = downshiftProps.getToggleButtonProps({ ref: buttonRef });
	const { getGroupProps, getLabelProps, getHelpblockProps, getInputProps, validation, inputRef } = useFormGroupValidation({
		id: downshiftInputProps.id,
		ref,
		disabled: isDisabled,
		readOnly,
		...selectProps
	});

	// group props
	const { controlId, ...groupProps } = getGroupProps();

	// label props
	const baseLabelProps = getLabelProps({ htmlFor: downshiftInputProps.id });
	const labelProps = { ...downshiftProps.getLabelProps(), ...baseLabelProps };

	// input props
	let inputValue = downshiftProps.selectedItem?.value !== undefined ? downshiftProps.selectedItem.label : placeholder;
	if (loading) {
		inputValue = loadingPlaceholder;
	}
	const baseInputProps: InputProps = getInputProps({
		id: downshiftInputProps.id,
		value: inputValue,
		'aria-labelledby': labelProps.id,
		className: ['form-select', loading && 'loading', validation.isInvalid && 'is-invalid', size !== undefined && `form-select-${size}`, className]
			.filter(Boolean)
			.join(' ')
	} as InputProps);
	const { 'aria-required': ariaRequired = undefined, disabled: toggleBtnDisabled = undefined, ...inputProps } = { ...downshiftInputProps, ...baseInputProps };

	// helpblock props
	const helpblockProps = getHelpblockProps({ id: `${downshiftInputProps.id}_helptext` });

	// menu props
	const isNothingFoundShown = isSearchVisible && filteredItemsToDisplay.length === 0;
	const downshiftMenuProps = downshiftProps.getMenuProps({
		'aria-labelledby': labelProps.id,
		tabIndex: 0,
		style: {
			height: getSelectMenuHeight(isNothingFoundShown ? 1 : filteredItemsToDisplay.length)
		}
	});

	// effects
	const { setValue = noop } = validation.formContext;
	React.useEffect(() => {
		if (!items) return;
		const { plainItems } = calculatePlainItems(items);
		const newItem = plainItems.find(item => item.value === downshiftProps.selectedItem?.value);
		if (!baseInputProps.disabled) {
			setValue(baseInputProps.name ?? '', newItem?.value ?? '');
		}
	}, [items, downshiftProps.selectedItem, baseInputProps.name, baseInputProps.disabled, setValue]);

	const { dropdownMenuRef, isDropdownMenuFlipped } = useFlippedDropdownMenu(downshiftProps.isOpen);

	return (
		<FormGroupValidation
			groupProps={groupProps}
			labelProps={labelProps}
			helpblockProps={helpblockProps}
			validation={validation}
			inputRef={inputRef}
			inline={selectProps.inline}
			requiredFieldMessage={requiredFieldMessage}
			suppressValidationMessage={suppressValidationMessage}>
			<div className={['form-select-wrapper', validation.isInvalid && 'is-invalid'].filter(Boolean).join(' ')}>
				<span className={loading ? 'input-group input-group-embedded' : undefined}>
					<input {...inputProps} data-testid={dataTestId} type="button" disabled={toggleBtnDisabled || readOnly} />
					{loading && (
						<span className="input-group-text" data-testid="loading-spinner">
							<IconLoading />
						</span>
					)}
				</span>

				<input
					id={selectProps.id}
					data-testid="select-hidden-input"
					aria-required={ariaRequired}
					ref={inputRef as unknown as React.MutableRefObject<HTMLInputElement>}
					value={downshiftProps.selectedItem?.value ?? ''}
					onInput={onChange}
					type="hidden"
					name={baseInputProps.name}
					disabled={baseInputProps.disabled}
				/>

				<div
					ref={dropdownMenuRef}
					className={['dropdown-menu w-100 p-0', downshiftProps.isOpen && 'show', isDropdownMenuFlipped && 'dropdown-menu-end']
						.filter(Boolean)
						.join(' ')}>
					{isSearchVisible ? (
						<div className="dropdown-search-field w-100">
							<Search
								groupClassName=""
								ref={searchRef as React.MutableRefObject<HTMLInputElement>}
								placeholder={searchPlaceholder}
								value={searchValue}
								onChange={onSearchInputChange}
								aria-autocomplete="list"
								aria-controls={inputProps.id}
								onClear={() => {
									setSearchValue('');
								}}
								autoComplete="off"
							/>
						</div>
					) : null}

					<ul className="dropdown-menu-items w-100 p-0 overflow-auto" {...downshiftMenuProps} data-testid="select-dropdown-menu">
						{downshiftProps.isOpen ? (
							<OptionsList
								getItemProps={downshiftProps.getItemProps}
								allItems={filteredItems}
								visibleItems={filteredItemsToDisplay}
								hiddenGroups={hiddenGroups}
								highlightedIndex={downshiftProps.highlightedIndex}
								notFoundPlaceholder={notFoundPlaceholder}
								searchString={searchValue}
								onGroupCollapse={collapseGroup}
								selectedItems={downshiftProps.selectedItem ? [downshiftProps.selectedItem.value ?? ''] : []}
								textTruncation={textTruncation}
							/>
						) : null}
					</ul>
				</div>
			</div>
		</FormGroupValidation>
	);
});
Select.defaultProps = {
	...inputDefaultProps
};
Select.displayName = 'Select';

export default Select;
