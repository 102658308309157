import { ProgressBarRangeProps, ProgressChartConfigOptions } from '../types';
import { CurrencyFormat } from '../../../helpers';

export function formatDisplayValue(value: number, config: ProgressChartConfigOptions) {
	const options: Intl.NumberFormatOptions = {
		style: 'decimal',
		useGrouping: config.formatNumber
	};
	if (config.forceDecimals) {
		options['minimumFractionDigits'] = config.decimals;
	}

	return (config.numberPrefix || '') + new CurrencyFormat().format(value, options);
}

export const getPercentage = ({ now, min, max, precision = 1000 }: ProgressBarRangeProps): number | undefined => {
	if ((typeof now !== 'number' && !now) || (typeof min !== 'number' && !min) || (typeof max !== 'number' && !max)) return;
	const percentage = ((now - min) / (max - min)) * 100;
	return Math.round(Math.round(percentage * precision) / precision);
};
