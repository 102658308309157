import React, { MutableRefObject } from 'react';
import PropTypes from 'prop-types';
import { TableToggleCommonProps } from 'react-table';

// https://react-table.tanstack.com/docs/api/useRowSelect#instance-properties
export const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, TableToggleCommonProps>(({ indeterminate = true, ...rest }, ref) => {
	const defaultRef = React.useRef<HTMLInputElement>(null);
	const resolvedRef = (ref || defaultRef) as MutableRefObject<HTMLInputElement>;

	React.useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<div className="form-check p-0">
			<input ref={resolvedRef} className="form-check-input" type="checkbox" {...rest} />
		</div>
	);
});
IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

IndeterminateCheckbox.propTypes = {
	indeterminate: PropTypes.bool
};
