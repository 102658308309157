import { RefObject } from 'react';
import { FieldValues, UseFormRegister, ValidationValueMessage } from 'react-hook-form';
import { DatePickerHotKeys } from './keyboardKeys';
import {
	AttributeBasedValidator,
	RegisterOptions,
	BuildRegisterPropsData,
	Validator,
	AttributeBasedValidationKeys,
	InternalRegisterOptions,
	BuildPercentValidatorProps
} from './types';
import { validateDaysInMonth } from './date';
import { EIN_REGEX_CHECK, MBI_REGEX_CHECK, SIN_REGEX_CHECK } from './utils/constants';

// Returns a list of react-hook-form errors for the given fieldName
export function getFieldErrors(fieldName?: string, errors: Record<string, unknown> = {}) {
	/*
	 field names with "." separators are created as nested keys in the errors object.
	 this is common practice for dynamic fields, but also applies for static fields as well.
	 */
	return (fieldName || '').split('.').reduce((errorsAccumulator, key) => {
		if (errorsAccumulator && Object.prototype.hasOwnProperty.call(errorsAccumulator, key)) {
			return errorsAccumulator[key as keyof typeof errorsAccumulator] as Record<string, unknown> | undefined;
		}
		return undefined;
	}, errors as Record<string, unknown> | undefined);
}

// Returns true/false if a given fieldName has errors
export function fieldHasErrors(fieldName?: string, errors = {}) {
	return typeof getFieldErrors(fieldName, errors) !== 'undefined';
}

export function isValueUnset(value?: string | null) {
	return value === null || value === undefined || value === '';
}

export const Validation: Record<string, Validator> = {
	email: {
		name: 'email',
		validate: value => {
			// regex taken from http://emailregex.com/
			const rfc5322 =
				// eslint-disable-next-line no-control-regex
				/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i;
			return isValueUnset(value) || rfc5322.test(value);
		},
		message: 'Invalid format. Must be a valid email address format.'
	},
	integer: {
		name: 'integer',
		validate: value => {
			return isValueUnset(value) || /^\d+$/.test(value);
		},
		message: 'Invalid numeric field. Must be all numbers.'
	},
	phone: {
		name: 'phone',
		validate: value => {
			return isValueUnset(value) || /^(\d{3})-?(\d{3})-?(\d{4})$/.test(value);
		},
		message: 'Invalid phone number. Must be 10 digits or in the form NNN-NNN-NNNN.'
	},
	alphaNumeric: {
		name: 'alphaNumeric',
		validate: value => {
			return isValueUnset(value) || /^[a-z0-9-]+$/i.test(value);
		},
		message: 'Invalid alphanumeric field. Must contain only characters A thru Z, or 0 thru 9.'
	},
	alphaNumericStrict: {
		name: 'alphaNumericStrict',
		validate: value => {
			return isValueUnset(value) || /^[a-z0-9]+$/i.test(value);
		},
		message: 'Invalid alphanumeric field. Must contain only characters A thru Z, or 0 thru 9.'
	},
	ein: {
		name: 'ein',
		validate: value => {
			return isValueUnset(value) || EIN_REGEX_CHECK.test(value);
		},
		message: 'Invalid Federal Tax ID/EIN format. Must be 9 digits or in the form NN-NNNNNNN.'
	},
	sin: {
		name: 'sin',
		validate: value => {
			return isValueUnset(value) || SIN_REGEX_CHECK.test(value);
		},
		message: 'Invalid SIN format. Must be 9 digits or in the form NNN-NNN-NNN.'
	},
	ssn: {
		name: 'ssn',
		validate: value => {
			if (isValueUnset(value)) {
				return true;
			}

			if (/^(?!000|666)[0-8]\d{2}([-]?)(?!00)\d{2}\1(?!0000)\d{4}$/.test(value)) {
				return !(value === '123456789' || value === '012345678' || /^(.)\1*$/.test(value));
			}

			return false;
		},
		message: 'Must be 9 digits or in the form NNN-NN-NNNN.'
	},
	mbi: {
		name: 'mbi',
		validate: value => {
			const aValue = value.replace(/-/g, '');

			if (aValue.length === 11) {
				return isValueUnset(value) || MBI_REGEX_CHECK.strict.test(aValue);
			}

			return false;
		},
		message: 'Invalid Medicare Beneficiary Identifier. Please refer to the Medicare Number listed on your Medicare Information Card.'
	},
	date: {
		name: 'formattedDate',
		validate: value => {
			if (isValueUnset(value) || value === DatePickerHotKeys.nextDay || value === DatePickerHotKeys.today || value === DatePickerHotKeys.previousDay) {
				return true;
			}

			if (/^(0[1-9]|1[012])[/.](0[1-9]|[12]\d|3[01])[/.](19|20)\d\d$/.test(value)) {
				const [month, day, year] = value.split('/').map(x => Number(x));
				const thisYear = new Date().getFullYear();
				if (year <= thisYear + 125 && year >= thisYear - 125) {
					return validateDaysInMonth(month, day, year);
				}
			}

			return false;
		},
		message: 'Invalid date. Please enter date in mm/dd/yyyy format.'
	},
	zip: {
		name: 'USorCanadianZip',
		validate: value => {
			return isValueUnset(value) || /(^\d{5}(?:-?\d{4})?$)|(^[A-Z]\d[A-Z] \d[A-Z]\d$)/.test(value);
		},
		message: 'Invalid zip code format. Must be a valid USA/Canadian zip code.'
	},
	money: {
		name: 'money',
		validate: value => {
			return isValueUnset(value) || /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(value);
		},
		message: 'Invalid Monetary Amount. Must be numeric digits or in the form NNN,NNN.'
	},
	numeric: {
		name: 'numeric',
		validate: value => {
			return isValueUnset(value) || !Number.isNaN(Number(value));
		},
		message: 'Invalid numeric field. Must be numeric.'
	},
	percent: buildPercentValidator({
		name: 'percent',
		message: 'Invalid percent field. Must be percent.'
	})
};

export const AttributeBasedValidation: Record<AttributeBasedValidationKeys, AttributeBasedValidator> = {
	maxLength: value => {
		return {
			value,
			message: `Value must be ${value} characters or less.`
		};
	},
	minLength: value => {
		return {
			value,
			message: `Value must be ${value} characters or more.`
		};
	},
	max: value => {
		return {
			value,
			message: `Value must be less than or equal to ${value}.`
		};
	},
	min: value => {
		return {
			value,
			message: `Value must be greater than or equal to ${value}.`
		};
	}
};

export function buildPercentValidator({ name, message, min = 0, max = 100, decimals = 0 }: BuildPercentValidatorProps): Validator {
	return {
		name: name,
		validate: value => {
			const numParts = value.split('.');
			if (decimals && (numParts.length > 2 || numParts[1]?.length > decimals)) return false;
			if (!decimals && (numParts.length > 1 || parseFloat(value) % 1 !== 0)) return false;
			return isValueUnset(value) || (parseFloat(value) >= min && parseFloat(value) <= max);
		},
		message: message
	};
}

export function buildRegisterProps(
	register: UseFormRegister<FieldValues>,
	registerOptions: RegisterOptions = {},
	fieldProps: Record<string, unknown> = {}
): BuildRegisterPropsData {
	const { name, required, validators, ...rest } = registerOptions;
	if (!name || !register) {
		return {};
	}

	const internalRegisterOptions: InternalRegisterOptions = {
		required: !!required,
		shouldUnregister: true,
		validate: {},
		...rest
	};

	(Object.keys(AttributeBasedValidation) as AttributeBasedValidationKeys[]).forEach(key => {
		if (Object.prototype.hasOwnProperty.call(fieldProps, key) && fieldProps[key]) {
			internalRegisterOptions[key] = AttributeBasedValidation[key](fieldProps[key]) as ValidationValueMessage<number>;
		}
	});

	if (Array.isArray(validators) && validators.length > 0) {
		validators.forEach(validator => {
			if (validator.name) {
				internalRegisterOptions['validate'][validator.name] = (value: string) => validator.validate(value) || validator.message;
			}
		});
	}

	// returns { onChange, onBlur, name, ref }
	return register(name, internalRegisterOptions);
}

export function isInputChecked(element: HTMLInputElement) {
	let isChecked = false;
	if (element) {
		isChecked = element.checked || isChecked;
		if (!isChecked && element.type === 'radio' && element.form) {
			for (const radio of element.form[element.name]) {
				if (radio.checked) {
					isChecked = true;
					break;
				}
			}
		}
	}

	return isChecked;
}

export function submitFormRef(formRef?: RefObject<HTMLFormElement>) {
	formRef && formRef.current && formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
}
