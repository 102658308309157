import { UserInterfaceContract } from '../types';

export const unsafeTitleAdapter = (contract: UserInterfaceContract) => {
	if (contract.title) {
		if (contract.title.match(/&\S+;/)) {
			return { dangerouslySetInnerHTML: { __html: contract.title } };
		} else {
			return { children: contract.title };
		}
	}

	return {};
};
