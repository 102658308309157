import React from 'react';
import { BreadcrumbProps } from './types';
import { breadcrumbBaseClass } from './utility/constants';
import { BreadcrumbItem } from './BreadcrumbItem';

export const Breadcrumb = ({ 'aria-label': ariaLabel = 'breadcrumb', children, condensed = false, ...props }: BreadcrumbProps) => {
	return (
		<nav aria-label={ariaLabel} {...props}>
			<ol className={breadcrumbBaseClass}>
				{React.Children.map(children, (child, index) => {
					if (condensed && React.isValidElement(child)) {
						return React.cloneElement(child, {
							...child.props,
							condensed: index === 0 ? false : condensed
						});
					}
					return child;
				})}
			</ol>
		</nav>
	);
};
Breadcrumb.displayName = 'Breadcrumb';

Breadcrumb.Item = BreadcrumbItem;
