import React, { useCallback } from 'react';
import InputGroup from '../utility/InputGroup';
import { applyEventListener, fieldHasErrors } from '../../helpers';
import useFormGroupValidation from './shared/hooks/useFormGroupValidation';
import FormGroupValidation from './shared/FormGroupValidation';
import { inputDefaultProps } from '../../props/inputProps';
import { InputProps } from './types';
import FormControl from './form-control/FormControl';

/**
 * see [react-bootstrap - Form Controls](https://react-bootstrap.github.io/components/forms/#forms-controls) for complete documentation on the underlying implementation
 */
const Input = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(
	({ addOnAppend, embedded, addOnPrepend, addOnRole, onChange, onBlur, requiredFieldMessage, suppressValidationMessage, ...props }, ref) => {
		const { getGroupProps, getLabelProps, getInputProps, getHelpblockProps, validation, inputRef } = useFormGroupValidation({
			ref,
			...props
		});

		const handleChange = useCallback(
			(event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				validation.setIsInvalid(
					(validation.isRequired && !(event.target as HTMLInputElement).value) ||
						fieldHasErrors(validation.inputName, validation.formContext.formState?.errors)
				);

				applyEventListener(onChange, [event]);
			},
			[validation, onChange]
		);

		const handleBlur = useCallback(
			(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				validation.setIsInvalid(
					(validation.isRequired && !(event.target as HTMLInputElement).value) ||
						fieldHasErrors(validation.inputName, validation.formContext.formState?.errors)
				);

				applyEventListener(onBlur, [event]);
			},
			[validation, onBlur]
		);

		return (
			<FormGroupValidation
				groupProps={getGroupProps()}
				labelProps={getLabelProps()}
				helpblockProps={getHelpblockProps()}
				validation={validation}
				inputRef={inputRef}
				inline={props.inline}
				requiredFieldMessage={requiredFieldMessage}
				suppressValidationMessage={suppressValidationMessage}>
				<InputGroup embedded={embedded} addOnAppend={addOnAppend} addOnPrepend={addOnPrepend} addOnRole={addOnRole} isInvalid={validation.isInvalid}>
					<FormControl
						ref={inputRef}
						{...getInputProps({
							onChange: handleChange,
							onBlur: handleBlur,
							isInvalid: validation.isInvalid
						})}
					/>
				</InputGroup>
			</FormGroupValidation>
		);
	}
);
Input.displayName = 'Input';

Input.defaultProps = {
	...inputDefaultProps,
	type: 'text',
	addOnRole: undefined
};

export default Input;
