import { createIcon } from '../utility/createIcon';

export const IconCase = createIcon('case');

export const IconCompany = createIcon('company');

export const IconConfiguration = createIcon('configuration');

export const IconDashboard = createIcon('dashboard');

export const IconGoal = createIcon('goal');

export const IconHistory = createIcon('history');

export const IconParking = createIcon('parking');

export const IconTag = createIcon('tag');

export const IconTransit = createIcon('transit');

export const IconUserGroup = createIcon('user-group');
