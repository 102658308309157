import { FormatValue } from './types/shared';

const Locale: Record<string, { currency?: string }> = {
	// Australian Dollar
	'en-AU': {
		currency: 'AUD'
	},
	// Canadian Dollar
	'en-CA': {
		currency: 'CAD'
	},
	// British Pounds
	'en-GB': {
		currency: 'GBP'
	},
	// Euro
	'en-IE': {
		currency: 'EUR'
	},
	// New Zealand Dollar
	'en-NZ': {
		currency: 'NZD'
	},
	// US Dollar
	'en-US': {
		currency: 'USD'
	},
	'es-ES': {
		currency: 'EUR'
	},
	// French Canadian Dollar
	'fr-CA': {
		currency: 'USD'
	},
	// French Euro
	'fr-FR': {
		currency: 'EUR'
	},
	// Japanese Yen
	'ja-JP': {
		currency: 'JPY'
	}
};

// options pulled from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
export class CurrencyFormat {
	locale?: string;
	options: Intl.NumberFormatOptions;

	constructor(locale?: string) {
		this.locale = locale;
		this.options = {
			style: 'currency',
			currencyDisplay: 'narrowSymbol',
			currency: Locale[locale || '']?.currency || 'USD'
		};
	}

	format(amount: FormatValue, options: Intl.NumberFormatOptions = {}) {
		const number = Number(amount);
		if (!isNaN(number)) {
			const resolvedOptions = {
				...this.options,
				...options
			};
			try {
				return new Intl.NumberFormat(this.locale, resolvedOptions).format(number);
			} catch (_e) {
				// older safari versions don't support `narrowSymbol` so format with localized currency symbol
				if (resolvedOptions.currencyDisplay === 'narrowSymbol') {
					return new Intl.NumberFormat(this.locale, {
						...resolvedOptions,
						currencyDisplay: 'symbol'
					}).format(number);
				}
			}
		}

		return String(amount);
	}
}

export function formatCurrency(value: FormatValue) {
	return new CurrencyFormat().format(value);
}
