import React from 'react';

import { FormControlFeedbackProps } from '../types';

const FormControlFeedback = ({ type, ...props }: FormControlFeedbackProps) => {
	const classes = [type === 'invalid' ? 'invalid' : 'valid', '-feedback'].filter(Boolean).join('');
	return <div className={classes} {...props} />;
};

FormControlFeedback.displayName = 'FormControlFeedback';

export default FormControlFeedback;
