import React from 'react';
import Input from './Input';
import { TextareaProps } from './types';

/**
 * see [Input](/docs/framework-forms-input--default) component for complete documentation on the underlying implementation
 */

export const defaultPropsTextarea = {
	rows: 10,
	cols: 30
};

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref): JSX.Element => {
	return <Input as="textarea" ref={ref} {...props} />;
});

Textarea.displayName = 'Textarea';

Textarea.defaultProps = {
	rows: defaultPropsTextarea.rows,
	cols: defaultPropsTextarea.cols
};

export default Textarea;
