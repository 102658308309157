import { createIcon } from '../utility/createIcon';

export const IconBack = createIcon('back');

export const IconCalendarGrid = createIcon('calendar-grid');

export const IconClose = createIcon('close');

export const IconCollapse = createIcon('collapse');

export const IconDraggable = createIcon('draggable');

export const IconEllipsis = createIcon('ellipsis');

export const IconExpand = createIcon('expand');

export const IconExternalLink = createIcon('external-link');

export const IconFilter = createIcon('filter');

export const IconFolder = createIcon('folder');

export const IconFolderOpen = createIcon('folder-open');

export const IconHidePassword = createIcon('hide-password');

export const IconLoading = createIcon('loading');

export const IconLock = createIcon('lock');

export const IconNavigation = createIcon('navigation');

export const IconProfile = createIcon('profile');

export const IconSearch = createIcon('search');

export const IconShowPassword = createIcon('show-password');

export const IconNext = createIcon('next');

export const IconUnlock = createIcon('unlock');
