import { IGroupedListOption, IListOption, UseSearchableItemsListProps, UseSearchableItemsListData } from '../types';
import React from 'react';
import { comparatorTypes, compare } from '../../combobox/utility';
import { isChild, isSameGroupByParentsList } from '../utility';

export function calculatePlainItems(
	baseItems: IListOption[],
	acc: IGroupedListOption[] = [],
	level = 0,
	parents: string[] = []
): { plainItems: IGroupedListOption[]; count: number } {
	let childrenCount = 0;
	for (const item of baseItems) {
		const isGroup = item.value === undefined && item.items !== undefined;
		if (isGroup) {
			const group: IGroupedListOption = { ...item, value: undefined, label: item.label, isGroup, level, parents: [...parents] };
			acc.push(group);

			if (group.items) {
				const { count } = calculatePlainItems(group.items, acc, level + 1, [...parents, group.label]);
				childrenCount += count;
				group.childrenCount = count;
			}
		} else {
			acc.push({ ...item, parents, level, isGroup });
		}
	}
	return { plainItems: acc, count: baseItems.filter(item => !item.value === undefined || !item.items).length + childrenCount };
}

export function useSearchableItemsList(props: UseSearchableItemsListProps): UseSearchableItemsListData {
	const { items, value, defaultValue, compareType = comparatorTypes.startsWith, exclude = false, multiple = false } = props;

	const updatePlainItemsRef = React.useRef(false);
	const updateFilteredItemsRef = React.useRef(false);
	const [plainItems, setPlainItems] = React.useState<IGroupedListOption[]>(() => calculatePlainItems(items ?? []).plainItems);
	const [searchValue, setSearchValue] = React.useState('');
	const [filteredItems, setFilteredItems] = React.useState<IGroupedListOption[]>(plainItems);
	const [hiddenGroups, setHiddenGroups] = React.useState<string[][]>([]);

	const searchRef = React.useRef<HTMLInputElement>();

	function countItems(items: IGroupedListOption[]): number {
		return items.filter(item => !item.isGroup).length;
	}

	function itemMatchesSearch(searchValue: string, item: IGroupedListOption, compareType: string): boolean {
		// hide groups on search
		if (searchValue && item.isGroup) {
			return false;
		}

		return item.value !== undefined && compare(compareType, item.label, searchValue);
	}

	function collapseGroup(group: IGroupedListOption): void {
		const hiddenGroupIndex = hiddenGroups.findIndex(groupParents => isSameGroupByParentsList(group, groupParents));

		if (hiddenGroupIndex >= 0) {
			const newHiddenGroups = [...hiddenGroups];
			newHiddenGroups.splice(hiddenGroupIndex, 1);
			setHiddenGroups(newHiddenGroups);
		} else {
			if (group.parents) {
				setHiddenGroups([...hiddenGroups, [...group.parents, group.label]]);
			}
		}
	}

	const initialSelectedValue = React.useMemo(() => {
		function isSinglePreselected(item: IListOption): boolean {
			return String(item.value) === String(value) || String(item.value) === String(defaultValue);
		}
		function isMultiplePreselected(item: IListOption): boolean {
			const values = String(value).split(',');
			const defaultValues = String(defaultValue).split(',');
			return values.includes(String(item.value)) || defaultValues.includes(String(item.value));
		}

		if (value === undefined && defaultValue === undefined) {
			return multiple ? [] : undefined;
		}

		const definedValuesOnly = plainItems.filter(item => item.value !== undefined);

		if (multiple) {
			const values = definedValuesOnly.filter(item => isMultiplePreselected(item)).map(item => item.value) as Array<string | number>;
			return values || [];
		} else {
			const newValue = definedValuesOnly.find(item => isSinglePreselected(item))?.value;
			return newValue !== undefined ? newValue : undefined;
		}
	}, [multiple, plainItems, value, defaultValue]);

	const valuesMapped = React.useMemo(() => {
		const entries = plainItems?.filter(item => item.value !== undefined).map(item => [item.value, item]);
		return Object.fromEntries(entries);
	}, [plainItems]);

	const getItemByValue = React.useCallback(
		(value: string | number | undefined): IGroupedListOption | undefined => {
			if (value === undefined) {
				return undefined;
			}

			return valuesMapped[value];
		},
		[valuesMapped]
	);

	const getGroupItems = React.useCallback((group: IGroupedListOption, items: IGroupedListOption[]): IGroupedListOption[] => {
		return items.filter(item => !item.isGroup && isChild(item, group));
	}, []);

	// prepare plain items
	React.useEffect(() => {
		if (!updatePlainItemsRef.current) {
			updatePlainItemsRef.current = true;
			return;
		}

		if (items === undefined) {
			setPlainItems([]);
			return;
		}

		const { plainItems: calculatedItems } = calculatePlainItems(items ?? []);
		setPlainItems(calculatedItems);
	}, [updatePlainItemsRef, items, items?.length]);

	// perform search filtering
	React.useEffect(() => {
		if (!updateFilteredItemsRef.current) {
			updateFilteredItemsRef.current = true;
		}

		if (searchValue.trim() === '') {
			setFilteredItems(plainItems || []);
			return;
		}

		// find items that:
		// 1. is group (groups are not filtered by search)
		// OR
		// 2. matches search (taking in count the 'exclude' value)
		const allFilteredItems = plainItems.filter(item => item.isGroup || exclude !== itemMatchesSearch(searchValue, item, compareType)) || [];

		// remove groups with no visible items
		const newItems = allFilteredItems.filter(item => (item.isGroup ? getGroupItems(item, allFilteredItems).length > 0 : true));

		setFilteredItems(newItems);
	}, [updateFilteredItemsRef, getGroupItems, exclude, compareType, plainItems, searchValue, hiddenGroups]);

	return {
		filteredItems,
		searchValue,
		setSearchValue,
		searchRef,
		getGroupItems,
		initialSelectedValue,
		hiddenGroups,
		getItemByValue,
		collapseGroup,
		itemsNumber: countItems(plainItems),
		countItems
	};
}
