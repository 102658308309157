import React from 'react';
import { WidgetElementProps } from './types';
import { widgetBaseClass } from './utility/constants';

export default function WidgetTitle({ className = 'h4', children, ...props }: WidgetElementProps): JSX.Element {
	const classes = [`${widgetBaseClass}-title`, className].filter(Boolean).join(' ');
	return (
		<h2 className={classes} {...props}>
			{children}
		</h2>
	);
}
