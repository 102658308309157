import { IconName as FaIconName } from '@fortawesome/fontawesome-common-types';
import { attentionColors, customCSSProperties, supportedThemes } from './constants';

export type Theme = 'solid' | 'regular' | 'duotone';

export type MappingIconName =
	| 'add'
	| 'attach'
	| 'back'
	| 'banking'
	| 'calendar-grid'
	| 'call'
	| 'case'
	| 'chart'
	| 'chart-area'
	| 'chart-bar'
	| 'chart-line'
	| 'chart-pie'
	| 'chat'
	| 'close'
	| 'collapse'
	| 'company'
	| 'configuration'
	| 'copy'
	| 'credit-card'
	| 'dashboard'
	| 'delete'
	| 'dollar'
	| 'download'
	| 'draggable'
	| 'edit'
	| 'ellipsis'
	| 'email'
	| 'expand'
	| 'export'
	| 'external-link'
	| 'file'
	| 'file-archive'
	| 'file-code'
	| 'file-error'
	| 'file-excel'
	| 'file-image'
	| 'file-pdf'
	| 'file-powerpoint'
	| 'file-text'
	| 'file-word'
	| 'filter'
	| 'folder'
	| 'folder-open'
	| 'goal'
	| 'hide-password'
	| 'history'
	| 'import'
	| 'information'
	| 'loading'
	| 'lock'
	| 'navigation'
	| 'next'
	| 'notification'
	| 'parking'
	| 'print'
	| 'profile'
	| 'question'
	| 'refresh'
	| 'savings'
	| 'search'
	| 'show-password'
	| 'sortable'
	| 'sort-ascending'
	| 'sort-descending'
	| 'success'
	| 'tag'
	| 'transfer'
	| 'transit'
	| 'upload'
	| 'undo'
	| 'unlock'
	| 'warning'
	| 'wallet'
	| 'user-group'
	| 'error';

export interface IconMapDefinition {
	iconName: FaIconName;
	theme: Theme;
	color?: string;
	spin?: boolean;
	style?: Record<string, unknown>;
}
export const iconMap: Record<MappingIconName, IconMapDefinition> = {
	add: { iconName: 'plus', theme: supportedThemes.regular },
	attach: { iconName: 'paperclip', theme: supportedThemes.regular },
	back: { iconName: 'chevron-left', theme: supportedThemes.regular },
	banking: { iconName: 'building-columns', theme: supportedThemes.regular },
	'calendar-grid': { iconName: 'calendar-days', theme: supportedThemes.regular },
	call: { iconName: 'phone', theme: supportedThemes.regular },
	case: { iconName: 'briefcase', theme: supportedThemes.regular },
	chart: { iconName: 'chart-simple', theme: supportedThemes.regular },
	'chart-area': { iconName: 'chart-area', theme: supportedThemes.regular },
	'chart-bar': { iconName: 'chart-bar', theme: supportedThemes.regular },
	'chart-line': { iconName: 'chart-line', theme: supportedThemes.regular },
	'chart-pie': { iconName: 'chart-pie', theme: supportedThemes.regular },
	chat: { iconName: 'comment', theme: supportedThemes.regular },
	close: { iconName: 'xmark', theme: supportedThemes.regular },
	collapse: { iconName: 'chevron-up', theme: supportedThemes.regular },
	company: { iconName: 'building', theme: supportedThemes.regular },
	copy: { iconName: 'copy', theme: supportedThemes.regular },
	'credit-card': { iconName: 'credit-card', theme: supportedThemes.regular },
	configuration: { iconName: 'gears', theme: supportedThemes.regular },
	dashboard: { iconName: 'gauge-high', theme: supportedThemes.regular },
	delete: { iconName: 'trash-can', theme: supportedThemes.regular },
	dollar: { iconName: 'dollar-sign', theme: supportedThemes.regular },
	download: { iconName: 'download', theme: supportedThemes.regular },
	draggable: { iconName: 'arrows-maximize', theme: supportedThemes.regular },
	edit: { iconName: 'pencil', theme: supportedThemes.regular },
	ellipsis: { iconName: 'ellipsis', theme: supportedThemes.solid },
	error: { iconName: 'times-octagon', theme: supportedThemes.regular, color: attentionColors.error },
	email: { iconName: 'envelope', theme: supportedThemes.regular },
	expand: { iconName: 'chevron-down', theme: supportedThemes.regular },
	export: { iconName: 'file-export', theme: supportedThemes.regular },
	'external-link': { iconName: 'arrow-up-right-from-square', theme: supportedThemes.regular },
	file: { iconName: 'file', theme: supportedThemes.regular },
	'file-archive': { iconName: 'file-zipper', theme: supportedThemes.regular },
	'file-code': { iconName: 'file-code', theme: supportedThemes.regular },
	'file-error': { iconName: 'file-exclamation', theme: supportedThemes.regular, color: '#e11313' },
	'file-excel': { iconName: 'file-excel', theme: supportedThemes.regular, color: '#0e773c' },
	'file-image': { iconName: 'file-image', theme: supportedThemes.regular },
	'file-pdf': { iconName: 'file-pdf', theme: supportedThemes.regular, color: '#eb1001' },
	'file-powerpoint': { iconName: 'file-powerpoint', theme: supportedThemes.regular, color: '#bf3618' },
	'file-text': { iconName: 'file-lines', theme: supportedThemes.regular },
	'file-word': { iconName: 'file-word', theme: supportedThemes.regular, color: '#124195' },
	filter: { iconName: 'filter', theme: supportedThemes.regular },
	folder: { iconName: 'folder', theme: supportedThemes.regular },
	'folder-open': { iconName: 'folder-open', theme: supportedThemes.regular },
	goal: { iconName: 'bullseye', theme: supportedThemes.regular },
	'hide-password': { iconName: 'eye-slash', theme: supportedThemes.regular },
	history: { iconName: 'clock-rotate-left', theme: supportedThemes.regular },
	import: { iconName: 'file-import', theme: supportedThemes.regular },
	information: { iconName: 'circle-info', theme: supportedThemes.regular, color: attentionColors.info },
	loading: { iconName: 'spinner-third', theme: supportedThemes.regular, spin: true },
	lock: { iconName: 'lock', theme: supportedThemes.regular },
	navigation: { iconName: 'bars', theme: supportedThemes.regular },
	next: { iconName: 'chevron-right', theme: supportedThemes.regular },
	notification: { iconName: 'bell', theme: supportedThemes.regular },
	parking: { iconName: 'square-parking', theme: supportedThemes.regular },
	print: { iconName: 'print', theme: supportedThemes.regular },
	profile: { iconName: 'user-circle', theme: supportedThemes.regular },
	question: { iconName: 'circle-question', theme: supportedThemes.regular },
	refresh: { iconName: 'rotate', theme: supportedThemes.regular },
	savings: { iconName: 'piggy-bank', theme: supportedThemes.regular },
	search: { iconName: 'search', theme: supportedThemes.regular },
	'show-password': { iconName: 'eye', theme: supportedThemes.regular },
	sortable: { iconName: 'sort', theme: supportedThemes.duoTone, style: { [customCSSProperties.primaryOpacity]: 0.4 } },
	'sort-ascending': { iconName: 'sort', theme: supportedThemes.duoTone },
	'sort-descending': {
		iconName: 'sort',
		theme: supportedThemes.duoTone,
		style: { [customCSSProperties.primaryOpacity]: 0.4, [customCSSProperties.secondaryOpacity]: 1 }
	},
	success: { iconName: 'circle-check', theme: supportedThemes.regular, color: attentionColors.success },
	tag: { iconName: 'tag', theme: supportedThemes.regular },
	transfer: { iconName: 'shuffle', theme: supportedThemes.regular },
	transit: { iconName: 'bus-simple', theme: supportedThemes.regular },
	upload: { iconName: 'upload', theme: supportedThemes.regular },
	undo: { iconName: 'arrow-rotate-left', theme: supportedThemes.regular },
	unlock: { iconName: 'unlock', theme: supportedThemes.regular },
	warning: { iconName: 'triangle-exclamation', theme: supportedThemes.regular, color: attentionColors.warning },
	wallet: { iconName: 'wallet', theme: supportedThemes.regular },
	'user-group': { iconName: 'user-group', theme: supportedThemes.regular }
};

export type IconName = MappingIconName | FaIconName;

export const blacklist: Array<IconName> = ['hand-middle-finger', 'poop', 'poo', 'poo-storm', 'poo-bolt', 'bong', 'joint'];
