import React from 'react';

import { Datatable } from '../datatable';
import { TableProps } from './types';

// TODO delete when `Datatable` component will be typescript based
// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
const JSDatatable: any = (props: any) => {
	return <Datatable {...props} />;
};

const Table = ({ striped = false, responsive = true, 'data-testid': dataTestId = 'basic-vertical', type = 'vertical', ...props }: TableProps): JSX.Element => (
	<JSDatatable
		type={type}
		sortable={false}
		paginate={false}
		tableFilter={false}
		striped={striped}
		responsive={responsive}
		data-testid={dataTestId}
		{...props}
	/>
);

export default Table;
