import React from 'react';

import { IconEllipsis } from '@optic-delight/icons';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
import ActionButtonDropdownItem from './ActionButtonDropdownItem';
import ActionButtonDropdownDivider from './ActionButtonDropdownDivider';
import { ActionButtonDropDownProps } from './types';

const ActionButtonDropdown = ({ children, toggleIconTestId, align = 'end', ...props }: ActionButtonDropDownProps): JSX.Element => {
	return (
		<BootstrapDropdown className="dropdown-action-btn" {...props}>
			<BootstrapDropdown.Toggle variant="default" size="sm">
				<IconEllipsis data-testid={toggleIconTestId} />
			</BootstrapDropdown.Toggle>
			<BootstrapDropdown.Menu align={align}>{children}</BootstrapDropdown.Menu>
		</BootstrapDropdown>
	);
};

ActionButtonDropdown.Item = ActionButtonDropdownItem;
ActionButtonDropdown.Divider = ActionButtonDropdownDivider;
ActionButtonDropdown.displayName = 'ActionButtonDropdown';

export default ActionButtonDropdown;
