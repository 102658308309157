import * as React from 'react';
import { TranslatableTextContext } from './TranslatableTextProvider';
import { UseTranslatableTextData } from './types';

export const translatableTextDefaults = {
	loadingText: 'Loading...',
	globalFilterPlaceholder: 'Search...',
	searchResultsText: 'Results for',
	filterResultsLabel: 'Filters',
	filterResultsText: 'Results found',
	clearAllText: 'Clear All',
	noResultsText: 'No results found',
	lengthMenuShowText: 'Show',
	lengthMenuOfText: 'of'
};

export function useTranslatableText(): UseTranslatableTextData {
	const context = React.useContext(TranslatableTextContext) || {};
	// remove keys with `undefined` value
	(Object.keys(context) as Array<keyof typeof context>).forEach(key => context[key] === undefined && delete context[key]);

	return {
		...translatableTextDefaults,
		...context
	};
}
