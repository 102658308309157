import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Company {
	id: number;
}

function CompaniesList(): JSX.Element {
	const [companyIds, setCompanyIds] = useState<number[]>([]);

	useEffect(() => {
		const hcWebURL = process.env.REACT_APP_API_URL;
		axios
			.get(`${hcWebURL}/companies`)
			.then(response => {
				const ids = response.data.map((company: Company) => company.id);
				setCompanyIds(ids);
			})
			.catch(error => {
				// console.error("Error fetching data", error);
			});
	}, []);

	return (
		<div>
			<h1>Company IDs</h1>
			<ul>
				{companyIds.map(id => (
					<li key={id} data-testid={`company-${id}`}>
						{id}
					</li>
				))}
			</ul>
		</div>
	);
}

export default CompaniesList;
