import React, { ForwardedRef } from 'react';
import { CustomizedButtonProps } from './types';
import CustomizedButtonWrapper from './CustomizedButtonWrapper';

export const AddButtonDefaultText = 'Add';

const AddButton = React.forwardRef(({ children = AddButtonDefaultText, ...props }: CustomizedButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
	return (
		<CustomizedButtonWrapper variant="primary" icon="add" iconDirection="prepend" ref={ref} {...props}>
			{children}
		</CustomizedButtonWrapper>
	);
});
AddButton.displayName = 'AddButton';

export default AddButton;
