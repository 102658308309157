import React from 'react';

import { ModalContext } from '../ModalProvider';
import HookContextError from '../../../helpers/HookContextError';

export default function useModal() {
	const context = React.useContext(ModalContext);
	if (!context) {
		throw new HookContextError(useModal.name, ModalContext.displayName);
	}

	return context;
}
