import { createIcon } from '../utility/createIcon';

export const IconInformation = createIcon('information');

export const IconNotification = createIcon('notification');

export const IconQuestion = createIcon('question');

export const IconSuccess = createIcon('success');

export const IconWarning = createIcon('warning');

export const IconError = createIcon('error');
