import React from 'react';
import { DropdownDividerProps } from './types';

const DropdownDivider = ({ as: Component = 'hr', className, ...props }: DropdownDividerProps) => {
	const classes = [className, 'dropdown-divider'].filter(Boolean).join(' ');
	return <Component role="separator" className={classes} {...props} />;
};

DropdownDivider.displayName = 'DropdownDivider';

export default DropdownDivider;
